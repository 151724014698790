import React from 'react';
import {Language} from "../i18n/language";
import {OurClassesSection} from './ourClassesSection/ourClassesSection';
import CerahOnlineHomeImg from "../../../img/cerahOnline/go international.webp";

export function CerahOnlineSection(props) {
  const strings = Language.active.ourClasses;

  return (
    <OurClassesSection 
      title={strings.cerahOnline} 
      text={strings.cerahOnlineText}
      imageUri={CerahOnlineHomeImg.src}
      buttonText={strings.buttonText}
      navigateTo="/cerahOnline"
      noBackground/>
  );
}
