import React from 'react';
import * as style from './ourClassesSection.module.scss';
import {Button} from '../../controls/button';
import * as buttonStyle from '../../controls/button.module.scss';
import {Link} from 'react-router-dom';
import {LazyLoadImage} from 'react-lazy-load-image-component';

export function OurClassesSection({title, subtitle, text, imageUri, buttonText, navigateTo, flexReverse}) {
  return (
    <div className={style.ourClass}>
      <div className={`${style.ourClassInner} ${flexReverse && style.ourClassInnerReverse}`}>
        <div className={style.ourClassLeft}>
            <span className={style.ourClassTitle}>
              {title}
              {subtitle && <span className={style.ourClassSubtitle}>{subtitle}</span>}
            </span>
            {text.split('\n').map((para, idx) => (
              <span key={idx} className={style.ourClassText}>
                {para}
              </span>
            ))}
            {buttonText &&
              <Link to={navigateTo}>
                <Button className={`${buttonStyle.large}`} noArrow>
                  {buttonText}
                </Button>
              </Link>
            }
        </div>
        <LazyLoadImage className={style.ourClassImage} src={imageUri}/>
      </div>
    </div>
  )
}