const {supportedLanguages} = require("./locales");

const langPrefixRegExp = new RegExp(`^\/(${supportedLanguages.join("|")})(\/.*|$)`);

function languageLessPath(path) {
  let languageLessPath = path.match(langPrefixRegExp) ? path.substr(3) : path;
  while (languageLessPath.endsWith("/")) {
    languageLessPath = languageLessPath.substring(0, languageLessPath.length -1);
  }
  return languageLessPath;
}


module.exports = {
  languageLessPath,
  langPrefixRegExp
}