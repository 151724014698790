import React from "react";
import * as mainStyle from "../main.module.scss";
import * as style from './ourMethod.module.scss';
import {Language} from "../../i18n/language";
import {useFixedWinHeight} from "../main";
import {isBrowser} from "../../serverSideRendering/ssr";
import {Swiper, SwiperSlide} from "../../controls/swiper";
import SwiperCore, { Navigation, Autoplay } from "swiper/core";
import MethodImage1 from "../../../../img/ourMethod/no-theoretical-explanations.svg";
import StudentsGetCorrectionsImg from "../../../../img/ourMethod/immediate-correction-of-mistakes.svg";
import HomeworkIsPassionsImg from "../../../../img/ourMethod/relatable-homework.svg";
import PracticeWithTheAppImg from "../../../../img/ourMethod/practice-with-students-app.svg";
import EllipseLeft from "../../../../img/main/ellipse-left.svg";
import EllipseRight from "../../../../img/main/ellipse-right.svg";
import {Button} from '../../controls/button';
import * as buttonStyle from '../../controls/button.module.scss';
import {SectionTitle} from "../sectionTitle";
import {Link} from "react-router-dom";
import {LazyLoadImage} from 'react-lazy-load-image-component';

export function OurMethod() {
  SwiperCore.use([Navigation, Autoplay]);

  const strings = Language.active.ourMethod;

  const vh = useFixedWinHeight();

  const height = isBrowser ? vh : undefined;

  const methods = [
    {
      text: strings.realConversation,
      imageUri: `${MethodImage1.src}`
    },
    {
      text: strings.studentsGetCorrections,
      imageUri: `${StudentsGetCorrectionsImg.src}`
    },
    {
      text: strings.passionsHomework,
      imageUri: `${HomeworkIsPassionsImg.src}`
    },
    {
      text: strings.practiceUsingTheApp,
      imageUri: `${PracticeWithTheAppImg.src}`
    }
  ];

  return (
    <div className={style.ourMethod}>
      <SectionTitle title={'Our Method'}/>
      <div className={style.ourMethodSlide}>
        <div  className={style.doodle}>
          <div className={style.sliderWrapper}>
            <Swiper
                  modules={[Navigation, Autoplay]} 
                  autoplay={{delay: 2000}}
                  navigation={true}
                  spaceBetween={50}
                  slidesPerView={1}
                  style={{width: '100%', height: '100%'}}
                  breakpoints={{
                    0 : {
                      slidesPerView: 1,
                    },
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 1,
                    },
                    1024: {
                      slidesPerView: 3
                    },
                  }}>
              {methods.map((m, idx) =>
                <SwiperSlide key={idx} style={{height: "auto"}}>
                  <OurMethodSubDiv method={m}/>
                </SwiperSlide>
              )}
            </Swiper>
          </div>

        </div>
      </div>
      <LazyLoadImage className={style.ellipseLeft} src={EllipseLeft.src}/>
      <LazyLoadImage className={style.ellipseRight} src={EllipseRight.src}/>
    </div>
  )
}

function OurMethodSubDiv({method}) {
  return (
    <div className={style.methodsRow}>
      <div className={style.methodColumn}>
        <LazyLoadImage className={style.methodImage} src={method.imageUri}/>
        <div className={style.methodTextContainer}><span className={style.methodText}>{method.text}</span></div>
      </div>
    </div>
  )
}