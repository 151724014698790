import React from 'react';

export function Image({altFormats, alt, layout, objectFit}) {
  altFormats = altFormats || [];
  const types = altFormats.map(module => {
    const parts = module.src.split(".");
    return parts[parts.length - 1];
  });
  return (
    <picture>
      {altFormats.map((module, idx) => <source key={idx} srcSet={module.src} type={`image/${types[idx]}`}/>)}

      <img src={altFormats[1].src}
           alt={alt}
           style={{
             objectFit,
             width: "100%",
             height: "100%"
           }}/>
    </picture>
  );
}
