import React from 'react';
import {useParams} from "react-router-dom";
import {importSnap} from "./snapLoader";

export function Payment(props) {
  const {token} = useParams();

  (async () => {
    const snap = await importSnap();
    snap.pay(token);
  })();

  return (<div style={{minHeight: "100vh"}}></div>);
}