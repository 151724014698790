import React from 'react';
import * as style from '../enquiry/teacherEnquiry.module.scss';

export function TeacherEnquiriesClosed() {
  console.log("teacherEnquiriesClosed.render()");
  return (
    <div className={style.teacherEnquiry}>
      <div className={style.closed}>
        <div className={style.logo}/>

        <div className={style.text}>
          Apologies: due to overwhelming demand, we have temporary closed applications to join Cerah.
          <br/>
          <br/>
          Please come back in a few days :-)
        </div>
      </div>
    </div>
  )
}