import analytics from './analytics.js';
import {isElementBottomInViewport} from '../scroll/scroll.js';
export class ScrollTracker {
  sectionsVisited;
  sectionElements;
  scrollTracked;
  scrollableSections;
  prefix;

  constructor(prefix, elementIdEventPairings) {
    this.prefix = prefix;
    this.scrollableSections = elementIdEventPairings;
    this.sectionsVisited = {};
    this.sectionElements = {};
    this.scrollTracked = false;
    this.trackScroll = this.trackScroll.bind(this);
  }

  setup() {
    window.addEventListener('scroll', this.trackScroll);
    this.scrollableSections.forEach(([id, evt]) => {
      this.sectionElements[id] = document.getElementById(id);
    });
  }

  teardown() {
    window.removeEventListener('scroll', this.trackScroll);
  }

  trackScroll() {
    if (!this.scrollTracked && window.scrollY > 0) {
      this.scrollTracked = true;
      analytics.track(`${this.prefix}.scroll`);
    }
    this.scrollableSections.forEach(([id, evt]) => {
      if (!this.sectionsVisited[id] && isElementBottomInViewport(this.sectionElements[id])) {
        this.sectionsVisited[id] = true;
        analytics.track(`${this.prefix}.${evt}`);
      }
    });
  }
}