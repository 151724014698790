const env = process.env.ENVIRONMENT;

const devConfig = Object.freeze({
  ENV: 'dev',
  STUDENT_ENQUIRIES_ENDPOINT: 'https://hooks.slack.com/services/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',
  TEACHER_ENQUIRIES_ENDPOINT: 'https://hooks.slack.com/services/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',
  API_HOST: 'https://api-dev.cerah.co',
  MIXPANEL_TOKEN: '8bae2497f96378b12ac5ee3767a5fcc3',
  ROLLBAR_ACCESS_TOKEN: '94699c03f92241a7b80a2ad8a9c837e8',
  CODE_VERSION: process.env.CODE_VERSION,
  SNAP_SCRIPT_URL: 'https://app.sandbox.midtrans.com/snap/snap.js',
  PUBLIC_PATH: process.env.PUBLIC_PATH,
  LIVE_CHAT_WIDGET_URL: 'https://d1oytm2fm9epou.cloudfront.net/index.html'
});

const prodConfig = Object.freeze({
  ENV: 'prod',
  STUDENT_ENQUIRIES_ENDPOINT: 'https://hooks.slack.com/services/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',
  TEACHER_ENQUIRIES_ENDPOINT: 'https://hooks.slack.com/services/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',
  API_HOST: 'https://api.cerah.co',
  MIXPANEL_TOKEN: '5438fcd4821605c1d763bc792733e7b7',
  ROLLBAR_ACCESS_TOKEN: '94699c03f92241a7b80a2ad8a9c837e8',
  CODE_VERSION: process.env.CODE_VERSION,
  SNAP_SCRIPT_URL: 'https://app.midtrans.com/snap/snap.js',
  PUBLIC_PATH: process.env.PUBLIC_PATH,
  LIVE_CHAT_WIDGET_URL: 'https://chatwidget.cerah.co/'
});

export const config = (env === "prod") ? prodConfig : devConfig;
