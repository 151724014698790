import {config} from "../config";

let snapLoaded = false;

export async function importSnap() {
  return new Promise(function (resolve, reject) {
    if (snapLoaded) {
      resolve(snap);
      return;
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = config.SNAP_SCRIPT_URL;
    script.addEventListener("load", () => { snapLoaded = true; resolve(snap) });

    const first_script = document.getElementsByTagName("script")[0];
    first_script.parentNode.insertBefore(script, first_script);
  });
}