import {mixpanel} from './mixpanelLoader';
import {isBrowser} from "../serverSideRendering/ssr";
import {experimentalGroups} from '../experiment/experimentGroup';

const buildTimestamp = isBrowser ? window.buildTimestamp : 0;

let lastPath = null;

class Analytics {
  page(path, options) {
    if (path !== lastPath) {
      lastPath = path;
      mixpanel.track('Viewed ' + path + ' Page', {...options, buildTimestamp, ...experimentalGroups});
    }
  }

  track(name, options) {
    mixpanel.track(name, {...options, buildTimestamp, ...experimentalGroups});
  }

  trackLink(element, name, options) {
    mixpanel.track_links(element, name, {...options, buildTimestamp, ...experimentalGroups});
  }

  getAnalyticsDistinctId() {
    return mixpanel.get_distinct_id();
  }
}

class ServerSideAnalytics {
  page(name, options) {
  }

  track(name, options) {
  }

  trackLink(element, name, options) {
  }

  initialize(config) {
  }

  getAnalyticsDistinctId() {
  }
}

let analytics = isBrowser ? new Analytics() : new ServerSideAnalytics()

export default analytics;
