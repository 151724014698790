import {userSession} from "../userSession/userSession";
import {isBrowser} from "../serverSideRendering/ssr";

export {supportedLanguages} from "./locales";

//TODO: make translations an immutable object
const translations = {
  en: {
    title: "Learn English Fast | Online Conversation Classes | Foreign Teachers",
    metaDescription: "Learn English online and have fun in our courses! Just sign up for group classes or get a private tutor to tailor a program especially for you. There are teachers specialized in IELTS too!",
    main: {
      changeYourLife: "Change your life",
      speakEnglishWithinMonths: "Speak English within months",
      noConfusingGrammar: "Learn English the way that really works: lots of real conversation practice with the expert corrections of a human teacher",
      tryNow: "TRY NOW!",
      noPaymentNeeded: "No payment needed.",
      homeImageAltText: "Learn English at affordable prices",
      learnEnglishAtComfort: "Learn English online at the comfort of your home with our experienced teachers",
      ourClasses: "Our programs",
      contactUs: {
        contactUs: "Contact us",
        imInterested: "Hi! I'm interested in your English classes, I'd like to have more information",
      },
      readMore: "read more...",
    },
    hero: {
      title: "Learn English the only way that works:",
      speakingPractice: "Lots of speaking practice...",
      talkingToAHuman: "...talking to a real human...",
      gettingFeedback: "...getting tons of expert corrections...",
      talkingAboutYourPassions: "...and talking about YOUR passions",
    },
    ourMethod: {
      ourMethod: "Our Method",
      learnMoreOurMethod: "Learn more",
      realConversation: "Real conversation with real humans",
      studentsGetCorrections: "Students get immediate corrections of mistakes",
      passionsHomework: "Relatable homework based on the students’ hobbies and passions",
      practiceUsingTheApp: "Practice between classes using our app so you don't forget what you learn",
    },
    ourClasses: {
      cerahOnline: "CerahOnline",
      cerahOnlineText: "For you who need to improve your English to prepare for the job market, advance your career, nail an IELTS test, get a visa to live abroad, or just want to make friends with people from different countries.\n\
      Learn by using the language right away, without tedious theoretical explanations. Have your mistakes corrected by our expert teachers. Get your teacher's coaching to arrange a practice routine outside of the class. And improve in no time!\n\
      You can learn privately or with new friends, you decide!",
      buttonText: "Get Started",
      cerahKids: "CerahKids",
      cerahKidsSubtitle: "Prepare your kids since they are young ",
      cerahKidsText: "Science shows that the ability to learn a language starts declining after adolescence. Give your kids the chance to start to learn English early so that they master it before they become adults\n\
      At Cerah they will learn in a practical way, using English from day one, with fun and engaging teachers, and with materials tailored to their own interests and hobbies, rather than boring textbooks",
    },
    whyCerah: {
      whyCerah: "Why Cerah",
      aMethodThatWorks: "A method that works",
      realSpeakingPracticeNoTheory: "No more pointless theory. Our brains can only learn a language the way you learn to ride a bike: with lots of actual practice, constant feedback... and enjoying the ride",
      learnMoreMethod: "Learn more about our method",
      worldClassTeachers: "World-class teachers at your fingertips",
      ourTeachersAreFun: "Learn English with the best teachers in the world, not in town. Say bye to boring teachers that make you hate English. Our teachers do know how to make a class fun",
      canTryBeforeYouBuy: "You can try before you buy",
      nothingToLose: "Try our classes before making any payment, and pay only if you like them. Nothing to lose for checking them out. We're sure you will sign up after seeing what we offer :-)",
      veryAffordablePrices: "Very affordable prices",
      privatePricesBetterThanYourTown: "Global scale allows us to offer prices for private classes that you cannot get where you live... for teachers you cannot get where you live.",
      learnWithTheApp: "Learn outside the class with our app",
      vocabularyPracticeAndProgress: "For the price of the classes, get also access to our app. Practice the vocabulary you learned during the class in your spare time, so that you don't forget what you learn between classes, and track your progress.",
    },
    whatTheySayAboutUs: {
      whatTheySayAboutUs: "What they say about us",
      seeAllTestimonials: "See all testimonials"
    },
    signUpNow: {
      beOneOfThem: "Be one of them",
      signUpNow: "Sign up now",
      tryItFree: "Try it free"
    },
    littleAboutUs: {
      title: "Little bit about us",
      aboutUsText: "We are a group of English private tutors that are fed up with how English has been taught more as maths problems than a language.\n\
      We believe strongly in learning by doing, getting immediate feedback, and practicing at all times. We encourage students to not see   English as a language that they are learning, but as one of the languages that they speak. The change of attitude and mindset will help boost   the confidence a lot and start speaking in English. Making mistakes is not a punishable event. It is the chance to learn from the mistakes   and improve. Not making mistakes means you are not doing enough. By not doing enough, you are not progressing enough.\n\
      So, join us in speaking English and improve it fast!",
      learnMore: "Learn more"
    },
    appShowcaseBanner: {
      practiceEnglishAnywhereYouAre: "Practice English\n anywhere you are",
      donwloadOurAppAndStartLearningNow: "Download our app and start learning now!"
    },
    navbar: {
      home: "Home",
      ourMethod: "Our method",
      coursesForAdults: "For adults",
      coursesForKids: "For kids",
      becomeATeacher: "Become a teacher",
      aboutUs: "About us"
    },
    footer: {
      conquerTheWorld: "Conquer the world",
      ourServices: "Our services",
      testimonials: "Testimonials",
      company: "Company",
      aboutUs: "About us",
      becomeTeacher: "Become a teacher",
      findUsOn: "Find us on",
      allRightsReserved: "All Rights Reserved"
    },
    copyright: {
      privacyPolicy: "Privacy policy",
      termsAndConditions: "Terms & conditions",
      contactUs: "Contact us",
    },
    teacher: {
      title: "Work As An Online English Teacher",
      metaDescription: "",
      slogan1: "Change people's lives",
      slogan2: "become an English tutor",
      startToday: "Start today",
      joinUs: "Join us!",
      mainMessage: {
        joinExclusive: "Join the exclusive club of the best private English tutors in Indonesia",
        atHome: "Teach your students online from home or face-to-face",
        workAtLeisure: "Work as much or as little as you want, and choose your own schedule",
        andMakeMoney: "Get a stable source of income with great pay!"
      },
      appShowcase: {
        howIt: "how it",
        works: "works",
        receiveNotifications: "Receive notifications of requests for courses directly on your phone",
        checkDetails: "Check details of the course request on the spot",
        acceptClass: "Accept the course if you like it. Courses last months or years",
        prepareClass: "Once the student confirms, prepare your classes and start working!",
        address: "Address",
        cemaraAsri: "Komp. Prambanan, Jalan Kembangan 72, Jakarta",
        fee: "Fee",
        seventySevenK: "115.000 / hour",
        classType: "class type",
        convo: "Conversation",
        schedule: "schedule",
        tue9am: `Tuesday | 09.00 - 10.30`,
        fri6pm: `Friday | 18.30 - 20.00`,
        level: "level",
        intermediate: "intermediate",
        groupSize: "group size",
        fourPeople: "4 people",
        studentsAge: "student age",
        doYouAccept: "do you want to teach this course?",
        cerah: "Cerah",
        classRequest: "Course request",
        studentWantsClasses: "A student is requesting classes in Pondok Indah area",
        seeDetails: "See details",
        congratulations: "Congratulations!",
        reminder: "Reminder",
        studentHasAccepted: "The student has agreed to start classes with you",
        yourClassAt: "Your first teaching day will be",
        onTuesday: "on Tuesday 6 March 2018 at 9am"
      },
      testimonials: {
        teacher: "teacher",
        testimonials: "testimonials",
        melisTestimonial: `The most helpful feature is that teachers are continuously provided with students. 
          This way, I don't need to spam on my social media or something just to inform others 
          that I'm available if someone needs an English teacher`,
        ilhamsTestimonial: `I was impressed by how they select and filter the teachers so that only 
          the most qualified ones can teach, to guarantee the effectiveness. Teaching is 
          a very professional occupation that cannot be taken for granted. And they pay their teachers 
          accordingly. I'm happy with the numbers I earn`,
        cuncunsTestimonial: `Nowadays, we order food, buy clothes, etc., all via internet through 
          the flick of our fingers. Booking private tutors online can be a 
          breakthrough in education. Students can have their own private tutors at places they wish, 
          and for us teachers, we get more students to teach. It's a great idea`,
        camelia: "Melly",
        ilham: "Ilham",
        cuncun: "Cun Cun"
      },
      benefits: {
        benefits: "benefits",
        your: "your",
        peaceOfMind: "just teach",
        payRates: "great pay",
        forgetAdmin: "we do the admin",
        neverMissClass: "never miss a class",
        support: "guidance & support",
        materials: "access to materials",
        noMarketing: "We find the students for you. Forget about having to spend precious time posting ads and searching for students, and focus instead what you love doing: teaching",
        onlyBestTeachers: "By letting only the best teachers join us, we can both provide great quality... and our teachers a great pay. Cerah only takes a small percentage as a fee",
        noChasingForPayments: "No need to chase students or keep track of payments due. We take care of that on your behalf",
        reminders: "Get reminders of all classes on your phone",
        networking: "Enjoy the benefits of belonging to a group of excellent teachers, learn from others and share experiences",
        wealthOfMaterials: "Get access to a wealth of materials to prepare your classes"
      }
    },
    enquiry: {
      teacher: {
        fillForm: "Create your account",
        intro: "Input your personal details as a first step to join Cerah as a teacher",
        form: {
          label: {
            name: "Enter your name below",
            phone: "WhatsApp number or phone number (include country code!)",
            email: "E-mail (we'll try this if the phone fails)",
            send: "Submit"
          },
          placeholder: {
            name: "Name",
            phone: "xxxxxxxxxx",
            email: "e.g example@example.com",
          },
          errors: {
            invalidPhoneFormat: "Please include the country prefix (e.g., +44 for the UK)",
            invalidEmailFormat: "Invalid email format, please revise",
          }
        },
      },
    },
    thankYou: {
      thankYou: "Thank you! We'll contact you shortly :-)",
      back: "Back"
    },
    quiz: {
      now: `Start now`,
      later: 'Do later',
      start: `Start`,
      done: `Done`,
      resultsSoon: `You've finished the test now. We will get back to you soon.`,
      question: `Question`,
      submit: 'Next',
      unknownError: `An error occurred. Please try again later`,
      notFound: `Test not found`,
      level: {
        starter: "Starter",
        elementary: "Elementary",
        preIntermediate: "Pre-intermediate",
        intermediate: "Intermediate",
        upperIntermediate: "Upper intermediate",
        advanced: "Advanced",
        notSure: "Not sure about your level?",
        clickForTest: "Test it!"
      },
      levelResults: {
        yourLevelIs: "Great! It turns out your level is",
        nextComeCourses: "Find the most appropriate course for your level!",
        continue: "Continue"
      },
    },
    midtrans: {
      success: {
        "result": "Success",
        "title": "Congratulations!",
        "detail": "Your payment was successful, thank you!",
        "done": "Done"
      },
      failure: {
        "result": "Failure",
        "title": "Sorry!",
        "detail": "Your payment failed. Please try again.",
        "done": "Got it"
      },
      incomplete: {
        "result": "Incomplete",
        "title": "Beware",
        "detail": "Your payment did not complete.",
        "done": "OK"
      },
    },
    levelTest: {
      intro: "This is a quick test to know your current English level. It should take less than 10 minutes to complete. The test comprises 25 questions"
    },
    npsTest: {
      thanks: "Thank you for your input!",
      feedbackEssential: "Your feedback is essential for us to improve our service",
      home: "Home"
    },
    courseSelection: {
      schedule: "Schedule",
      teacherProfile: {
        education: "Education",
        qualifications: "Qualifications",
        experience: "Experience",
        specialisations: "Specialisations"
      }
    },
    cerahKids: {
      kidsImgAltText: "Children learning English online",
      pageTitle: "English Language Classes For Kids",
      metaDescription: "Online English classes with experienced, qualified, foreign teachers who know how to make a class easy, fun and exciting! Give your children the language skills they need for the global world of the future",
      whatIstheAgeOfTheStudent: "What is the age of the student?",
      startLearningEarly: "Start learning English early",
      content: "Let your kids learn English by having real conversations with fun teachers who know how to keep their attention, using the heroes, characters, and stories they enjoy, not boring grammar books",
      tryItNow: "Try it now",
      KidsFutureImgAltText: "English courses for kids",
      didYouKnow: {
        sixtyCountries: "Did you know that English is globally spoken in more than 60 countries?",
        broadenTheirHorizons: "Expand your kids' future with English language skills"
      },
      nineYearOldIndonesianStudents: "9-y.o. Indonesian students",
      emergencyContact: {
        form: {
          label: {
            studentAge: "Student age",
            studentName: "Student name",
            name: "Parent name",
            email: "Parent e-mail",
            phone: "Phone",
            whatsapp: "WhatsApp number",
            voucher: "Promo code (if you have one)",
            send: "Send"
          },
          placeholder: {
            studentAge: "student's age",
            studentName: "Full name",
            name: "Full name",
            phone: "XXXXXXXXXX",
            email: "name@example.com"
          },
          invalidPhoneFormat: "Please include the country prefix (e.g., +44 for the UK)",
          numberNotOnWhatsapp: "This number is not registered on WhatsApp, please revise",
          invalidEmailFormat: "Invalid email format, please revise",
        }
      },
      tryBeforeBuy: {
        title: "Sounds interesting?",
        subTitle: "Try for free!",
        btnText: "Try it now"
      }
    },
    cerahOnline: {
      studentImgAltText: "Learn English online",
      pageTitle: "CerahOnline - Learn English online with a foreign teacher",
      metaDescription: "Practice your English online from anywhere with foreign teachers. Practical online courses, focusing on conversation and social interaction to learn English naturally and effortlessly",
      howOldIsStudent: "How old is the student?",
      underage: "Below 16",
      adult: "16+",
      title: "Get international at lightning speed",
      content: "Whether you want to learn English to land your dream job, to do business internationally or to get the IELTS score to study abroad, our English courses with private teachers will get you there in the fastest possible time.",
      courseTypes: {
        courseTypes: "Online English course types",
        courseTypesKids: "English course types for kids",
        privateClass: "Private",
        privateClassSubtitle: "Have an expert English teacher all for yourself",
        privateCourses: "Private online courses",
        groupCourses: "Group online courses",
        perHour: " /h",
        groupClass: "Group",
        groupOfTwoSubtitle: "Learn English with a conversation peer",
        groupOfThreeSubtitle: "Practice English in a group discussion",
        groupOfFourSubtitle: "Practice English in a lively conversation with people from different countries",
        people: "pax",
        perMonth: " /month",
        equivalentTo: "equivalent to",
        sixtyMinutesSessions: "60 minutes / session",
        twoPerWeek: "2x per week",
      },
      wannaTry: "Sounds interesting?",
      itsFree: "Try for free!",
      tryItNow: "Try it now",
      chooseSchedule: "Choose your schedule",
      yourSchedule: "Your schedule",
      weekdays: "Weekday",
      weekends: "Weekend",
      morning: "mornings",
      afternoon: "afternoons",
      night: "evenings",
      wantBut: "I want to, but",
      dontKnowWhen: "don't know when yet",
      group: "Group",
      individual: "Private",
      whatsapp: "WhatsApp / text",
      line: "Line / text",
      callback: "Phone call",
      youWantGroupIndividual: "Do you want group or private classes?",
      howYouWannaBeContacted: "How do you want to be contacted?",
      howManyMeetingsPerWeek: "How many classes per week?",
      meetingsPerWeek: "classes per week",
      choice: {
        youveChosen: "You've chosen",
        months: "months",
        perWeek: "per week",
        perMonth: "per month",
        month: "month",
        butFree: "But can I try before I pay?",
        freeTrialExplanation: "It's FREE to try! Pay only if you like it 😊",
        groupClass: "Group class",
        privateClass: "Private class",
        confirm: "Confirm"
      },
      scheduleSummary: {
        change: "Change",
        agree: "Confirm"
      },
      chooseTeacher: "Choose your teacher",
      yourClass: "You've chosen",
      whenTrial: "When would you like to have your trial class?",
      later: "Later",
      giveUsYourDetails: "Please fill in your contact details",
      ifEmergencyHowToContactYou: "If there's a schedule change, you'd prefer to be contacted via...",
      emergencyContact: {
        clickHere: "Click here",
        form: {
          label: {
            studentAge: "Student Age",
            studentName: "Student Name",
            name: "Name",
            email: "Email",
            phone: "Phone",
            whatsapp: "WhatsApp number",
            voucher: "Promo code (if you have one)",
            send: "Send"
          },
          placeholder: {
            studentAge: "student's age",
            studentName: "Full Name",
            name: "Full name",
            phone: "XXXXXXXXXX",
            email: "name@example.com"
          },
          invalidPhoneFormat: "Please include the country prefix (e.g., +44 for the UK)",
          numberNotOnWhatsapp: "This number is not registered on WhatsApp, please revise",
          invalidEmailFormat: "Invalid email format, please revise",
        }
      },
      selectTestCallSchedule: "Choose a time for a test call",
      takesJustFiveMinutes: "(the call takes just 5 minutes)",
      testCallDescription: "We'll contact you to configure your audio / video optimally before your trial class, so that you can hit the ground running :-)",
      noScheduleAvailableForTestCall: "Sorry, no schedules are available on this date. Please select another one.",
      testCallChoice: "You've chosen",
      clickOnWaButtonToConfirm: "Click on the button below to confirm your enquiry via whatsapp",
      preferToTalkThenClick: "Would you prefer to talk to us first? Click",
      here: "here",
      toChatWithUs: "to chat with us via whatsapp",
      yourTeacherNeedsYourLevel: "Your teacher needs to know the student's level to prepare the class.",
      downloadTheApp: "Install the Cerah app now to test your English:",
      yourClassIsntConfirmedYet: "Your trial class is NOT booked yet",
      downloadTheAppToConfirm: "Install the Cerah app now to confirm your booking",
      installForAndroid: "Install for Android",
      installForIPhone: "Install for iPhone",
      chooseYourLevel: "Choose your level",
      levels: {
        a1: "A1 - Starter",
        a2: "A2 - Elementary",
        b1: "B1 - Pre-intermediate",
        b2: "B2 - Intermediate",
        unknown: "I don't really know",
      },
      youCanTestYourLevelInTenMinutes: "You can test your level in 10 minutes",
      testTimes: {
        now: "OK, I want to try now",
        later: "I don't have time now"
      },
      wellSendYouAMsg: "Give us your details and we'll send you a link to start the test later",
      testTakes10Minutes: "The test takes 10 minutes, give us your contact details so that we can get back to you in case of interruption",
      choosePreferredGroup: "Choose the group you prefer",
      exploreGroups: "Meanwhile, explore our group schedules and teachers",
      course: {
        tutor: "Teacher",
        schedule: "Schedule",
        seeTutor: "See tutor",
        chooseCourse: "Choose this one",
        course: "Course",
        needDifferentSchedule: "None of the schedules suit me",
        requestNewGroup: "Request a new group",
        noGroupsAvailable: "There are no groups available for this level and age range"
      },
    },
    learningMethods: {
      learnEnglishInPracticalWay: "Learn English in practical way.",
      bannerContent: "Cerah delivers English materials practically and effectively. Forget about the text-books and exercises that you got in formal learning center. We will teach English just like everyday language.",
      ourLearningMethods: "Our learning methods",
      methodTitle1: "Full practice session",
      methodContent1: "No theory, no grammar, that’s not how the brain works.\nPractice English just like your daily language",
      methodTitle2: "Fun learning",
      methodContent2: "Our class session will be fun, engaging and interactive.\nMeet our friendly teachers who will guide you to learn English",
      methodTitle3: "Learn English by doing what you love",
      methodContent3: "Cerah will give you homeworks based on your passion.\nForget about having to read boring material and textbook",
      ourMaterials: "Our Materials",
      cerahAlwaysGivesUpdatedMaterial: "Cerah always gives updated material",
      materialTailoredToStudent: "We use materials that is tailored to each student",
      turnEverthingIntoYourLearning: "Turn everything what you (or your kids) love into your learning materials.",
      yourPassionWillBecomeYourHomework: "Your K-Pop passion or Netflix binging habits will become your daily homeworks.",
      learningPlatform: "Learning platform",
      learningPlatformTitle: "We use skype as learning platform",
      learningPlatformText1: "No multiple call links needed",
      learningPlatformText2: "Only one-time signing is needed",
      learningPlatformText3: "Access all chat history and class videos through class channel",
      trackYourProgress: "Track your progress",
      EvaluationEveryThreeMonths: "We do evaulation on students' speaking skills once in 3 months",
      resultOfEvaulation: "The result of evaluation is shown in the Certificate of Participation"
    },
    testimonials: {
      bannerTitle: "What they say about Cerah",
      bannerTestimonialText: "They are amazing, the best thing is the teachers because the classes are very fun",
      bannerPersonName: "Manuel",
      bannerPersonInfo: "30-y.o. Spanish student living in the UK",
      storiesFromOurStudents: "Stories from our students",
      followTheirSuccessStory: "Follow their success path",
      joinNow: "Join Now!",
      tryItFree: "Try it free",
      reviews: {
        nova: "I used to not dare to speak English at all. Embarrassed and afraid of being wrong. I often underestimated myself in the past. Now things are very different and have turned 180 degrees. When I start communicating with foreigners, they understand what I say. I'm not afraid to be wrong anymore",
        manolo: "When I arrived in the UK I couldn't even order a coffee at a coffee shop. Now my English is still not the best, but got a job at an NHS hospital... and I can order my fish and chips :-)",
        jorge: "It is great learning with Aicks. She always has good topics and explains them very well",
        della: "During this month, I really enjoyed studying with Teacher Tine who was always patient. The learning method is also cool. It's nice to be able to learn and share experiences and culture. Overall, every class is very fun👍",
        nadia: "Thank God, because of the lessons at Cerah, the quality of my speaking English has improved. I was also able to get gold medals in several olympiads in the field of English, thank you Cerah for wanting to help me improve the quality of my speaking English.",
        siti: "My experience during English lessons at Cerah was very satisfying. Learn directly with native speakers, the class is fun and the teacher is patient. Especially now that there is a listening session.",
        alvin: "I took a course at Cerah for about 4 months... my progress was quite fast because the tutors were foreign who couldn't speak Indonesian. At first I had difficulties to describe and explain something, but the method was very fun and the feedback very helpful. Thanks of that, I felt more fluent when interviewing for foreign scholarships and managed to get an LoA!",
        nurul: "The class is good. I really enjoy studying with Lury and other class members. It is what I expected. I am more confident to speak in English. I can think in English so that I can speak fast and do not need to translate from Malay to English anymore😊",
        kaelaLeeka: "We are 9 years old. We've been studying with Cerah for almost 2 years. The classes are soo much fun. And we learn so much from Cerah classes such as grammar and new vocabulary. We can make our own schedule. Teachers are very nice, they always give us interesting topics. When we have a word we don't understand, the teacher will explain it to us and write it in chat box so that we can remember it. We think we are getting better at our grammar and we know more vocabulary."
      },
      countries: {
        ID: "Indonesia",
        MY: "Malaysia",
        PA: "Panama",
        ES: "Spain",
        US: "United States"
      }
    },
    aboutUs: {
      bannerTitle: "Cerah helps you learn English in the most practical way",
      bannerText: "Cerah is an online English learning platform. We deliver the materials in new approach. Our students are spread all over the country, so you can practice speaking and listening English easily and effectively.",
      ourStudents: "Our students",
      weCoverStudentsAllAcrossTheWorld: "We cover students all across the world",
      meetOurTeachers: "Meet our teachers"
    },
    cerahTeacher: {
      bannerTitle: "Cerah Teacher",
      bannerSubtitle: "Change people's lives, become an English tutor",
      bannerText: "We give opportunity for you who loves to teach and gives an impact to people. Enjoy the benefit of belonging to a group of excellent teachers, and get a stable source of income.",
      joinNow: "Join Now",
      teacherYourStudensOnline: "Teach your students online from home or face-to-face",
      chooseYourOwnSchedule: "Work as much or as little as you want, choose your own schedule",
      stableSourceOfIncome: "Get a stable source of income with great pay!",
      whatAreYourBenifits: "What are your benifits",
      benefitTitle1: "Just teach",
      benefitContent1: "We find the students for you. Forget about having to spend precious time posting ads and searching for students, and focus instead what you love doing: teaching",
      benefitTitle2: "Great pay",
      benefitContent2: "By letting only the best teachers join us, we can both provide great quality... and our teachers a great pay. Cerah only takes a small percentage as a fee",
      benefitTitle3: "We do the admin",
      benefitContent3: "No need to chase students or keep track of payments due. We take care of that on your behalf",
      benefitTitle4: "Never miss a class",
      benefitContent4: "Get reminders of all classes on your phone",
      benefitTitle5: "Guidance & support",
      benefitContent5: "Enjoy the benefits of belonging to a group of excellent teachers, learn from others and share experiences",
      benefitTitle6: "Access to materials",
      benefitContent6: "Get access to a wealth of materials to prepare your classes",
      teachersTestimonials: "Teacher's testimonials",
      beAPartOfCerah: "Be a part of Cerah",
      joinAsTeacher: "Join as teacher"
    },
    testCallScheduling: {
      selectTestCallSchedule: "Choose a time for a test call",
      takesJustFiveMinutes: "(the call takes just 5 minutes)",
      testCallDescription: "We'll contact you to configure your audio / video optimally before your trial class, so that you can hit the ground running :-)",
      missingParams: "We cannot show the available schedules because the link you received is incomplete. Please request a new one from our admins",
      reschedulingSuccessful: "Your test call has now been rescheduled successfully",
      thereWasAProblem: "There was an error while scheduling your test call. Please try again, and if it persists, request a new link from our admins"
    },
    day: {
      morning: "morning",
      noon: "noon",
      afternoon: "afternoon",
      evening: "evening"
    },
    teacherChoices: {
      chooseTheTeacher: "Choose the teacher",
      thatYouPrefer: "that you prefer.",
      theseTeachers: "These teachers are willing to teach you",
      checkTheTeacher: "Check this profile",
      andAccept: "and accept if you like it.",
      thisTeacher: "This teacher is willing to teach you",
      submit: "Submit",
      displayProfile: "Show profile",
      choose: "Choose teacher",
      accept: "Accept teacher",
      chosen: "Selected",
      chooseThisOne: "Choose this one",
    },
    renewals: {
      subscription: "Subscription",
      course: "Course",
      students: "Students",
      subject: "Subject",
      teacherName: "Teacher's name",
      meetingsRemaining: "Meetings remaining",
      endsOnDate: "Ends on date",
      continueSubs: "Extend current subscription",
      addMeetings: "Purchase meetings",
      changeSchedule: "Change schedule",
      selectSubsRenewal: "Select subscription package",
      howDoYouWannaPay: "How would you like to pay?",
      order: "Choose",
      hoursOfMeetings: "hours of classes",
      save: "Save",
      monthly: "One month of classes",
      yourLatestPurchase: "Your latest purchase",
      paymentDirections: {
        contactUs: "Contact us",
        andWellHelpYou: "and we will guide you through the payment process",
        toExtendYourSubscription: "To extend your subscription for",
        hours: "hours",
        pleaseTransfer: "please transfer",
        toOurCompanyAccount: "to our company account:",
        accountHolder: "Account holder",
        address: "Address",
        idLikeToRenewSubscription: "Hi Cerah! I'd like to renew my subscription. These are the details:",
      },
      payUsingCard: "By card",
      payUsingOtherMethod: "Bank / e-wallet / cash",
      requireEmail: "Please enter your email address",
      linkExpired: "Sorry, this link has expired. Please contact customer service to get a new link."
    },
    courseTypes: {
      onlineGroup: "Online Group",
      onlinePrivate: "Online Private"
    },
    availableSubjects: {
      englishBarengA1: "Group: Gen. English - A1",
      englishBarengA2: "Group: Gen. English - A2",
      englishBarengB1: "Group: Gen. English - B1",
      englishBarengB2: "Group: Gen. English - B2",
      englishBarengIELTS: "Group: IELTS",
      englishConversation: "Conversation",
      englishGeneral: "General English",
      englishBusiness: "Business English",
      englishIELTS: "IELTS prep",
      englishTOEFL: "TOEFL prep",
      englishAPTIS: "APTIS prep",
      englishEssay: "Essay writing for applications",
      bahasaGeneral: "Indonesian",
      mandarinGeneral: "Mandarin",
      germanGeneral: "German",
      eventIELTSsim: "Event - IELTS simulation",
      eventstudyAbroad: "Event - Advice to study abroad",
      eventSpeaking: "Event - Speaking activity",
    },
    certificateValidations: {
      thisConfirms: "The electronic signature of the certificate is valid",
      student: "Student: ",
      level: "Level attained: ",
      date: "Date: ",
      invalidCertificate: "The electronic signature of the certificate is INVALID",
    }
  },
  id: {
    title: "Belajar Bahasa Inggris | Les Percakapan Online | Guru Asing",
    metaDescription: "Belajar Bahasa Inggris online dan seru-seruan di kursus kami, yuk! Gabung aja di kelas kelompok atau dapatkan guru les " +
    "privat yang siap merancang program spesial buat kamu. Ada juga guru khusus untuk persiapan ujian IELTS loh!",
    main: {
      changeYourLife: "Ubah Hidupmu",
      speakEnglishWithinMonths: "Lancar bahasa Inggris dalam hitungan bulan",
      noConfusingGrammar: "Belajar bahasa Inggris dengan cara yang ampuh: Banyak praktek ngobrol mendapatkan koreksi dari guru ahlinya",
      tryNow: "COBA SEKARANG!",
      noPaymentNeeded: "Boleh coba dulu tanpa biaya loh",
      homeImageAltText: "Belajar bahasa Inggris dengan harga terjangkau",
      learnEnglishAtComfort: "Belajar bahasa Inggris online dari rumah dengan nyaman bersama guru yang berpengalaman",
      ourClasses: "Program",
      contactUs: {
        contactUs: "Hubungi kami",
        imInterested: "Halo, Kak! Aku tertarik ikut kelas bahasa Inggris nih. Boleh minta info lebih lanjut?",
      },
      readMore: "Lanjutkan membaca...",
    },
    hero: {
      title: "Belajar Bahasa Inggris dengan satu-satunya cara yang efektif:",
      speakingPractice: "Banyak latihan berbicara...",
      talkingToAHuman: "...berbicara dengan orang sungguhan...",
      gettingFeedback: "...terus dikoreksi oleh ahlinya...",
      talkingAboutYourPassions: "...dan membahas minat dan pengalamanmu",
    },
    ourMethod: {
      ourMethod: "Metode belajar",
      learnMoreOurMethod: "Pelajari lebih lanjut",
      realConversation: "Ngobrol langsung dengan guru",
      studentsGetCorrections: "kalau salah, dikoreksi langsung oleh guru",
      passionsHomework: "PR yang berkaitan dengan hobi dan passion murid",
      practiceUsingTheApp: "Latihan diluar jam pelajaran dengan aplikasi agar tetap ingat yang sudah dipelajari",
    },
    ourClasses: {
      cerahOnline: "CerahOnline",
      cerahOnlineText: "Untuk kamu yang perlu meningkatkan kemampuan bahasa Inggris untuk dunia kerja, karir, test IELTS, TOEFL, visa keluar negeri, atau untuk kamu yang mau ngobrol dengan teman dari berbagai negara.\n\
      Belajar dengan langsung praktek bahasanya langsung, tanpa teori yang rumit. Kesalahan akan dikoreksi langsung oleh guru. Boleh tanya-tanya ke guru diluar jam kelas agar mahir dengan cepat!\n\
      Kamu bisa belajar kelas private atau kelas kelompok dengan teman baru, terserah kamu!",
      buttonText: "Mulai sekarang",
      cerahKids: "CerahKids",
      cerahKidsSubtitle: "Bekali anak dari kecil ",
      cerahKidsText: "Penelitian menunjukkan kalau kemampuan belajar bahasa semakin menurun saat sudah dewasa. Beri anak kesempatan untuk belajar bahasa Inggris sejak dini agar sudah jago sebelum dewasa.\n\
      Di Cerah anak akan belajar dengan praktek langsung, pakai bahasa Inggris dari hari Pertama dengan guru yang asik, dan materi akan disesuaikan dengan ketertarikan dan hobi anak, bukan dari buku yang membosankan",
    },
    whyCerah: {
      whyCerah: "Kenapa harus Cerah?",
      aMethodThatWorks: "Metode belajar yang ampuh",
      realSpeakingPracticeNoTheory: "Sudah cukup dengan teori yang tidak berguna. Otak kita hanya bisa belajar bahasa seperti saat kita belajar naik sepeda: dengan banyak latihan, masukan setiap waktu... dan menikmatinya.",
      learnMoreMethod: "Pelajari lebih lanjut tentang metode kami",
      worldClassTeachers: "Guru berstandar internasional dalam jangkauanmu",
      ourTeachersAreFun: "Belajar Bahasa Inggris dengan guru yang terbaik di dunia, bukan hanya yang terbaik di kotamu. Bilang selamat tinggal pada kelas membosankan yang membuat kamu benci Bahasa Inggris. Guru-guru kami tahu caranya agar kelasmu jadi menyenangkan!",
      canTryBeforeYouBuy: "Coba dulu sebelum membayar",
      nothingToLose: "Kamu bisa mencoba kelasnya terlebih dulu sebelum memutuskan, dan kamu hanya perlu membayar jika suka kelasnya. Tidak ada ruginya untuk dicoba. Kami yakin kamu akan segera mendaftar setelah melihat apa yang kami tawarkan :-)",
      veryAffordablePrices: "Harga yang sangat terjangkau",
      privatePricesBetterThanYourTown: "Berskala global, kami dapat menawarkan harga untuk kelas privat yang tidak bisa kamu dapatkan di tempat tinggalmu... untuk kualitas guru yang tidak pernah kamu temui dimanapun.",
      learnWithTheApp: "Belajar di luar jam kelas dengan aplikasi kami",
      vocabularyPracticeAndProgress: "Tanpa biaya tambahan, dapatkan juga akses ke aplikasi kami. Latih kosakata yang kamu pelajari selama kelas di waktu luangmu, agar kamu tidak lupa sebelum kelas selanjutnya. Dan kamu juga bisa lacak kemajuanmu.",
    },
    whatTheySayAboutUs: {
      whatTheySayAboutUs: "Pendapat mereka tentang kami",
      seeAllTestimonials: "Lihat semua testimoni"
    },
    signUpNow: {
      beOneOfThem: "Be one of them",
      signUpNow: "Sign up now",
      tryItFree: "Try it free"
    },
    littleAboutUs: {
      title: "Little bit about us",
      aboutUsText: "We are a group of English private tutors that are fed up with how English has been taught more as maths problems than a language.\n\
      We believe strongly in learning by doing, getting immediate feedback, and practicing at all times. We encourage students to not see   English as a language that they are learning, but as one of the languages that they speak. The change of attitude and mindset will help boost   the confidence a lot and start speaking in English. Making mistakes is not a punishable event. It is the chance to learn from the mistakes   and improve. Not making mistakes means you are not doing enough. By not doing enough, you are not progressing enough.\n\
      So, join us in speaking English and improve it fast!",
      learnMore: "Learn more"
    },
    appShowcaseBanner: {
      practiceEnglishAnywhereYouAre: "Berlatih Bahasa Inggris di mana pun kamu berada",
      donwloadOurAppAndStartLearningNow: "Download aplikasi kami dan mulai belajar sekarang!"
    },
    navbar: {
      home: "Beranda",
      ourMethod: "Metode kami",
      coursesForAdults: "Kelas dewasa",
      coursesForKids: "Kelas anak",
      becomeATeacher: "Pendaftaran Guru",
      aboutUs: "Tentang Kami"
    },
    footer: {
      conquerTheWorld: "Takhlukkan dunia",
      ourServices: "Layanan",
      testimonials: "Kesaksian",
      company: "Perseroan",
      aboutUs: "Tentang kami",
      becomeTeacher: "Jadi guru",
      findUsOn: "Temukan kami di",
      allRightsReserved: "All Rights Reserved"
    },
    copyright: {
      privacyPolicy: "Kebijakan privasi",
      termsAndConditions: "Syarat & ketentuan",
      contactUs: "Hubungi kami",
    },
    teacher: {
      title: "Work As An Online English Teacher",
      metaDescription: "",
      slogan1: "Change people's lives",
      slogan2: "become an English tutor",
      startToday: "Start today",
      joinUs: "Join us!",
      mainMessage: {
        joinExclusive: "Join the exclusive club of the best private English tutors in Indonesia",
        atHome: "Teach your students online from home or face-to-face",
        workAtLeisure: "Work as much or as little as you want, and choose your own schedule",
        andMakeMoney: "Get a stable source of income with great pay!"
      },
      appShowcase: {
        howIt: "how it",
        works: "works",
        receiveNotifications: "Receive notifications of requests for courses directly on your phone",
        checkDetails: "Check details of the course request on the spot",
        acceptClass: "Accept the course if you like it. Courses last months or years",
        prepareClass: "Once the student confirms, prepare your classes and start working!",
        address: "Address",
        cemaraAsri: "Komp. Prambanan, Jalan Kembangan 72, Jakarta",
        fee: "Fee",
        seventySevenK: "115.000 / hour",
        classType: "class type",
        convo: "Conversation",
        schedule: "schedule",
        tue9am: `Tuesday | 09.00 - 10.30`,
        fri6pm: `Friday | 18.30 - 20.00`,
        level: "level",
        intermediate: "intermediate",
        groupSize: "group size",
        fourPeople: "4 people",
        studentsAge: "student age",
        doYouAccept: "do you want to teach this course?",
        cerah: "Cerah",
        classRequest: "Course request",
        studentWantsClasses: "A student is requesting classes in Pondok Indah area",
        seeDetails: "See details",
        congratulations: "Congratulations!",
        reminder: "Reminder",
        studentHasAccepted: "The student has agreed to start classes with you",
        yourClassAt: "Your first teaching day will be",
        onTuesday: "on Tuesday 6 March 2021 at 9am"
      },
      testimonials: {
        teacher: "teacher",
        testimonials: "testimonials",
        melisTestimonial: `The most helpful feature is that teachers are continuously provided with students. 
          This way, I don't need to spam on my social media or something just to inform others 
          that I'm available if someone needs an English teacher`,
        ilhamsTestimonial: `I was impressed by how they select and filter the teachers so that only 
          the most qualified ones can teach, to guarantee the effectiveness. Teaching is 
          a very professional occupation that cannot be taken for granted. And they pay their teachers 
          accordingly. I'm happy with the numbers I earn`,
        cuncunsTestimonial: `Nowadays, we order food, buy clothes, etc., all via internet through 
          the flick of our fingers. Booking private tutors online can be a 
          breakthrough in education. Students can have their own private tutors at places they wish, 
          and for us teachers, we get more students to teach. It's a great idea`,
        camelia: "Melly",
        ilham: "Ilham",
        cuncun: "Cun Cun"
      },
      benefits: {
        benefits: "benefits",
        your: "your",
        peaceOfMind: "just teach",
        payRates: "great pay",
        forgetAdmin: "we do the admin",
        neverMissClass: "never miss a class",
        support: "guidance & support",
        materials: "access to materials",
        noMarketing: "We find the students for you. Forget about having to spend precious time posting ads and searching for students, and focus instead what you love doing: teaching",
        onlyBestTeachers: "By letting only the best teachers join us, we can both provide great quality... and our teachers a great pay. Cerah only takes a small percentage as a fee",
        noChasingForPayments: "No need to chase students or keep track of payments due. We take care of that on your behalf",
        reminders: "Get reminders of all classes on your phone",
        networking: "Enjoy the benefits of belonging to a group of excellent teachers, learn from others and share experiences",
        wealthOfMaterials: "Get access to a wealth of materials to prepare your classes"
      }
    },
    enquiry: {
      teacher: {
        fillForm: "Create your account",
        intro: "Input your personal details as a first step to join Cerah as a teacher",
        form: {
          label: {
            name: "Enter your name below",
            phone: "WhatsApp number or phone number (include country code!)",
            email: "E-mail (we'll try this if the phone fails)",
            send: "Submit"
          },
          placeholder: {
            name: "Name",
            phone: "xxxxxxxxxx",
            email: "e.g example@example.com",
          },
          errors: {
            invalidPhoneFormat: "Please include the country prefix (e.g., +44 for the UK)",
            invalidEmailFormat: "Invalid email format, please revise",
          }
        },
      },
    },
    thankYou: {
      thankYou: "Terima kasih! Kami akan segera menghubungi kamu",
      back: "Kembali"
    },
    quiz: {
      now: `Start now`,
      later: 'Do later',
      start: `Start`,
      done: `Done`,
      resultsSoon: `You've finished the test now. We will get back to you soon.`,
      question: `Question`,
      submit: 'Next',
      unknownError: `An error ocurred. Please try again later`,
      notFound: `Test not found`,
      level: {
        starter: "Starter",
        elementary: "Elementary",
        preIntermediate: "Pre-intermediate",
        intermediate: "Intermediate",
        upperIntermediate: "Upper intermediate",
        advanced: "Advanced",
      },
      levelResults: {
        yourLevelIs: "Hore! Ternyata levelmu adalah",
        nextComeCourses: "Cari kelas yang paling cocok untuk kamu",
        continue: "Selanjutnya"
      },
    },
    midtrans: {
      success: {
        "result": "Sukses",
        "title": "Selamat!",
        "detail": "Pembayaran kamu berhasil! Terima kasih!",
        "done": "Selesai"
      },
      failure: {
        "result": "Kesalahan",
        "title": "Maaf!",
        "detail": "Pembayaran kamu bersalah. Silahkan coba lagi.",
        "done": "Dicatat"
      },
      incomplete: {
        "result": "Belum lunas",
        "title": "Awas",
        "detail": "Kamu belum menyelesaikan pembayaran kamu.",
        "done": "Dicatat"
      },
    },
    levelTest: {
      intro: "Tes singkat ini akan membantu kamu untuk tahu level kamu saat ini. Cuma 10 menit, 25 pertanyaan. Pilih jawaban paling cocok untuk setiap pertanyaan"
    },
    npsTest: {
      thanks: "Terima kasih atas pendapatmu!",
      feedbackEssential: "Masukan kamu sangat penting untuk memperbaharui layanan kami",
      home: "Beranda"
    },
    cerahKids: {
      kidsImgAltText: "Anak belajar bahasa Inggris online",
      pageTitle: "Kursus Bahasa Inggris Online Untuk Anak",
      metaDescription: "Les Bahasa Inggris online dengan guru privat asing berpengalaman dan berkualifikasi yang tahu cara membuat kelas menjadi mudah, menarik, dan menyenangkan! Berikan anak-anak Anda kemampuan Bahasa Inggris yang mereka perlukan di dunia global di masa depan",
      whatIstheAgeOfTheStudent: "Umur muridnya berapa aja?",
      title: "CerahKids",
      startLearningEarly: "Mulai belajar Bahasa Inggris sejak dini",
      content: "Berikan anak-anak kesempatan belajar bahasa Inggris melalui percakapan langsung dengan guru yang seru dan yang tahu cara menarik perhatian mereka. Guru kami akan merancang materi kelas seputar pahlawan, dongeng, and permainan yang sang buah hati senangi, bukan membahas buku teori membosankan",
      tryItNow: "Coba sekarang",
      KidsFutureImgAltText: "Belajar bahasa inggris untuk anak",
      didYouKnow: {
        sixtyCountries: "Tahukah kamu kalau bahasa Inggris itu dipakai di lebih dari 60 negara? ",
        broadenTheirHorizons: "Tingkatkan masa depan si buah hati dengan kemampuan bahasa Inggris"
      },
      nineYearOldIndonesianStudents: "Murid Indonesia berumur 9 tahun",
      emergencyContact: {
        form: {
          label: {
            studentAge: "Usia pelajar",
            studentName: "Nama siswa",
            name: "Nama orang tua",
            email: "Email orang tua",
            phone: "Telepon",
            whatsapp: "Nomor WhatsApp",
            voucher: "Kode promo (jika ada)",
            send: "Kirim"
          },
          placeholder: {
            studentAge: "Usia siswa",
            studentName: "Nama lengkap",
            name: "Nama lengkap",
            phone: "XXXXXXXXXX",
            email: "nama@contoh.com"
          },
          invalidPhoneFormat: "Silakan masukkan imbuhan negara (e.g., +62 untuk Indonesia)",
          numberNotOnWhatsapp: "Nomor ini tidak terdaftar di WhatsApp, silakan periksa lagi",
          invalidEmailFormat: "Format email tidak sah, mohon diperiksa",
        }
      },
      tryBeforeBuy: {
        title: "Tertarik?",
        subTitle: "Coba dulu, bayar nanti!",
        btnText: "Coba sekarang",
      }
    },
    courseSelection: {
      schedule: "Jadwal",
      teacherProfile: {
        education: "Pendidikan",
        qualifications: "Kualifikasi",
        experience: "Pengalaman",
        specialisations: "Spesialisasi"
      }
    },
    cerahOnline: {
      studentImgAltText: "Belajar bahasa Inggris secara daring",
      pageTitle: "CerahOnline - Belajar Bahasa Inggris daring dengan guru asing",
      metaDescription: "Latih bahasa Inggris kamu secara online dari mana saja dengan guru asing. Kursus online yang praktis, berfokus pada percakapan dan interaksi sosial untuk belajar bahasa Inggris secara alami dan mudah.",
      howOldIsStudent: "Berapa usia murid?",
      underage: "Di bawah 16 tahun",
      adult: "16+",
      title: "Mendunia dalam sekejap",
      content: "Baik kamu mau mendapatkan pekerjaan impian, melakukan bisnis internasional maupun mendapatkan skor IELTS agar bisa belajar di luar negeri, kursus bahasa Inggris Cerah akan membuatmu menwujudkannya secepat mungkin.",
      benefits: "Keuntungan",
      courseTypes: {
        courseTypes: "Jenis kursus kelas daring",
        courseTypesKids: "Jenis kursus untuk anak-anak",
        privateClass: "Privat",
        privateClassSubtitle: "Dapatakan guru bahasa Inggris yang ahli hanya untuk kamu",
        privateCourses: "Kursus privat daring",
        groupCourses: "Kursus kelompok daring",
        perHour: " /j",
        groupClass: "Kelompok",
        groupOfTwoSubtitle: "Belajar bahasa Inggris dengan seorang teman",
        groupOfThreeSubtitle: "Belajar bahasa Inggris membahas dalam kelompok",
        groupOfFourSubtitle: "Belajar bahasa Inggris dengan teman-teman dari berbagai negara",
        people: "orang",
        perMonth: " /bulan",
        equivalentTo: "setara dengan",
        sixtyMinutesSessions: "60 menit / sesi",
        twoPerWeek: "2x seminggu",
      },
      wannaTry: "Tertarik?",
      itsFree: "Coba dulu, nanti bayar",
      tryItNow: "Coba sekarang",
      chooseSchedule: "Pilih jadwal kamu",
      yourSchedule: "Jadwalmu",
      weekdays: "Hari kerja",
      weekends: "Akhir pekan",
      morning: "Pagi",
      afternoon: "Siang/Sore",
      night: "Malam",
      wantBut: "Saya mau tapi",
      dontKnowWhen: "belum tahu kapan",
      group: "Kelas kelompok",
      individual: "Kelas privat",
      whatsapp: "WhatsApp / Text",
      line: "Line / Text",
      callback: "Panggilan telpon",
      youWantGroupIndividual: "Pilih kelas yang kamu mau",
      howYouWannaBeContacted: "Kamu ingin dihubungi melalui...",
      howManyMeetingsPerWeek: "Berapa kali per minggu?",
      meetingsPerWeek: "pertemuan per minggu",
      choice: {
        youveChosen: "Kamu telah memilih",
        months: "bulan",
        perWeek: "seminggu",
        perMonth: "per bulan",
        month: "bln",
        butFree: "Tapi boleh coba sebelum bayar?",
        freeTrialExplanation: "Coba kelas GRATIS! Bayar nanti kalau suka kelasnya 😊",
        groupClass: "Kelas kelompok",
        privateClass: "Kelas privat",
        confirm: "Konfirmasi"
      },
      scheduleSummary: {
        change: "Ubah",
        agree: "Setuju"
      },
      chooseTeacher: "Pilih guru",
      yourClass: "Ini pilihan kamu",
      whenTrial: "Kapan kelas percobaan ingin dilakukan?",
      later: "Hari lain",
      giveUsYourDetails: "Silakan isi rincian kontak kamu",
      ifEmergencyHowToContactYou: "Jika perubahan jadwal diperlukan, kamu ingin dihubungi melalui...",
      emergencyContact: {
        form: {
          label: {
            studentAge: "usia pelajar",
            studentName: "Nama siswa",
            name: "Nama",
            email: "Email",
            phone: "Telpon",
            whatsapp: "Nomor WhatsApp",
            voucher: "Kode promo (jika ada)",
            send: "Kirim"
          },
          placeholder: {
            studentAge: "usia siswa",
            studentName: "Nama lengkap",
            name: "Nama lengkap",
            phone: "XXXXXXXXXX",
            email: "nama@contoh.com"
          },
          invalidPhoneFormat: "Silakan masukkan imbuhan negara (e.g., +62 untuk Indonesia)",
          numberNotOnWhatsapp: "Nomor ini tidak terdaftar di WhatsApp, silakan periksa lagi",
          invalidEmailFormat: "Format email tidak sah, mohon diperiksa",
        }
      },
      selectTestCallSchedule: "Pilih tanggal dan jam untuk tes koneksi",
      takesJustFiveMinutes: "(tesnya butuh 5 menit aja)",
      testCallDescription: "Kami akan menghubungi kamu dulu untuk pastikan audio dan video berfungsi dengan baik",
      noScheduleAvailableForTestCall: "Maaf, tidak ada jadwal tersedia. Silakan pilih hari lain",
      testCallChoice: "Kami akan tes koneksi kamu pada",
      clickOnWaButtonToConfirm: "Klik tombol di bawah untuk konfirmasi permintaan kelas kamu via WhatsApp",
      preferToTalkThenClick: "Mau konsultasi dengan kami dulu? Klik ",
      here: "di sini",
      toChatWithUs: "untuk menghubungi kami via WhatsApp",
      yourTeacherNeedsYourLevel: "Gurumu perlu tau level murid untuk menyiapkan kelas.",
      downloadTheApp: "Silakan install aplikasi Cerah untuk tes level Bahasa Inggrisnya:",
      yourClassIsntConfirmedYet: "Kelas percobaan kamu BELUM terkonfirmasi",
      downloadTheAppToConfirm: "Untuk mengkonfirmasi kelasnya, silakan unduh aplikasi Cerah sekarang",
      installForAndroid: "Unduh untuk Android",
      installForIPhone: "Unduh untuk iPhone",
      chooseYourLevel: "Pilih level kamu?",
      levels: {
        a1: "A1 - Starter",
        a2: "A2 - Elementary",
        b1: "B1 - Pre-intermediate",
        b2: "B2 - Intermediate",
        unknown: "Belum tau",
      },
      youCanTestYourLevelInTenMinutes: "Kamu bisa cek levelmu hanya dalam 10 menit. Mau coba?",
      testTimes: {
        now: "OK, mau coba sekarang",
        later: "Tidak ada waktu saat ini"
      },
      wellSendYouAMsg: "Beri info kontak kamu untuk kami kirimkan tes level untuk kamu kerjakan nanti",
      testTakes10Minutes: "Tes level berdurasi sekitar 10 menit. Masukkan informasi kontak kamu untuk kami hubungi jika ada gangguan.",
      choosePreferredGroup: "Pilih kelompok yang kamu lebih suka",
      exploreGroups: "Klik di bawah ini untuk cek jadwal kelas berjalan",
      course: {
        tutor: "Guru",
        schedule: "Jadwal",
        seeTutor: "Lihat guru",
        chooseCourse: "Pilih ini",
        course: "Kursus",
        needDifferentSchedule: "Tidak ada jadwal yang sesuai untuk saya",
        requestNewGroup: "Minta grup baru",
        noGroupsAvailable: "Tidak ada grup yang tersedia untuk level dan rentang usia ini"
      },
    },
    learningMethods: {
      learnEnglishInPracticalWay: "Learn English in practical way.",
      bannerContent: "Cerah delivers English materials practically and effectively. Forget about the text-books and exercises that you got in formal learning center. We will teach English just like everyday language.",
      ourLearningMethods: "Our learning methods",
      methodTitle1: "Full practice session",
      methodContent1: "No theory, no grammar, that’s not how the brain works.\nPractice English just like your daily language",
      methodTitle2: "Fun learning",
      methodContent2: "Our class session will be fun, engaging and interactive.\nMeet our friendly teachers who will guide you to learn English",
      methodTitle3: "Learn English by doing what you love",
      methodContent3: "Cerah will give you homeworks based on your passion.\nForget about having to read boring material and textbook",
      ourMaterials: "Our Materials",
      cerahAlwaysGivesUpdatedMaterial: "Cerah always gives updated material",
      materialTailoredToStudent: "We use materials that is tailored to each student",
      turnEverthingIntoYourLearning: "Turn everything what you (or your kids) love into your learning materials.",
      yourPassionWillBecomeYourHomework: "Your K-Pop passion or Netflix binging habits will become your daily homeworks.",
      learningPlatform: "Learning platform",
      learningPlatformTitle: "We use skype as learning platform",
      learningPlatformText1: "No multiple call links needed",
      learningPlatformText2: "Only one-time signing is needed",
      learningPlatformText3: "Access all chat history and class videos through class channel",
      trackYourProgress: "Track your progress",
      EvaluationEveryThreeMonths: "We do evaulation on students' speaking skills once in 3 months",
      resultOfEvaulation: "The result of evaluation is shown in the Certificate of Participation"
    },
    testimonials: {
      bannerTitle: "Pendapat murid tentang Cerah",
      bannerTestimonialText: "Mereka hebat, yang terbaik adalah guru-gurunya, karena kelasnya sangat menyenangkan",
      bannerPersonName: "Manuel",
      bannerPersonInfo: "30 tahun. Murid Spanyol yang tinggal di Inggris",
      storiesFromOurStudents: "Cerita dari murid-murid kami",
      followTheirSuccessStory: "Ikuti jalan kesuksesan mereka",
      joinNow: "Bergabung sekarang!",
      tryItFree: "Coba gratis",
      reviews: {
        nova: "Aku dulu sama sekali tidak berani pakai Bahasa Inggris. Malu. Takut salah. Dulu sering underestimate diri sendiri. Sekarang keadaan beda banget. 180 derajat. Ketika aku mulai berkomunikasi dengan orang asing, mereka mengerti apa yang aku bilang. Jangan takut salah",
        manolo: "Saat pertama kali di UK dulu saya bahkan gak bisa pesan kopi. Sekarang bahasa Inggris saya belum sempurna, tapi saya bisa dapat pekerjaan di Rumah Sakit NHS... dan bahkan udah bisa pesan fish and chips :-)",
        jorge: "Sangat memuaskan belajar dengan guru Aicks. Dia selalu memiliki topik yang bagus dan menjelaskannya dengan sangat baik",
        della: "Selama sebulan ini, seneng banget belajar dengan Teacher Tine yang selalu sabar. Metode pembelajarannya juga asik. Seneng rasanya bisa belajar sekaligus sharing pengalaman dan budaya masing2. Overall, setiap kelasnya menyenangkan sekali👍",
        nadia: "Alhamdulillah karena les di cerah kualitas speaking English aku meningkat. aku juga bisa mendapatkan medali emas di beberapa olimpiade bidang bahasa inggris, terimakasih buat cerah karena udah mau bantu aku dalam meningkatkan kualitas speaking English aku yaa",
        siti: "Pengalamanku selama les  bahasa Inggris di Cerah, sangat puas.  Belajar langsung dengan native speaker, kelasnya fun dan gurunya sabar. Apalagi sekarang ada sesi listening. Sangat diperhatikan pronunciation saat berbicara, sehingga siswa tahu pelafalan yang benar.",
        alvin: "Saya mengikuti kursus di Cerah selama 4 bulan, fokus dalam meningkatkan kemampuan komunikasi bahasa Inggris. Kemajuan saya rasakan cukup pesat karena tutor tidak bisa berbicara Bahasa Indonesia. Awalnya saya kesulitan saat mendeskripsikan dan menjelaskan sesuatu, namun metode yg diterapkan selama dinamika di kelas sangat menyenangkan dan masukan yg diberikan sangat membantu. Oleh karena itu, saya merasa lebih lancar saat interview beasiswa luar negeri dan berhasil mendapatkan LoA!",
        nurul: "Kelasnya bagus. Saya sangat suka belajar dengan Lury dan teman-teman kelas yang lain. Kelasnya sesuai dengan harapan saya. Saya jadi lebih percaya diri untuk bicara bahasa Inggris. Saya bisa berpikir dalam bahasa Inggris, sehingga saya bisa bicara dengan cepat dan tidak perlu menerjemahkan dari bahasa Malaysia ke Inggris lagi 😊",
        kaelaLeeka: "Umur kami 9 tahun. Kami sudah belajar di Cerah selama hampir 2 tahun. Kelasnya seru banget. Kami belajar banyak dari kelas Cerah, seperti tata bahasa dan kosakata. Kami juga bisa mengatur jadwal kami sendiri. Guru-gurunya sangat baik, mereka selalu memberikan topik-topik menarik. Saat ada kata yang belum kami tahu, guru Cerah akan menjelaskan dan menuliskannya di kolom chat, supaya bisa kami ingat.  Grammar bahasa Inggris kami jadi lebih bagus, dan kami jadi tahu lebih banyak vocabulary."
      },
      countries: {
        ID: "Indonesia",
        MY: "Malaysia",
        PA: "Panama",
        ES: "Spanyol",
        US: "Amerika Serikat"
      }
    },
    aboutUs: {
      bannerTitle: "Cerah helps you learn English in the most practical way",
      bannerText: "Cerah is an online English learning platform. We deliver the materials in new approach. Our students are spread all over the country, so you can practice speaking and listening English easily and effectively.",
      ourStudents: "Our students",
      weCoverStudentsAllAcrossTheWorld: "We cover students all across the world",
      meetOurTeachers: "Meet our teachers"
    },
    cerahTeacher: {
      bannerTitle: "Cerah Teacher",
      bannerSubtitle: "Change people's lives become an English tutor",
      bannerText: "We give opportunity for you who loves to teach and gives an impact to people. Enjoy the benefit of belonging to a group of excellent teachers, and get a stable source of income.",
      joinNow: "Join Now",
      teacherYourStudensOnline: "Teach your students online from home or face-to-face",
      chooseYourOwnSchedule: "Work as much or as little as you want, choose your own schedule",
      stableSourceOfIncome: "Get a stable source of income with great pay!",
      whatAreYourBenifits: "What are your benifits",
      benefitTitle1: "Just teach",
      benefitContent1: "We find the students for you. Forget about having to spend precious time posting ads and searching for students, and focus instead what you love doing: teaching",
      benefitTitle2: "Great pay",
      benefitContent2: "By letting only the best teachers join us, we can both provide great quality... and our teachers a great pay. Cerah only takes a small percentage as a fee",
      benefitTitle3: "We do the admin",
      benefitContent3: "No need to chase students or keep track of payments due. We take care of that on your behalf",
      benefitTitle4: "Never miss a class",
      benefitContent4: "Get reminders of all classes on your phone",
      benefitTitle5: "Guidance & support",
      benefitContent5: "Enjoy the benefits of belonging to a group of excellent teachers, learn from others and share experiences",
      benefitTitle6: "Access to materials",
      benefitContent6: "Get access to a wealth of materials to prepare your classes",
      teachersTestimonials: "Teacher's testimonials",
      beAPartOfCerah: "Be a part of Cerah",
      joinAsTeacher: "Join as teacher"
    },
    testCallScheduling: {
      selectTestCallSchedule: "Pilih tanggal dan jam untuk tes koneksi",
      takesJustFiveMinutes: "(tesnya butuh 5 menit aja)",
      testCallDescription: "Kami akan menghubungi kamu dulu untuk pastikan audio dan video berfungsi dengan baik",
      missingParams: "Jadwal yang tersedia tidak bisa ditampilkan karena link yang diberikan tidak lengkap. Silakan minta link baru dari admin kami",
      reschedulingSuccessful: "Tes koneksi kamu sudah berhasil dijadwalkan",
      thereWasAProblem: "Oops! Ada masalah dalam menjadwalkan tes koneksi kamu. Silakan coba lagi. Kalau masih tidak bisa, silakan minta link baru dari admin kami"
    },
    day: {
      morning: "pagi",
      noon: "siang",
      afternoon: "sore",
      evening: "malam"
    },
    teacherChoices: {
      chooseTheTeacher: "Pilih guru",
      thatYouPrefer: "yang kamu mau.",
      theseTeachers: "Guru ini tersedia untukmu",
      checkTheTeacher: "Terima guru",
      andAccept: "jika profilnya cocok untukmu.",
      thisTeacher: "Guru ini tersedia untukmu",
      submit: "Kirim",
      displayProfile: "Tampilkan profil",
      choose: "Pilih guru",
      accept: "Terima guru",
      chosen: "Terpilih",
      chooseThisOne: "Pilih guru",
    },
    renewals: {
      subscription: "Berlangganan",
      course: "Kursus",
      students: "Murid",
      subject: "Jenis kursus",
      teacherName: "Guru",
      meetingsRemaining: "Sisa pertemuan",
      endsOnDate: "Berakhir pada tanggal",
      continueSubs: "Tambah pertemuan",
      addMeetings: "Beli paket kelas",
      changeSchedule: "Ubah jadwal",
      selectSubsRenewal: "Pilih paket langganan",
      howDoYouWannaPay: "Mau bayar pakai...",
      order: "Pilih",
      hoursOfMeetings: "jam kelas",
      save: "Hemat",
      monthly: "1 bulan kelas",
      yourLatestPurchase: "Pembelian terbaru kamu",
      paymentDirections: {
        contactUs: "Hubungi kami",
        andWellHelpYou: "dan kami akan membantu kamu dengan proces pembayaran",
        toExtendYourSubscription: "Untuk melanjutkan berlanggan kamu selama",
        hours: "jam",
        pleaseTransfer: "silakan kirim",
        toOurCompanyAccount: "ke akun perusahaan kami:",
        accountHolder: "Pemanganan akun",
        address: "Alamat",
        idLikeToRenewSubscription: "Hello Cerah! Saya ingin memperpanjang berlangganan saya. Ini detail:",
      },
      payUsingCard: "kartu",
      payUsingOtherMethod: "bank / e-wallet / tunai",
      requireEmail: "Masukkan email kamu",
      linkExpired: "Mohon maaf, link ini kadaluarsa. Silakan, hubungi pelayanan pelanggan Cerah untuk mendapatkan link baru."
    },
    courseTypes: {
      onlineGroup: "Online kelompok",
      onlinePrivate: "Online pribadi"
    },
    availableSubjects: {
      englishBarengA1: "Group: Gen. English - A1",
      englishBarengA2: "Group: Gen. English - A2",
      englishBarengB1: "Group: Gen. English - B1",
      englishBarengB2: "Group: Gen. English - B2",
      englishBarengIELTS: "Group: IELTS",
      englishConversation: "Conversation",
      englishGeneral: "General English",
      englishBusiness: "Business English",
      englishIELTS: "IELTS prep",
      englishTOEFL: "TOEFL prep",
      englishAPTIS: "APTIS prep",
      englishEssay: "Essay writing for applications",
      bahasaGeneral: "Indonesian",
      mandarinGeneral: "Mandarin",
      germanGeneral: "Jerman",
      eventIELTSsim: "Acara - IELTS simulation",
      eventstudyAbroad: "Acara - Advice to study abroad",
      eventSpeaking: "Acara - Speaking activity",
    },
    certificateValidations: {
      thisConfirms: "Tanda tangan elektronik sertifikat sah",
      student: "Murid: ",
      level: "Tingkat: ",
      date: "Tanggal: ",
      invalidCertificate: "Tanda tangan elektronik sertifikat TIDAK SAH",
    }
  },
  es: {
    title: "Curso de Inglés Online | Clases de Conversación | Prueba Gratis",
    metaDescription: "Aprende inglés disfrutando en nuestros cursos online! Apúntate a clases en grupo o elige un profesor particular para tener un programa a medida. ¡También hay profesores de IELTS y TOEFL!",
    main: {
      changeYourLife: "Cambia tu vida",
      speakEnglishWithinMonths: "Habla inglés en unos meses",
      noConfusingGrammar: "Aprende inglés de la manera que realmente funciona: conversaciones reales con las correcciones expertas de un profesor humano",
      tryNow: "Prueba ahora",
      noPaymentNeeded: "Y paga sólo si te gusta",
      homeImageAltText: "Aprende inglés a precios asequibles",
      learnEnglishAtComfort: "Aprende inglés online cómodamente desde casa con nuestros profesores expertos",
      ourClasses: "Nuestros programas",
      contactUs: {
        contactUs: "Contáctanos",
        imInterested: "Hola! Me interesan vuestras clases de inglés. Me gustaría saber más",
      },
      readMore: "leer más...",
    },
    hero: {
      title: "Aprende inglés de la única manera que funciona:",
      speakingPractice: "Práctica intensiva de conversación real...",
      talkingToAHuman: "...con una persona de verdad...",
      gettingFeedback: "...que te corrige de manera experta...",
      talkingAboutYourPassions: "...mientras hablas de lo que te apasiona a TI",
    },
    ourMethod: {
      ourMethod: "Nuestro método",
      learnMoreOurMethod: "Leer más sobre nuestro método",
      realConversation: "Conversaciones reales con personas reales",
      studentsGetCorrections: "Los alumnos reciben correcciones inmediatas de sus errores",
      passionsHomework: "Practica en tus ratos libres con tareas basadas en tus hobbies y tus pasiones",
      practiceUsingTheApp: "Practica entre clases con nuestra app para no olvidar lo que has aprendido",
    },
    ourClasses: {
      cerahOnline: "CerahOnline",
      cerahOnlineText: "Para ti que necesitas mejorar tu inglés para prepararte para el mercado de trabajo, para progresar profesionalmente, para sacar buena nota en un test de Cambridge, para conseguir una visa para vivir en el extranjero, o simplemente quieres hacer amigos en otros países\n\
      Aprende inglés usando el idioma desde el primer momento, sin tediosas explicaciones teóricas. Recibe correcciones de nuestros expertos tutores y su asesoramiento para organizar tu rutina de práctica fuera de la clase. Y mejora en nada de tiempo!\n\
      Puedes aprender en clases particulares o con nuevos amigos, tú decides!",
      buttonText: "Empieza ya",
      cerahKids: "CerahKids",
      cerahKidsSubtitle: "Prepara a tus hijos desde pequeños",
      cerahKidsText: "Los estudios científicos muestran que la capacidad para aprender un idioma empieza a decaer al terminar la adolescencia. Dales a tus hijos la oportunidad de empezar a aprender inglés pronto para que lo dominen antes de llegar a adultos\n\
      En Cerah aprenderán de manera práctica, usando inglés desde el primer día, con profesores divertidos y cercanos, y con materiales ajustados a sus intereses y aficiones, en lugar de aburridos libros de teoría",
    },
    whyCerah: {
      whyCerah: "¿Por qué Cerah?",
      aMethodThatWorks: "Un método que funciona",
      realSpeakingPracticeNoTheory: "No más teoría inútil. Nuestro cerebro sólo puede aprender un idioma de la manera que aprende a montar en bici: con mucha práctica de verdad, feedback continuo ...y disfrutando del paseo",
      learnMoreMethod: "Más sobre nuestro método",
      worldClassTeachers: "Profesores de nivel internacional al alcance de tus dedos",
      ourTeachersAreFun: "Aprende inglés con los mejores profesores de inglés del mundo, no del barrio. Despídete te profesores aburridos que te hacen aborrecer el inglés. Nuestros profes saben cómo hacer una clase divertida",
      canTryBeforeYouBuy: "Puedes probar antes de decidir",
      nothingToLose: "Prueba nuestras clases antes de hacer ningún pago, y paga sólo si te gustan. No pierdes nada por probar. Estamos seguros de que te inscribirás después de ver lo que ofrecemos :-)",
      veryAffordablePrices: "Precios muy económicos",
      privatePricesBetterThanYourTown: "Trabajar a escala global nos permite ofrecerte precios por las clases particulares que nunca podrías conseguir donde vives... con profesores que tampoco podrías conseguir donde vives",
      learnWithTheApp: "Sigue aprendiendo después de clase con nuestra app",
      vocabularyPracticeAndProgress: "Por el precio de las clases, te damos acceso a nuestra app también. Practica el vocabulario que aprendiste durante la clase en tu tiempo libre, para que no se te olvide lo que aprendes entre clases, y monitoriza tus avances",
    },
    whatTheySayAboutUs: {
      whatTheySayAboutUs: "Lo que dicen de nosotros",
      seeAllTestimonials: "Ver todos los testimonios"
    },
    signUpNow: {
      beOneOfThem: "Sé uno de ellos",
      signUpNow: "Apúntate ahora mismo",
      tryItFree: "Prueba ahora"
    },
    littleAboutUs: {
      title: "Little bit about us",
      aboutUsText: "We are a group of English private tutors that are fed up with how English has been taught more as maths problems than a language.\n\
      We believe strongly in learning by doing, getting immediate feedback, and practicing at all times. We encourage students to not see   English as a language that they are learning, but as one of the languages that they speak. The change of attitude and mindset will help boost   the confidence a lot and start speaking in English. Making mistakes is not a punishable event. It is the chance to learn from the mistakes   and improve. Not making mistakes means you are not doing enough. By not doing enough, you are not progressing enough.\n\
      So, join us in speaking English and improve it fast!",
      learnMore: "Learn more"
    },
    appShowcaseBanner: {
      practiceEnglishAnywhereYouAre: "Practica inglés donde estés",
      donwloadOurAppAndStartLearningNow: "Descarga nuestra app y empieza a aprender ya!"
    },
    navbar: {
      home: "Home",
      ourMethod: "Nuestro método",
      coursesForAdults: "Clases adultos",
      coursesForKids: "Clases niños",
      becomeATeacher: "Become a teacher",
      aboutUs: "Acerca de",
    },
    footer: {
      conquerTheWorld: "Conquista el mundo",
      ourServices: "Nuestros servicios",
      testimonials: "Testimonios",
      company: "Empresa",
      aboutUs: "Acerca de nosotros",
      becomeTeacher: "Hazte profesor",
      findUsOn: "Encuéntranos en",
      allRightsReserved: "Todos los derechos reservados"
    },
    copyright: {
      privacyPolicy: "Política de privacidad",
      termsAndConditions: "Términos y condiciones",
      contactUs: "Contáctanos",
    },
    teacher: {
      title: "Work As An Online English Teacher",
      metaDescription: "",
      slogan1: "Change people's lives",
      slogan2: "become an English tutor",
      startToday: "Start today",
      joinUs: "Join us!",
      mainMessage: {
        joinExclusive: "Join the exclusive club of the best private English tutors in Indonesia",
        atHome: "Teach your students online from home or face-to-face",
        workAtLeisure: "Work as much or as little as you want, and choose your own schedule",
        andMakeMoney: "Get a stable source of income with great pay!"
      },
      appShowcase: {
        howIt: "how it",
        works: "works",
        receiveNotifications: "Receive notifications of requests for courses directly on your phone",
        checkDetails: "Check details of the course request on the spot",
        acceptClass: "Accept the course if you like it. Courses last months or years",
        prepareClass: "Once the student confirms, prepare your classes and start working!",
        address: "Address",
        cemaraAsri: "Komp. Prambanan, Jalan Kembangan 72, Jakarta",
        fee: "Fee",
        seventySevenK: "115.000 / hour",
        classType: "class type",
        convo: "Conversation",
        schedule: "schedule",
        tue9am: `Tuesday | 09.00 - 10.30`,
        fri6pm: `Friday | 18.30 - 20.00`,
        level: "level",
        intermediate: "intermediate",
        groupSize: "group size",
        fourPeople: "4 people",
        studentsAge: "student age",
        doYouAccept: "do you want to teach this course?",
        cerah: "Cerah",
        classRequest: "Course request",
        studentWantsClasses: "A student is requesting classes in Pondok Indah area",
        seeDetails: "See details",
        congratulations: "Congratulations!",
        reminder: "Reminder",
        studentHasAccepted: "The student has agreed to start classes with you",
        yourClassAt: "Your first teaching day will be",
        onTuesday: "on Tuesday 6 March 2018 at 9am"
      },
      testimonials: {
        teacher: "teacher",
        testimonials: "testimonials",
        melisTestimonial: `The most helpful feature is that teachers are continuously provided with students. 
          This way, I don't need to spam on my social media or something just to inform others 
          that I'm available if someone needs an English teacher`,
        ilhamsTestimonial: `I was impressed by how they select and filter the teachers so that only 
          the most qualified ones can teach, to guarantee the effectiveness. Teaching is 
          a very professional occupation that cannot be taken for granted. And they pay their teachers 
          accordingly. I'm happy with the numbers I earn`,
        cuncunsTestimonial: `Nowadays, we order food, buy clothes, etc., all via internet through 
          the flick of our fingers. Booking private tutors online can be a 
          breakthrough in education. Students can have their own private tutors at places they wish, 
          and for us teachers, we get more students to teach. It's a great idea`,
        camelia: "Melly",
        ilham: "Ilham",
        cuncun: "Cun Cun"
      },
      benefits: {
        benefits: "benefits",
        your: "your",
        peaceOfMind: "just teach",
        payRates: "great pay",
        forgetAdmin: "we do the admin",
        neverMissClass: "never miss a class",
        support: "guidance & support",
        materials: "access to materials",
        noMarketing: "We find the students for you. Forget about having to spend precious time posting ads and searching for students, and focus instead what you love doing: teaching",
        onlyBestTeachers: "By letting only the best teachers join us, we can both provide great quality... and our teachers a great pay. Cerah only takes a small percentage as a fee",
        noChasingForPayments: "No need to chase students or keep track of payments due. We take care of that on your behalf",
        reminders: "Get reminders of all classes on your phone",
        networking: "Enjoy the benefits of belonging to a group of excellent teachers, learn from others and share experiences",
        wealthOfMaterials: "Get access to a wealth of materials to prepare your classes"
      }
    },
    enquiry: {
      teacher: {
        fillForm: "Create your account",
        intro: "Input your personal details as a first step to join Cerah as a teacher",
        form: {
          label: {
            name: "Enter your name below",
            phone: "WhatsApp number or phone number (include country code!)",
            email: "E-mail (we'll try this if the phone fails)",
            send: "Submit"
          },
          placeholder: {
            name: "Name",
            phone: "xxxxxxxxxx",
            email: "e.g example@example.com",
          },
          errors: {
            invalidPhoneFormat: "Please include the country prefix (e.g., +44 for the UK)",
            invalidEmailFormat: "Invalid email format, please revise",
          }
        },
      },
    },
    thankYou: {
      thankYou: "Gracias! En breve nos pondremos en contacto contigo :-)",
      back: "Volver"
    },
    quiz: {
      now: `Empezar ya`,
      later: 'Hacerlo después',
      start: `Empezar`,
      done: `Hecho`,
      resultsSoon: `Has terminado el test. En breve nos pondremos en contacto contigo.`,
      question: `Pregunta`,
      submit: 'Siguiente',
      unknownError: `Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.`,
      notFound: `No se ha encontrado el test`,
      level: {
        starter: "Principiante",
        elementary: "Elemental",
        preIntermediate: "Pre-intermedio",
        intermediate: "Intermedio",
        upperIntermediate: "Intermedio alto",
        advanced: "Avanzado",
        notSure: "¿No estás seguro de cuál es tu nivel?",
        clickForTest: "Ponlo a prueba!"
      },
      levelResults: {
        yourLevelIs: "Estupendo! Tu nivel es",
        nextComeCourses: "Encuentra el curso más adecuado para tu nivel!",
        continue: "Continuar"
      },
    },
    midtrans: {
      success: {
        "result": "Éxito",
        "title": "¡Enhorabuena!",
        "detail": "Tu pago se ha completado con éxito, ¡gracias!",
        "done": "Salir"
      },
      failure: {
        "result": "Error",
        "title": "¡Lo sentimos!",
        "detail": "Tu pago no se ha completado. Inténtalo de nuevo, por favor.",
        "done": "OK"
      },
      incomplete: {
        "result": "Incompleto",
        "title": "Atención",
        "detail": "Tu pago no se ha completado",
        "done": "OK"
      },
    },
    levelTest: {
      intro: "Este es un test rápido para comprobar tu nivel de inglés actual. No lleva más de 10 minutos completarlo. El test se compone de 25 preguntas."
    },
    npsTest: {
      thanks: "Gracias por tus comentarios!",
      feedbackEssential: "Tu feedback es fundamental para que mejoremos nuestro servicio :-)",
      home: "Inicio"
    },
    courseSelection: {
      schedule: "Horario",
      teacherProfile: {
        education: "Formación",
        qualifications: "Cualificación",
        experience: "Experiencia",
        specialisations: "Especializaciones"
      }
    },
    cerahKids: {
      kidsImgAltText: "Niños aprendiendo inglés online",
      pageTitle: "Academia de inglés para niños",
      metaDescription: "Clases de inglés online con profesores extranjeros cualificados y con experiencia, que saben cómo hacer que una clase sea divertida, fácil y emocionante! Dales a tus niños las habilidades de inglés que necesitarán para el mundo globalizado del día de mañana",
      whatIstheAgeOfTheStudent: "¿Cuántos años tiene el alumno?",
      title: "CerahKids",
      startLearningEarly: "Empieza temprano a aprender inglés",
      content: "Dale a tus hijos la oportunidad de aprender inglés mediante conversaciones reales con profesores divertidos que saben cómo captar su atención, usando los personajes, las historias y los héroes que les gustan más a ellos, no pesados libros de gramática",
      tryItNow: "Prueba ahora",
      KidsFutureImgAltText: "Cursos de inglés para niños",
      didYouKnow: {
        sixtyCountries: "Sabías que el inglés se habla en más de 60 países globalmente?",
        broadenTheirHorizons: "Amplía los horizontes de tus hijos para el futuro con habilidades de inglés"
      },
      nineYearOldIndonesianStudents: "Alumnas indonesias de 9 años",
      emergencyContact: {
        form: {
          label: {
            studentAge: "Edad del estudiante",
            studentName: "Nombre del estudiante",
            name: "Nombre del progenitor",
            email: "Email del progenitor",
            phone: "Teléfono",
            whatsapp: "Número de WhatsApp",
            voucher: "Código de promoción (si tienes)",
            send: "Enviar"
          },
          placeholder: {
            studentAge: "Edad del alumno",
            studentName: "Nombre completo",
            name: "Nombre completo",
            phone: "XXXXXXXXXX",
            email: "nombre@ejemplo.com"
          },
          invalidPhoneFormat: "Por favor, incluye el prefijo del país (e.g., +34 para España)",
          numberNotOnWhatsapp: "Este número no parece ser un número de WhatsApp, revísalo por favor",
          invalidEmailFormat: "El formato de email no es válido, revísalo por favor",
        }
      },
      tryBeforeBuy: {
        title: "¿Interesante?",
        subTitle: "Prueba antes de pagar!",
        btnText: "Pruébalo ahora",
      }
    },
    cerahOnline: {
      studentImgAltText: "Clases de inglés online",
      pageTitle: "CerahOnline - Aprende inglés online conversando con profesores extranjeros",
      metaDescription: "Practica inglés online desde casa con profesores extranjeros y nativos. Cursos online prácticos, centrados en conversación real e interacción social real para aprender inglés de forma natural y fácilmente",
      howOldIsStudent: "Edad del estudiante",
      underage: "Menos de 16",
      adult: "16+",
      title: "Libera tu yo internacional",
      content: "Ya sea porque necesitas aprender inglés para conseguir el trabajo de tus sueños, para desarrollar negocios internacionalmente o para sacar la nota de IELTS para estudiar fuera, nuestros cursos de inglés con profesores particulares te llevarán a tu meta en el menor tiempo posible",
      courseTypes: {
        courseTypes: "Cursos online",
        courseTypesKids: "Cursos online para niños",
        privateClass: "Particular",
        privateClassSubtitle: "Un profesor de inglés experto todo para ti",
        privateCourses: "Clases particulares online",
        groupCourses: "Cursos online en grupo",
        perHour: " /h",
        groupClass: "En grupo",
        groupOfTwoSubtitle: "Aprende inglés con un compañero de conversación",
        groupOfThreeSubtitle: "Mejora tu inglés mediante discusiones en grupo",
        groupOfFourSubtitle: "Practica tu inglés en conversaciones animadas con gente de otros países",
        people: "pax",
        perMonth: " /mes",
        equivalentTo: "equivalente a",
        sixtyMinutesSessions: "60 minutos / sesión",
        twoPerWeek: "2x por semana",
      },
      wannaTry: "¿Interesante?",
      itsFree: "Pruébalo antes de pagar",
      tryItNow: "¡Pruébalo hoy!",
      chooseSchedule: "Elige tu horario",
      yourSchedule: "Tu horario",
      weekdays: "Entre semana",
      weekends: "Findes",
      morning: "mañanas",
      afternoon: "tardes",
      night: "noches",
      wantBut: "Me interesa, pero",
      dontKnowWhen: "aún no sé exactamente cuándo",
      group: "Grupo",
      individual: "Particulares",
      whatsapp: "WhatsApp / SMS",
      line: "Line / SMS",
      callback: "Teléfono",
      youWantGroupIndividual: "¿Quieres clases en grupo o particulares?",
      howYouWannaBeContacted: "¿Como te gustaría que nos pusiéramos en contacto contigo?",
      howManyMeetingsPerWeek: "¿Cuántas clases por semana?",
      meetingsPerWeek: "clases por semana",
      choice: {
        youveChosen: "Tu elección",
        months: "meses",
        perWeek: "a la semana",
        perMonth: "al mes",
        month: "mes",
        butFree: "Pero ¿puedo probar sin pagar?",
        freeTrialExplanation: "Probar es GRATIS! Paga sólo si la primera clase te gusta 😊",
        groupClass: "Clases en grupo",
        privateClass: "Clases particulares",
        confirm: "Confirmar"
      },
      scheduleSummary: {
        change: "Cambiar",
        agree: "Confirmar"
      },
      chooseTeacher: "Elige profesor",
      yourClass: "Tu elección",
      whenTrial: "¿Cuándo te gustaría tener tu clase de prueba?",
      later: "Más adelante",
      giveUsYourDetails: "Completa tus datos de contacto",
      ifEmergencyHowToContactYou: "Si hubiera un cambio de horario, prefieres que nos pongamos en contacto contigo por...",
      emergencyContact: {
        clickHere: "Pincha aquí",
        form: {
          label: {
            studentAge: "Edad del estudiante",
            studentName: "Nombre del estudiante",
            name: "Nombre",
            email: "e-mail",
            phone: "Teléfono",
            whatsapp: "Número de WhatsApp",
            voucher: "Código de promoción (si tienes)",
            send: "Enviar"
          },
          placeholder: {
            studentAge: "Edad del estudiante",
            studentName: "Nombre completo",
            name: "Nombre completo",
            phone: "XXXXXXXXXX",
            email: "nombre@ejemplo.com"
          },
          invalidPhoneFormat: "Por favor, incluye el prefijo del país (e.g., +34 para España)",
          numberNotOnWhatsapp: "Este número no parece ser un número de WhatsApp, revísalo por favor",
          invalidEmailFormat: "El formato de email es inválido, revísalo, por favor",
        }
      },
      noScheduleAvailableForTestCall: "Oh, parece que no hay horarios disponibles en esa fecha. Por favor, elige otro día",
      testCallChoice: "Para la llamada de prueba has elegido",
      clickOnWaButtonToConfirm: "Pulsa el botón de abajo para confirmar tu solicitud por whatsapp",
      preferToTalkThenClick: "¿Prefieres hablar con nosotros primero? Pincha",
      here: "aquí",
      toChatWithUs: "para chatear con nosotros por whatsapp",
      yourTeacherNeedsYourLevel: "Tu profesor necesita saber el nivel del alumno para preparar la clase",
      downloadTheApp: "Instala la app de Cerah para evaluar tu nivel de inglés",
      yourClassIsntConfirmedYet: "Tu clase de prueba aún NO está reservada",
      downloadTheAppToConfirm: "Instala la app de Cerah ahora para confirmar tu reserva",
      installForAndroid: "Instalar en Android",
      installForIPhone: "Instalar en iPhone",
      chooseYourLevel: "Escoge tu nivel",
      levels: {
        a1: "A1 - Principiante",
        a2: "A2 - Básico",
        b1: "B1 - Pre-intermedio",
        b2: "B2 - Intermedio",
        unknown: "La verdad es que no sé",
      },
      youCanTestYourLevelInTenMinutes: "Puedes evaluar tu nivel en 10 minutos",
      testTimes: {
        now: "Vale, quiero probar ahora",
        later: "Ahora no tengo tiempo"
      },
      wellSendYouAMsg: "Danos tu datos de contacto y te enviaremos un enlace para completar el test luego",
      testTakes10Minutes: "El test lleva 10 minutos, danos tu datos de contacto para que podamos contactar contigo en caso de interrupción",
      choosePreferredGroup: "Elige el grupo que prefieres",
      exploreGroups: "Mientras, puedes explorar los horarios de nuestros grupos y los profesores",
      course: {
        tutor: "Profesor",
        schedule: "Horario",
        seeTutor: "Ver perfil",
        chooseCourse: "Elegir éste",
        course: "Curso",
        needDifferentSchedule: "Ningún horario me viene bien",
        requestNewGroup: "Solicita un nuevo grupo",
        noGroupsAvailable: "No hay grupos disponibles para este nivel y franja de edad"
      },
    },
    learningMethods: {
      learnEnglishInPracticalWay: "Learn English in practical way.",
      bannerContent: "Cerah delivers English materials practically and effectively. Forget about the text-books and exercises that you got in formal learning center. We will teach English just like everyday language.",
      ourLearningMethods: "Our learning methods",
      methodTitle1: "Full practice session",
      methodContent1: "No theory, no grammar, that’s not how the brain works.\nPractice English just like your daily language",
      methodTitle2: "Fun learning",
      methodContent2: "Our class session will be fun, engaging and interactive.\nMeet our friendly teachers who will guide you to learn English",
      methodTitle3: "Learn English by doing what you love",
      methodContent3: "Cerah will give you homeworks based on your passion.\nForget about having to read boring material and textbook",
      ourMaterials: "Our Materials",
      cerahAlwaysGivesUpdatedMaterial: "Cerah always gives updated material",
      materialTailoredToStudent: "We use materials that is tailored to each student",
      turnEverthingIntoYourLearning: "Turn everything what you (or your kids) love into your learning materials.",
      yourPassionWillBecomeYourHomework: "Your K-Pop passion or Netflix binging habits will become your daily homeworks.",
      learningPlatform: "Learning platform",
      learningPlatformTitle: "We use skype as learning platform",
      learningPlatformText1: "No multiple call links needed",
      learningPlatformText2: "Only one-time signing is needed",
      learningPlatformText3: "Access all chat history and class videos through class channel",
      trackYourProgress: "Track your progress",
      EvaluationEveryThreeMonths: "We do evaulation on students' speaking skills once in 3 months",
      resultOfEvaulation: "The result of evaluation is shown in the Certificate of Participation"
    },
    testimonials: {
      bannerTitle: "Lo que dicen nuestros alumnos",
      bannerTestimonialText: "Son geniales, lo mejor son los profesores porque las clases son muy divertidas",
      bannerPersonName: "Manuel (30 años)",
      bannerPersonInfo: "Alumno español que vive en el Reino Unido",
      storiesFromOurStudents: "Historias de nuestros alumnos",
      followTheirSuccessStory: "Sigue sus pasos hacia el éxito",
      joinNow: "Apúntate ahora!",
      tryItFree: "Prueba gratis",
      countries: {
        ES: "España",
        ID: "Indonesia",
        MY: "Malasia",
        PA: "Panamá",
        US: "Estados Unidos",
      },
      reviews: {
        nova: "Antes no me atrevía ni a abrir la boca para hablar en inglés. Avergonzada y con miedo a equivocarme. Muchas veces me subestimaba. Ahora las cosas son muy diferentes y han dado un giro de 180º. Cuando hablo con extranjeros, entienden lo que digo. Ya no tengo miedo a equivocarme",
        manolo: "Cuando llegué a Inglaterra es que no podía ni pedir un café en una cafetería. Ahora mi inglés todavía no es perfecto, pero ya he podido conseguir un trabajo, en un hospital de la sanidad pública... y sé cómo pedir mi fish and chips en el restaurante :-)",
        jorge: "Es chévere aprender con Aicks. Siempre saca buenos temas de conversación y explica de maravilla",
        della: "Durante este mes que llevo dando clase lo he pasado genial aprendiendo con Teacher Tine, que siempre es muy paciente. El método de aprendizaje está guay también. Es agradable poder aprender y a la vez compartir experiencias y cultura. En conjunto, la clase es muy amena 👍",
        nadia: "Gracias a Dios porque con las clases de Cerah, la calidad de mi inglés hablado ha mejorado. Hasta he conseguido medallas de oro en varias olimpiadas de inglés. Gracias Cerah por ayudarme a mejorar la calidad de mi inglés hablado!",
        siti: "Mi experiencia durante las clases de inglés en Cerah fue muy satisfactoria. Aprendiendo directamente con un hablante nativo, la clase es divertida y la profesora, muy paciente",
        alvin: "Tuve clases con Cerah 4 meses más o menos... mi progreso fue bastante rápido porque la profesora era extranjera y no hablaba indonesio. Al principio tenía dificultades para describir o explicar algo, pero el método era muy divertido y las correcciones muy útiles. Gracias a eso, me vi más suelto en las entrevistas para conseguir una beca para estudiar fuera y conseguí una oferta de una universidad!",
        nurul: "Las clases son buenas. Disfruto de verdad estudiando con Lury y los otros alumnos. Es justo lo que esperaba. Ahora tengo más confianza para hablar en inglés. Puedo pensar directamente en inglés, de manera que puedo hablarlo deprisa y ya no necesito traducir del Malayo 😊",
        kaelaLeeka: "Tenemos 9 años, llevamos estudiando con Cerah casi 2 años. Las clases moooolan. Y aprendemos un montón de Cerah, gramática y vocabulario nuevo. El horario lo podemos elegir. Nuestra profe es muy simpática, siempre nos da actividades interesantes. Cuando hay una palabra que no entendemos, la profe nos la explica y la escribe en el chat para que no se nos olvide. Nuestros padres dicen que se nota que hemos mejorado en la gramática y sabemos más vocabulario",
      },
    },
    aboutUs: {
      bannerTitle: "Cerah helps you learn English in the most practical way",
      bannerText: "Cerah is an online English learning platform. We deliver the materials in new approach. Our students are spread all over the country, so you can practice speaking and listening English easily and effectively.",
      ourStudents: "Our students",
      weCoverStudentsAllAcrossTheWorld: "We cover students all across the world",
      meetOurTeachers: "Meet our teachers"
    },
    cerahTeacher: {
      bannerTitle: "Cerah Teacher",
      bannerSubtitle: "Change people's lives become an English tutor",
      bannerText: "We give opportunity for you who loves to teach and gives an impact to people. Enjoy the benefit of belonging to a group of excellent teachers, and get a stable source of income.",
      joinNow: "Join Now",
      teacherYourStudensOnline: "Teach your students online from home or face-to-face",
      chooseYourOwnSchedule: "Work as much or as little as you want, choose your own schedule",
      stableSourceOfIncome: "Get a stable source of income with great pay!",
      whatAreYourBenifits: "What are your benifits",
      benefitTitle1: "Just teach",
      benefitContent1: "We find the students for you. Forget about having to spend precious time posting ads and searching for students, and focus instead what you love doing: teaching",
      benefitTitle2: "Great pay",
      benefitContent2: "By letting only the best teachers join us, we can both provide great quality... and our teachers a great pay. Cerah only takes a small percentage as a fee",
      benefitTitle3: "We do the admin",
      benefitContent3: "No need to chase students or keep track of payments due. We take care of that on your behalf",
      benefitTitle4: "Never miss a class",
      benefitContent4: "Get reminders of all classes on your phone",
      benefitTitle5: "Guidance & support",
      benefitContent5: "Enjoy the benefits of belonging to a group of excellent teachers, learn from others and share experiences",
      benefitTitle6: "Access to materials",
      benefitContent6: "Get access to a wealth of materials to prepare your classes",
      teachersTestimonials: "Teacher's testimonials",
      beAPartOfCerah: "Be a part of Cerah",
      joinAsTeacher: "Join as teacher"
    },
    testCallScheduling: {
      selectTestCallSchedule: "Elige una hora para una llamada de prueba",
      takesJustFiveMinutes: "(la llamada lleva sólo 5 minutos)",
      testCallDescription: "Nos pondremos en contacto contigo para configurar tu audio / video de manera óptima antes de tu primera clase, para que puedas aprovechar el tiempo de clase al máximo :-)",
      missingParams: "No podemos mostrarte los horarios disponibles porque el enlace que recibiste está incompleto. Por favor, solicita uno nuevo de nuestros agentes.",
      reschedulingSuccessful: "Tu llamada de prueba se ha programado correctamente",
      thereWasAProblem: "Ha habido un error al programar tu llamada de prueba. Por favor, inténtalo de nuevo y, si el error persiste, pide un nuevo enlace a nuestros agentes."
    },
    day: {
      morning: "mañana",
      noon: "mediodía",
      afternoon: "tarde",
      evening: "noche"
    },
    teacherChoices: {
      chooseTheTeacher: "Elige el profesor",
      thatYouPrefer: "que prefieres.",
      theseTeachers: "Estos profesores están disponibles para enseñarte inglés",
      checkTheTeacher: "Revisa el perfil",
      andAccept: "y acepta si te gusta.",
      thisTeacher: "Este profesor está disponible para enseñarte inglés",
      submit: "Enviar",
      displayProfile: "Mostrar perfil",
      choose: "Elige profesor",
      accept: "Aceptar profesor",
      chosen: "Seleccionado",
      chooseThisOne: "Elegir éste",
    },
    renewals: {
      subscription: "Subscripción",
      course: "Curso",
      students: "Estudiantes",
      subject: "Materia",
      teacherName: "Nombre del profesor",
      meetingsRemaining: "Clases restantes",
      endsOnDate: "Termina el día",
      continueSubs: "Renovar la suscripción actual",
      addMeetings: "Comprar clases",
      changeSchedule: "Cambiar horario",
      selectSubsRenewal: "Elige el paquete de clases",
      howDoYouWannaPay: "¿Cómo prefieres pagar?",
      order: "Elijo éste",
      hoursOfMeetings: "horas de clase",
      save: "Te ahorras",
      monthly: "Un mes de clase",
      yourLatestPurchase: "Tu última compra",
      paymentDirections: {
        contactUs: "Contáctanos",
        andWellHelpYou: "y te ayudaremos durante el proceso de compra",
        toExtendYourSubscription: "Para renovar tu suscripción por",
        hours: "horas",
        pleaseTransfer: "por favor transfiere",
        toOurCompanyAccount: "a nuestra cuenta:",
        accountHolder: "Titular",
        address: "Dirección",
        idLikeToRenewSubscription: "Hola Cerah! Me gustaría renovar mi suscripción, éstos son los detalles:",
      },
      payUsingCard: "Con tarjeta",
      payUsingOtherMethod: "Banco / e-wallet / efectivo",
      requireEmail: "Introduce tu email",
      linkExpired: "Este enlace está caducado. Por favor, póngase en contacto con atención al cliente de Cerah para obtener un enlace nuevo."
    },
    courseTypes: {
      onlineGroup: "En grupo",
      onlinePrivate: "Particulares"
    },
    availableSubjects: {
      englishBarengA1: "Grupo: Gen. English - A1",
      englishBarengA2: "Grupo: Gen. English - A2",
      englishBarengB1: "Grupo: Gen. English - B1",
      englishBarengB2: "Grupo: Gen. English - B2",
      englishBarengIELTS: "Grupo: IELTS",
      englishConversation: "Conversación",
      englishGeneral: "General English",
      englishBusiness: "Business English",
      englishIELTS: "IELTS",
      englishTOEFL: "TOEFL",
      englishAPTIS: "APTIS",
      englishEssay: "Essay writing for applications",
      bahasaGeneral: "Indonesio",
      mandarinGeneral: "Mandarin",
      germanGeneral: "Alemán",
      eventIELTSsim: "Event - IELTS simulation",
      eventstudyAbroad: "Event - Advice to study abroad",
      eventSpeaking: "Event - Speaking activity",
    },
    certificateValidations: {
      thisConfirms: "Se ha validado la firma electrónica del certificado",
      student: "Estudiante: ",
      level: "Nivel obtenido: ",
      date: "Fecha: ",
      invalidCertificate: "La firma electrónica del certificado es INVÁLIDA",
    }
  },
  ms: {
    title: "Belajar Bahasa Inggeris Dengan Cepat | Kelas Pertuturan Online | Guru Asing",
    metaDescription: "Belajar Bahasa Inggeris secara online dan bersenang-senang dalam kursus kami! Hanya mendaftar untuk kelas berkumpulan atau dapatkan tutor persendirian untuk menyesuaikan program khusus untuk anda. Terdapat juga guru yang pakar dalam IELTS!",
    main: {
      changeYourLife: "Ubah hidup anda",
      speakEnglishWithinMonths: "Bertutur dengan Bahasa Inggeris dalam beberapa bulan",
      tryNow: "CUBA SEKARANG!",
      noPaymentNeeded: "Tidak perlu pembayaran.",
      homeImageAltText: "Belajar Bahasa Inggeris dengan harga yang berpatutan",
      learnEnglishAtComfort: "Belajar Bahasa Inggeris online dengan selesa di rumah anda bersama guru kami yang berpengalaman",
      ourClasses: "Program kami",
      contactUs: {
        contactUs: "Hubungi kami",
        imInterested: "Hai! Saya berminat dengan kelas Bahasa Inggeris anda, saya ingin mendapatkan lebih banyak maklumat",
      },
      readMore: "baca lebih lanjut...",
    },
    hero: {
      title: "Belajar Bahasa Inggeris dengan satu-satunya cara yang berkesan:",
      speakingPractice: "Berlatih bertutur ...",
      talkingToAHuman: "...bercakap dengan orang...",
      gettingFeedback: "...mendapatkan pembetulan dengan baik...",
      talkingAboutYourPassions: "...dan berborak mengenai minat ANDA",
    },
    ourMethod: {
      ourMethod: "Kaedah kami",
      learnMoreOurMethod: "Ketahui lebih lanjut",
      realConversation: "Perbualan sebenar bersama guru",
      studentsGetCorrections: "Pelajar mendapatkan pembetulan kesilapan dengan segera",
      passionsHomework: "Kerja rumah yang berkaitan dengan hobi dan minat pelajar",
      practiceUsingTheApp: "Berlatih di antara kelas dengan menggunakan aplikasi kami supaya anda tidak lupa dengan apa yang anda pelajari di kelas",
    },
    ourClasses: {
      cerahOnline: "CerahOnline",
      cerahOnlineText: "Bagi anda yang perlu meningkatkan Bahasa Inggeris anda untuk mempersiapkan pekerjaan, memajukan kerjaya anda, menjalani ujian IELTS, mendapatkan visa untuk tinggal di luar negara, atau hanya mahu berkawan dengan orang dari pelbagai negara.\n\
      Praktikkan penggunaan Bahasa Inggeris dengan segera, tanpa penjelasan teori yang membosankan. Kesalahan dalam bertutur Bahasa Inggeris akan diperbetulkan oleh guru pakar kami. Dapatkan bimbingan guru anda untuk mengatur rutin latihan di luar kelas. Dan bertambah baik dalam masa yang singkat!\n\
      Anda boleh belajar secara peribadi atau dengan rakan baru, anda dapat tentukan sendiri.",
      buttonText: "Bermula",
      cerahKids: "CerahKids",
      cerahKidsSubtitle: "Siapkan keperluan anak-anak anda dari kecil",
      cerahKidsText: "Sains menunjukkan bahawa kemampuan untuk belajar bahasa baru, mula menurun selepas remaja. Beri peluang kepada anak-anak anda untuk mula belajar Bahasa Inggeris lebih awal sehingga mereka menguasainya sebelum mereka dewasa.\n\
      Di Cerah mereka akan belajar dengan cara praktikal, menggunakan Bahasa Inggeris dari hari pertama, dengan guru yang menyeronokkan dan menarik, bahan belajar yang bersesuaian dengan minat dan hobi mereka, tanpa buku teks yang membosankan.",
    },
    whyCerah: {
      whyCerah: "Kenapa Cerah",
      aMethodThatWorks: "Kaedah yang berguna",
      realSpeakingPracticeNoTheory: "Tidak ada lagi teori yang membosankan. Otak kita hanya dapat mempelajari bahasa baru seperti kita belajar untuk menunggang basikal: dengan banyak berlatih, mendapatkan pembetulan... menikmati perjalanannya",
      learnMoreMethod: "Ketahui lebih lanjut mengenai kaedah kami",
      worldClassTeachers: "Guru bertaraf dunia di hujung jari anda",
      ourTeachersAreFun: "Belajar bahasa Inggeris dengan guru terbaik dunia, bukan sekadar di tempatan. Ucapkan selamat tinggal kepada guru yang membuat anda membenci bahasa Inggeris. Guru kami tahu bagaimana untuk membuat kelas anda lebih menyeronokkan",
      canTryBeforeYouBuy: "Anda boleh cuba dahulu sebelum membayar",
      nothingToLose: "Ikuti dahulu kelas kami dan hanya bayar jika anda menyukainya. Tidak rugi mencuba, dan kami yakin anda pasti tertarik untuk terus belajar dengan kami selepas pengalaman pertama anda!",
      veryAffordablePrices: "Harga yang sangat berpatutan",
      privatePricesBetterThanYourTown: "Skala global membolehkan kami untuk menawarkan harga kelas peribadi yang tidak dapat anda dapatkan di tempat tinggal anda... untuk guru berkaliber yang tidak dapat anda dapatkan di mana jua",
      learnWithTheApp: "Di luar kelas, anda boleh belajar dari aplikasi kami",
      vocabularyPracticeAndProgress: "Tanpa caj tambahan, anda akan mendapatkan jugak akses ke aplikasi kami. Berlatih perkataan Bahasa Inggeris yang anda pelajari semasa kelas anda di masa lapang, kekal ingat untuk sesi kelas akan datang. Anda juga mampu jejaki kemajuan anda.",
    },
    whatTheySayAboutUs: {
      whatTheySayAboutUs: "Apa yang mereka katakan mengenai kami",
      seeAllTestimonials: "Lihat semua testimoni"
    },
    signUpNow: {
      beOneOfThem: "Jadilah salah seorang dari mereka ",
      signUpNow: "Daftar sekarang",
      tryItFree: "Cuba secara percuma",
    },
    littleAboutUs: {
      title: "A little bit about us",
      aboutUsText: "We are a group of English private tutors that are fed up with how English has been taught more as maths problems than a language.\n\
      We believe strongly in learning by doing, getting immediate feedback, and practicing at all times. We encourage students to not see   English as a language that they are learning, but as one of the languages that they speak. The change of attitude and mindset will help boost   the confidence a lot and start speaking in English. Making mistakes is not a punishable event. It is the chance to learn from the mistakes   and improve. Not making mistakes means you are not doing enough. By not doing enough, you are not progressing enough.\n\
      So, join us in speaking English and improve it fast!",
      learnMore: "Learn more"
    },
    appShowcaseBanner: {
      practiceEnglishAnywhereYouAre: "Berlatih Bahasa Inggeris\n di mana sahaja anda berada",
      donwloadOurAppAndStartLearningNow: "Muat turun aplikasi kami dan mulakan pembelajaran sekarang!"
    },
    navbar: {
      home: "Utama",
      ourMethod: "Kaedah kami",
      coursesForAdults: "Kelas orang dewasa",
      coursesForKids: "Kelas kanak-kanak",
      becomeATeacher: "Menjadi guru",
      aboutUs: "Tentang kami"
    },
    footer: {
      conquerTheWorld: "Takluki dunia",
      ourServices: "Perkhidmatan kami",
      testimonials: "Testimoni",
      company: "Syarikat",
      becomeTeacher: "Menjadi guru",
      findUsOn: "Cari kami di",
      allRightsReserved: "Hak cipta terpelihara"
    },
    copyright: {
      privacyPolicy: "Dasar privasi",
      termsAndConditions: "Terma & syarat",
      contactUs: "Hubungi kami",
    },
    teacher: {
      title: "Work As An Online English Teacher",
      metaDescription: "",
      slogan1: "Change people's lives",
      slogan2: "become an English tutor",
      startToday: "Start today",
      joinUs: "Join us!",
      mainMessage: {
        joinExclusive: "Join the exclusive club of the best private English tutors in Indonesia",
        atHome: "Teach your students online from home or face-to-face",
        workAtLeisure: "Work as much or as little as you want, and choose your own schedule",
        andMakeMoney: "Get a stable source of income with great pay!"
      },
      appShowcase: {
        howIt: "how it",
        works: "works",
        receiveNotifications: "Receive notifications of requests for courses directly on your phone",
        checkDetails: "Check details of the course request on the spot",
        acceptClass: "Accept the course if you like it. Courses last months or years",
        prepareClass: "Once the student confirms, prepare your classes and start working!",
        address: "Address",
        cemaraAsri: "Komp. Prambanan, Jalan Kembangan 72, Jakarta",
        fee: "Fee",
        seventySevenK: "115.000 / hour",
        classType: "class type",
        convo: "Conversation",
        schedule: "schedule",
        tue9am: `Tuesday | 09.00 - 10.30`,
        fri6pm: `Friday | 18.30 - 20.00`,
        level: "level",
        intermediate: "intermediate",
        groupSize: "group size",
        fourPeople: "4 people",
        studentsAge: "student age",
        doYouAccept: "do you want to teach this course?",
        cerah: "Cerah",
        classRequest: "Course request",
        studentWantsClasses: "A student is requesting classes in Pondok Indah area",
        seeDetails: "See details",
        congratulations: "Congratulations!",
        reminder: "Reminder",
        studentHasAccepted: "The student has agreed to start classes with you",
        yourClassAt: "Your first teaching day will be",
        onTuesday: "on Tuesday 6 March 2018 at 9am"
      },
      testimonials: {
        teacher: "teacher",
        testimonials: "testimonials",
        melisTestimonial: `The most helpful feature is that teachers are continuously provided with students. 
          This way, I don't need to spam on my social media or something just to inform others 
          that I'm available if someone needs an English teacher`,
        ilhamsTestimonial: `I was impressed by how they select and filter the teachers so that only 
          the most qualified ones can teach, to guarantee the effectiveness. Teaching is 
          a very professional occupation that cannot be taken for granted. And they pay their teachers 
          accordingly. I'm happy with the numbers I earn`,
        cuncunsTestimonial: `Nowadays, we order food, buy clothes, etc., all via internet through 
          the flick of our fingers. Booking private tutors online can be a 
          breakthrough in education. Students can have their own private tutors at places they wish, 
          and for us teachers, we get more students to teach. It's a great idea`,
        camelia: "Melly",
        ilham: "Ilham",
        cuncun: "Cun Cun"
      },
      benefits: {
        benefits: "benefits",
        your: "your",
        peaceOfMind: "just teach",
        payRates: "great pay",
        forgetAdmin: "we do the admin",
        neverMissClass: "never miss a class",
        support: "guidance & support",
        materials: "access to materials",
        noMarketing: "We find the students for you. Forget about having to spend precious time posting ads and searching for students, and focus instead what you love doing: teaching",
        onlyBestTeachers: "By letting only the best teachers join us, we can both provide great quality... and our teachers a great pay. Cerah only takes a small percentage as a fee",
        noChasingForPayments: "No need to chase students or keep track of payments due. We take care of that on your behalf",
        reminders: "Get reminders of all classes on your phone",
        networking: "Enjoy the benefits of belonging to a group of excellent teachers, learn from others and share experiences",
        wealthOfMaterials: "Get access to a wealth of materials to prepare your classes"
      }
    },
    enquiry: {
      teacher: {
        fillForm: "Buat akaun anda",
        intro: "Masukkan butiran maklumat peribadi anda sebagai langkah pertama untuk menyertai Cerah sebagai guru",
        form: {
          label: {
            name: "Masukkan nama anda di bawah",
            phone: "Nombor WhatsApp atau nombor telefon (sertakan kod negara!)",
            email: "E-mel (kami akan menghubungi anda di sini sekiranya telefon gagal)",
            send: "Hantar"
          },
          placeholder: {
            name: "Nama",
            phone: "xxxxxxxxxx",
            email: "e.g contoh@contoh.com",
          },
          errors: {
            invalidPhoneFormat: "Sila sertakan nombor awalan negara (e.g., +60 untuk Malaysia)",
            invalidEmailFormat: "Format e-mel tidak sah, sila semak semula",
          }
        },
      },
    },
    thankYou: {
      thankYou: "Terima kasih! Kami akan menghubungi anda sebentar lagi :-)",
      back: "Balik"
    },
    quiz: {
      now: `Mulakan sekarang`,
      later: 'Lakukan nanti',
      start: `Mulakan`,
      done: `Selesai`,
      resultsSoon: `Anda telah menyelesaikan ujian sekarang. Kami akan menghubungi anda secepatnya.`,
      question: `Soalan`,
      submit: 'Seterusnya',
      unknownError: `Ada kesalah berlaku. Sila cuba lagi kemudian`,
      notFound: `Ujian tidak di jumpai`,
      level: {
        starter: "Permulaan",
        elementary: "Dasar",
        preIntermediate: "Pra-menengah",
        intermediate: "Pertengahan",
        upperIntermediate: "Pertengahan atas",
        advanced: "Maju",
        notSure: "Tidak pasti anda berada di tahap yang mana?",
        clickForTest: "Sila uji diri anda sendiri!"
      },
      levelResults: {
        yourLevelIs: "Tahniah! Ternyata tahap anda adalah",
        nextComeCourses: "Cari kursus yang paling sesuai untuk tahap anda!",
        continue: "Teruskan"
      },
    },
    midtrans: {
      success: {
        "result": "Berjaya",
        "title": "Tahniah!",
        "detail": "Pembayaran anda telah berjaya, terima kasih!",
        "done": "Selesai"
      },
      failure: {
        "result": "Gagal",
        "title": "Maaf!",
        "detail": "Pembayaran anda gagal. Sila cuba kembali.",
        "done": "Baiklah"
      },
      incomplete: {
        "result": "Tidak lengkap",
        "title": "Awas",
        "detail": "Pembayaran anda tidak selesai.",
        "done": "OK"
      },
    },
    levelTest: {
      intro: "Ini adalah ujian untuk mengetahui tahap Bahasa Inggeris anda sekarang. Ujian ini akan memakan masa kurang dari 10 minit untuk di selesaikan. Ujian ini merangkumi 25 soalan"
    },
    npsTest: {
      thanks: "Terima kasih atas input anda!",
      feedbackEssential: "Maklum balas anda penting untuk kami meningkatkan perkhidmatan lagi.",
      home: "Halaman utama"
    },
    courseSelection: {
      schedule: "Jadual",
      teacherProfile: {
        education: "Pendidikan",
        qualifications: "Kelayakan",
        experience: "Pengalaman",
        specialisations: "Kepakaran"
      }
    },
    cerahKids: {
      kidsImgAltText: "Kursus Bahasa Inggeris online untuk kanak-kanak",
      pageTitle: "Kelas Bahasa Inggeris Untuk Kanak-kanak",
      metaDescription: "Kelas Bahasa Inggeris online dengan guru asing yang berpengalaman, berkelayakan, mengetahui cara untuk menjadikan kelas lebih mudah, menyeronokkan dan menarik! Beri anak-anak anda kemahiran bahasa yang mereka perlukan untuk dunia global di masa depan",
      whatIstheAgeOfTheStudent: "Berapakah umur pelajar?",
      title: "CerahKids",
      startLearningEarly: "Mula belajar bahasa Inggeris lebih awal",
      content: "Biarkan anak-anak anda belajar Bahasa Inggeris dengan mendapatkan perbualan sebenar bersama guru asing yang menyeronokkan dan tahu bagaimana untuk menjaga perhatian mereka, menggunakan pahlawan, watak, dan cerita yang mereka minati, bukan dengan buku tatabahasa yang membosankan",
      tryItNow: "Ikut sesi percuma",
      KidsFutureImgAltText: "Kursus Bahasa Inggeris untuk kanak-kanak",
      didYouKnow: {
        sixtyCountries: "Apakah anda tahu bahawa Bahasa Inggeris dituturkan secara global lebih daripada 60 negara?",
        broadenTheirHorizons: "Luaskan masa depan anak-anak anda dengan kemahiran Berbahasa Inggeris"
      },
      nineYearOldIndonesianStudents: "Murid Indonesia berumur 9 tahun",
      emergencyContact: {
        form: {
          label: {
            studentAge: "Umur pelajar",
            studentName: "Nama pelajar",
            name: "Nama ibu atau bapa",
            email: "E-mel ibu atau bapa",
            phone: "Telefon",
            whatsapp: "Nombor WhatsApp",
            voucher: "Kod promo (Kalau ada)",
            send: "Kirim"
          },
          placeholder: {
            studentAge: "Umur pelajar",
            studentName: "Nama penuh",
            name: "Nama penuh",
            phone: "XXXXXXXX",
            email: "nama@contoh.com"
          },
          invalidPhoneFormat: "Sila sertakan nombor awalan negara (e.g., +60 untuk Malaysia)",
          numberNotOnWhatsapp: "Nombor ini tidak didaftarkan di WhatsApp, sila semak",
          invalidEmailFormat: "Format e-mel tidak sah, sila semak semula",
        }
      },
      tryBeforeBuy: {
        title: "Menarik kan?",
        subTitle: "Cuba percuma!",
        btnText: "Cuba sekarang",
      }
    },
    cerahOnline: {
      studentImgAltText: "Belajar Bahasa Inggeris online",
      pageTitle: "CerahOnline - Belajar Bahasa Inggeris online dengan guru asing",
      metaDescription: "Berlatih Bahasa Inggeris online dari mana sahaja dengan guru asing. Kursus praktikal online, memberi tumpuan pada pertuturan dan interaksi sosial untuk pembelajaran Bahasa Inggeris secara semula jadi dan mudah",
      howOldIsStudent: "Berapa umur pelajar?",
      underage: "Di bawah 16",
      adult: "16+",
      title: "Melangkau ke pentas antarabangsa segera!",
      content: "Sama ada anda ingin menguasai Bahasa Inggeris untuk pekerjaan impian anda, bisnes di peringkat antarabangsa, ataupun skor IELTS yang cemerlang untuk belajar di luar negara, guru persendirian kami adalah jaminan untuk anda mencapai semua impian anda dengan pantas.",
      benefits: "Faedah",
      courseTypes: {
        courseTypes: "Jenis kursus Bahasa Inggeris online",
        courseTypesKids: "Jenis kursus bahasa Inggeris untuk kanak-kanak",
        privateClass: "Peribadi",
        privateClassSubtitle: "Mendapatkan guru Bahasa Inggeris yang mahir untuk diri sendiri",
        privateCourses: "Kursus peribadi online",
        groupCourses: "Kursus kumpulan online",
        perHour: " /j",
        groupClass: "Kumpulan",
        groupOfTwoSubtitle: "Belajar Bahasa Inggeris dengan rakan sebaya",
        groupOfThreeSubtitle: "Berlatih Bahasa Inggeris dalam perbincangan kumpulan",
        groupOfFourSubtitle: "Berlatih Bahasa Inggeris dengan perbualan yang meriah bersama kawan dari pelbagai negara",
        people: "orang",
        perMonth: " /bulan",
        equivalentTo: "bersamaan dengan",
        sixtyMinutesSessions: "60 minit / sesi",
        twoPerWeek: "2x per week",
      },
      wannaTry: "Berminat?",
      itsFree: "Cuba secara percuma",
      tryItNow: "Ikuti sesi percuma",
      chooseSchedule: "Pilih jadual anda",
      yourSchedule: "Jadual anda",
      weekdays: "Hari kerja",
      weekends: "Hujung minggu",
      morning: "pagi",
      afternoon: "petang",
      night: "malam",
      wantBut: "Saya nak, tapi",
      dontKnowWhen: "belum tahu bila",
      group: "Kumpulan",
      individual: "Persendirian",
      whatsapp: "WhatsApp / SMS",
      line: "Line / SMS",
      callback: "Panggilan telefon",
      youWantGroupIndividual: "Anda mahu kelas berkumpulan atau kelas persendirian?",
      howYouWannaBeContacted: "Bagaimana anda mahu dihubungi?",
      howManyMeetingsPerWeek: "Berapa kelas dalam seminggu?",
      meetingsPerWeek: "kelas dalam seminggu",
      choice: {
        youveChosen: "Anda telah memilih",
        months: "bulan",
        perWeek: "setiap minggu",
        perMonth: "setiap bulan",
        month: "bulan",
        butFree: "Tapi, bolehkah saya cuba dulu sebelum saya bayar?",
        freeTrialExplanation: "Cuba secara PERCUMA! Pembayaran boleh dibuat sekiranya anda tertarik dengan kelas kami 😊",
        groupClass: "Kelas berkumpulan",
        privateClass: "Kelas persendirian",
        confirm: "Setuju"
      },
      scheduleSummary: {
        change: "Ubah",
        agree: "Setuju"
      },
      chooseTeacher: "Pilih guru anda",
      yourClass: "Anda telah memilih",
      whenTrial: "Bilakah anda mahu memulakan kelas percubaannya?",
      later: "Kemudian",
      giveUsYourDetails: "Sila isi butiran maklumat anda",
      ifEmergencyHowToContactYou: "Sekiranya terdapat perubahan jadual, anda lebih suka dihubungi melalui...",
      emergencyContact: {
        clickHere: "Klik di sini",
        form: {
          label: {
            studentAge: "Umur Pelajar",
            studentName: "Nama Pelajar",
            name: "Nama",
            email: "E-mel",
            phone: "Telefon",
            whatsapp: "Nombor WhatsApp",
            voucher: "Kod promo (jika ada)",
            send: "Hantar"
          },
          placeholder: {
            studentAge: "Umur pelajar",
            studentName: "Nama penuh",
            name: "Nama penuh",
            phone: "XXXXXXXX",
            email: "nama@contoh.com"
          },
          invalidPhoneFormat: "Sila sertakan nombor awalan negara (e.g., +60 untuk Malaysia)",
          numberNotOnWhatsapp: "Nombor ini tidak didaftarkan di WhatsAppp, sila semak",
          invalidEmailFormat: "Format e-mel tidak sah, sila semak semula",
        }
      },
      selectTestCallSchedule: "Fleksibilti dalam pemilihan masa panggilan",
      takesJustFiveMinutes: "(panggilan hanya mengambil masa 5 minit)",
      testCallDescription: "Kami akan menghubungi anda untuk mengkonfigurasi audio/ video anda dengan optimum sebelum kelas percubaan bermula.",
      noScheduleAvailableForTestCall: "Maaf, tidak ada jadual yang tersedia pada tarikh ini. Sila pilih yang lain.",
      testCallChoice: "Anda telah memilih",
      clickOnWaButtonToConfirm: "Klik butang di bawah untuk mengesahkan permintaan anda melalui aplikasi WhatsApp",
      preferToTalkThenClick: "Adakah anda mahu menghubungi kami terlebih dahulu? Klik",
      here: "di sini",
      toChatWithUs: "untuk menghubungi kami melalui aplikasi WhatsApp",
      yourTeacherNeedsYourLevel: "Guru kami perlu mengetahui tahap pelajar untuk menyiapkan kelasnya.",
      downloadTheApp: "Muat turun aplikasi Cerah sekarang untuk menguji Bahasa Inggeris anda:",
      yourClassIsntConfirmedYet: "Kelas percubaan anda BELUM ditempah",
      downloadTheAppToConfirm: "Muat turun aplikasi Cerah sekarang untuk mengesahkan tempahan anda",
      installForAndroid: "Muat turun untuk Android",
      installForIPhone: "Muat turun untuk iPhone",
      chooseYourLevel: "Pilih tahap anda",
      levels: {
        a1: "A1 - Permulaan",
        a2: "A2 - Dasar",
        b1: "B1 - Pra-menengah",
        b2: "B2 - Pertengahan",
        unknown: "Saya tidak tahu",
      },
      youCanTestYourLevelInTenMinutes: "Uji tahap Bahasa Inggeris anda dalam 10 minit",
      testTimes: {
        now: "OK, saya mahu mencuba sekarang",
        later: "Saya tidak ada waktu sekarang"
      },
      wellSendYouAMsg: "Berikan kami butiran maklumat anda dan kami akan menghantar pautan untuk memulakan ujiannya nanti",
      testTakes10Minutes: "Ujian ini akan mengambil masa 10 minit, berikan butiran maklumat anda kepada kami agar kami dapat menghubungi anda sekiranya berlaku gangguan",
      choosePreferredGroup: "Pilih kumpulan yang anda mahukan",
      exploreGroups: "Sementara itu, periksa jadual dan guru kumpulan kami",
      course: {
        tutor: "Guru",
        schedule: "Jadual",
        seeTutor: "Lihat tutor",
        chooseCourse: "Pilih",
        course: "Kursus",
        needDifferentSchedule: "Tiada jadual yang sesuai dengan saya",
        requestNewGroup: "Minta kumpulan baru",
        noGroupsAvailable: "Tidak ada kumpulan yang tersedia untuk tahap dan julat usia ini"
      },
    },
    learningMethods: {
      learnEnglishInPracticalWay: "Learn English in practical way.",
      bannerContent: "Cerah delivers English materials practically and effectively. Forget about the text-books and exercises that you got in formal learning center. We will teach English just like everyday language.",
      ourLearningMethods: "Our learning methods",
      methodTitle1: "Full practice session",
      methodContent1: "No theory, no grammar, that’s not how the brain works.\nPractice English just like your daily language",
      methodTitle2: "Fun learning",
      methodContent2: "Our class session will be fun, engaging and interactive.\nMeet our friendly teachers who will guide you to learn English",
      methodTitle3: "Learn English by doing what you love",
      methodContent3: "Cerah will give you homeworks based on your passion.\nForget about having to read boring material and textbook",
      ourMaterials: "Our Materials",
      cerahAlwaysGivesUpdatedMaterial: "Cerah always gives updated material",
      materialTailoredToStudent: "We use materials that is tailored to each student",
      turnEverthingIntoYourLearning: "Turn everything what you (or your kids) love into your learning materials.",
      yourPassionWillBecomeYourHomework: "Your K-Pop passion or Netflix binging habits will become your daily homeworks.",
      learningPlatform: "Learning platform",
      learningPlatformTitle: "We use skype as learning platform",
      learningPlatformText1: "No multiple call links needed",
      learningPlatformText2: "Only one-time signing is needed",
      learningPlatformText3: "Access all chat history and class videos through class channel",
      trackYourProgress: "Track your progress",
      EvaluationEveryThreeMonths: "We do evaulation on students' speaking skills once in 3 months",
      resultOfEvaulation: "The result of evaluation is shown in the Certificate of Participation"
    },
    testimonials: {
      bannerTitle: "Apa yang mereka cakap tentang Cerah",
      bannerTestimonialText: "Mereka sangat luar biasa, yang paling best adalah gurunya dan kelas mereka sangat menyeronokkan",
      bannerPersonName: "Manuel",
      bannerPersonInfo: "30-tahun. Pelajar Sepanyol yang tinggal di UK",
      storiesFromOurStudents: "Cerita dari pelajar kami",
      followTheirSuccessStory: "Ikuti perjalanan mereka yang berjaya",
      joinNow: "Join sekarang!",
      tryItFree: "Cuba secara percuma",
      countries: {
        ES: "Spanyol",
        ID: "Indonesia",
        MY: "Malaysia",
        PA: "Panama"
      },
      reviews: {
        nova: "Aku dulu sama sekali tidak berani pakai Bahasa Inggris. Malu. Takut salah. Dulu sering underestimate diri sendiri. Sekarang keadaan beda banget. 180 derajat. Ketika aku mulai berkomunikasi dengan orang asing, mereka mengerti apa yang aku bilang. Jangan takut salah",
        manolo: "Semasa saya tiba di UK, saya tak dapat memesan kopi di kedai kopi. Walaupun sekarang Bahasa Inggeris saya masih bukan lah yang terbaik, tetapi saya telah mendapatkan pekerjaan di hospital NHS ... dan saya juga dah boleh memesan fish and chips  :-)",
        jorge: "Ini adalah pembelajaran yang terbaik dengan Miss Aicks. Dia selalu mempunyai topik yang baik dan menerangkannya dengan sempurna",
        della: "Selama sebulan ini, seneng banget belajar dengan Teacher Tine yang selalu sabar. Metode pembelajarannya juga asik. Seneng rasanya bisa belajar sekaligus sharing pengalaman dan budaya masing2. Overall, setiap kelasnya menyenangkan sekali👍",
        nadia: "Alhamdulillah karena les di cerah kualitas speaking English aku meningkat. aku juga bisa mendapatkan medali emas di beberapa olimpiade bidang bahasa inggris, terimakasih buat cerah karena udah mau bantu aku dalam meningkatkan kualitas speaking English aku yaa",
        siti: "Pengalamanku selama les  bahasa Inggris di Cerah, sangat puas.  Belajar langsung dengan native speaker, kelasnya fun dan gurunya sabar. Apalagi sekarang ada sesi listening. Sangat diperhatikan pronunciation saat berbicara, sehingga siswa tahu pelafalan yang benar.",
        alvin: "Saya mengikuti kursus di Cerah selama 4 bulan, fokus dalam meningkatkan kemampuan komunikasi bahasa Inggris. Kemajuan saya rasakan cukup pesat karena tutor tidak bisa berbicara Bahasa Indonesia. Awalnya saya kesulitan saat mendeskripsikan dan menjelaskan sesuatu, namun metode yg diterapkan selama dinamika di kelas sangat menyenangkan dan masukan yg diberikan sangat membantu. Oleh karena itu, saya merasa lebih lancar saat interview beasiswa luar negeri dan berhasil mendapatkan LoA!",
        nurul: "Kelasnya bagus. Saya suka sangat belajar dengan Miss Lury bersama dengan rakan-rakan yang lain dalam group. Saya sekarang dah boleh bercakap dengan baik dalam bahasa Inggeris. Dah boleh juga berfikir dalam bahasa Inggeris dengan pantas dan tak perlu nak fikir lagi dalam bahasa Malaysia dulu sebelum bercakap dalam Bahasa Inggeris 😊",
        kaelaLeeka: "Kami berumur 9 tahun. Kami telah belajar dengan Cerah selama hampir 2 tahun. Kelasnya sangat lah menyeronokkan. Kami sudah belajar banyak dari kelas Cerah seperti tatabahasa dan perbendaharaan kata baru. Kita jugak boleh membuat jadual kita sendiri. Gurunya sangat baik, mereka selalu memberikan kita topik menarik. Bila kita mempunyai perkataan yang kita tak faham, guru akan menerangkannya kepada kita dan menulis nya semula supaya kita dapat mengingatnya. Sekarang kita semakin baik dalam tatabahasa kami dan kami tahu lebih banyak perkataan baru dalam Bahasa Inggeris",
      },
    },
    aboutUs: {
      bannerTitle: "Cerah helps you learn English in the most practical way",
      bannerText: "Cerah is an online English learning platform. We deliver the materials in new approach. Our students are spread all over the country, so you can practice speaking and listening English easily and effectively.",
      ourStudents: "Our students",
      weCoverStudentsAllAcrossTheWorld: "We cover students all across the world",
      meetOurTeachers: "Meet our teachers"
    },
    cerahTeacher: {
      bannerTitle: "Cerah Teacher",
      bannerSubtitle: "Change people's lives become an English tutor",
      bannerText: "We give opportunity for you who loves to teach and gives an impact to people. Enjoy the benefit of belonging to a group of excellent teachers, and get a stable source of income.",
      joinNow: "Join Now",
      teacherYourStudensOnline: "Teach your students online from home or face-to-face",
      chooseYourOwnSchedule: "Work as much or as little as you want, choose your own schedule",
      stableSourceOfIncome: "Get a stable source of income with great pay!",
      whatAreYourBenifits: "What are your benifits",
      benefitTitle1: "Just teach",
      benefitContent1: "We find the students for you. Forget about having to spend precious time posting ads and searching for students, and focus instead what you love doing: teaching",
      benefitTitle2: "Great pay",
      benefitContent2: "By letting only the best teachers join us, we can both provide great quality... and our teachers a great pay. Cerah only takes a small percentage as a fee",
      benefitTitle3: "We do the admin",
      benefitContent3: "No need to chase students or keep track of payments due. We take care of that on your behalf",
      benefitTitle4: "Never miss a class",
      benefitContent4: "Get reminders of all classes on your phone",
      benefitTitle5: "Guidance & support",
      benefitContent5: "Enjoy the benefits of belonging to a group of excellent teachers, learn from others and share experiences",
      benefitTitle6: "Access to materials",
      benefitContent6: "Get access to a wealth of materials to prepare your classes",
      teachersTestimonials: "Teacher's testimonials",
      beAPartOfCerah: "Be a part of Cerah",
      joinAsTeacher: "Join as teacher"
    },
    testCallScheduling: {
      selectTestCallSchedule: "Fleksibilti dalam pemilihan masa panggilan",
      takesJustFiveMinutes: "(panggilan hanya mengambil masa 5 minit)",
      testCallDescription: "Kami akan menghubungi anda untuk mengkonfigurasi audio/ video anda dengan optimum sebelum kelas percubaan bermula.",
      missingParams: "Kami tidak dapat menunjukkan jadual yang tersedia kerana pautan yang anda terima tidak lengkap. Mohon permintaan baru dari admin kami",
      reschedulingSuccessful: "Sesi panggilan anda kini berjaya dijadualkan semula",
      thereWasAProblem: "Terdapat kesilapan semasa menjadualkan sesi panggilan anda. Sila cuba lagi, dan jika berterusan, minta pautan baru dari admin kami"
    },
    day: {
      morning: "pagi",
      noon: "tengah hari",
      afternoon: "petang",
      evening: "malam"
    },
    teacherChoices: {
      chooseTheTeacher: "Pilih guru",
      thatYouPrefer: "yang kamu suka.",
      theseTeachers: "Guru-guru ini bersedia untuk mengajar anda",
      checkTheTeacher: "Semak profil ini",
      andAccept: "dan terima jika anda suka.",
      thisTeacher: "Guru ini bersedia untuk mengajar anda",
      submit: "Hantar",
      displayProfile: "Tunjukkan profil",
      choose: "Pilih guru",
      accept: "Terima guru",
      chosen: "Dipilih",
      chooseThisOne: "Pilih",
    },
    renewals: {
      subscription: "Langganan",
      course: "Kursus",
      students: "Pelajar",
      subject: "Subjek",
      teacherName: "Nama guru",
      meetingsRemaining: "Perjumpaan yang masih ada",
      endsOnDate: "Berakhir pada tarikh",
      continueSubs: "Lanjutkan langganan semasa",
      addMeetings: "Beli pakej jelas",
      changeSchedule: "Ganti jadual",
      selectSubsRenewal: "Pilih pakej langganan",
      howDoYouWannaPay: "Bayar dengan...",
      order: "Pilih",
      hoursOfMeetings: "jam kelas",
      save: "Simpan",
      monthly: "Kelas satu bulan",
      yourLatestPurchase: "Pembelian terbaru anda",
      paymentDirections: {
        contactUs: "Hubungi kami",
        andWellHelpYou: "dan kami akan membantu anda dengan proses pembayaran anda",
        toExtendYourSubscription: "Untuk melanjutkan langganan anda",
        hours: "jam",
        pleaseTransfer: "Sila transfer",
        toOurCompanyAccount: "ke akaun syarikat kami:",
        accountHolder: "Pemegang akaun",
        address: "Alamat",
        idLikeToRenewSubscription: "Hi Cerah! Saya ingin memperbaharui langganan saya. Ini adalah butirannya:",
      },
      payUsingCard: "kartu",
      payUsingOtherMethod: "bank / e-dompet / tunai",
      requireEmail: "Masukkan emel anda",
      linkExpired: "Maaf, pautan ini telah tamat tempoh. Sila hubungi perkhidmatan pelanggan untuk mendapatkan pautan baharu",
    },
    courseTypes: {
      onlineGroup: "Berkumpulan Online",
      onlinePrivate: "Persendirian Online"
    },
    availableSubjects: {
      englishBarengA1: "Kumpulan: Umum. Bahasa Inggeris - A1",
      englishBarengA2: "Kumpulan: Umum. Bahasa Inggeris - A2",
      englishBarengB1: "Kumpulan: Umum. Bahasa Inggeris - B1",
      englishBarengB2: "Kumpulan: Umum. Bahasa Inggeris - B2",
      englishBarengIELTS: "Kumpulan: IELTS",
      englishConversation: "Perbualan",
      englishGeneral: "Bahasa Inggeris Umum",
      englishBusiness: "Bahasa Inggeris Peniaga",
      englishIELTS: "Persiapan IELTS",
      englishTOEFL: "Persiapan TOEFL",
      englishAPTIS: "Persiapan APTIS",
      englishEssay: "Penulisan esei untuk permohonan",
      bahasaGeneral: "Indonesian",
      mandarinGeneral: "Mandarin",
      germanGeneral: "German",
      eventIELTSsim: "Event - IELTS simulasi",
      eventstudyAbroad: "Event - Nasihat untuk belajar ke luar negara",
      eventSpeaking: "Event - Aktiviti bertutur",
    },
    certificateValidations: {
      thisConfirms: "Tandatangan elektronik sijil adalah sah",
      student: "Pelajar: ",
      level: "Tahap dicapai: ",
      date: "Tarikh: ",
      invalidCertificate: "Tandatangan elektronik sijil adalah TIDAK SAH",
    }
  },


/// TODO: change the indonesian and the malaysian keyword
};

let activeLangIsoCode;
let activeLang;
const listeners = [];

export class Language {
  static get active() {
    return activeLang;
  }
  
  static get activeLangIsoCode() {
    return activeLangIsoCode;
  }
  
  static switchActiveTranslation(langIsoCode) {
    userSession.setPreferredLanguage(langIsoCode);
    activeLangIsoCode = langIsoCode;
    activeLang = translations[langIsoCode];
    listeners.forEach(l => l());
  }
  
  static onChange(f) {
    if (typeof f === "function") {
      listeners.push(f);
    } else {
      console.log("passed non-function to Language.onChange");
    }
  }
}

let defaultLang = "en";
if (isBrowser) {
  if (window.navigator.language.startsWith("id")) {
    defaultLang = "id";
  } else if (window.navigator.language.startsWith("es")) {
    defaultLang = "es";
  } else if (window.navigator.language.startsWith("ms")) {
    defaultLang = "ms";
  } else {
    defaultLang = "en";
  }
}
Language.switchActiveTranslation(userSession.getPreferredLanguage() || defaultLang);
