import React from 'react'
import {Language} from "../../i18n/language";
import * as wizardStyle from "../../cerahOnline/cerahOnline.module.scss";
import {userSession} from '../../userSession/userSession';
import * as style from "./downloadTheApp.module.scss";
import analytics from "../../analytics/analytics";
import {Button} from "../../controls/button";

export function DownloadTheApp({title, subtitle, onConfirm}) {
  const strings = Language.active.cerahOnline;
  return (
    <div className={wizardStyle.choiceSection}>
      <div className={wizardStyle.choiceTitle}>{title || strings.yourTeacherNeedsYourLevel}</div>
      <div className={style.subtitle}>{subtitle || strings.downloadTheApp}</div>
      <div className={style.downloadSection}>
        <PlayStoreBadge/>
        <AppStoreBadge/>
      </div>
      <div className={style.textContent}>
        {strings.preferToTalkThenClick} <span className={style.link} onClick={onConfirm}>{strings.here}</span> {strings.toChatWithUs}
      </div>
    </div>
  );
}

function PlayStoreBadge() {
  const strings = Language.active.cerahOnline;
  return (
    <Button text={strings.installForAndroid} onClick={() => {
      analytics.track("onlineJour.installBtnClicked", {platform: "google"});
      window.open(getAndroidDownloadLink("android"), '_blank')
    }}/>
  );
}


export function getAndroidDownloadLink(platform) {
  const refreshToken = userSession.getRefreshToken();
  let downloadLink;
  if (refreshToken) {
    downloadLink = `https://play.google.com/store/apps/details?id=co.cerah.students&referrer=tk%3D${refreshToken}`;
  } else {
    downloadLink = `https://play.google.com/store/apps/details?id=co.cerah.students`;
  }
  return downloadLink;
}

function AppStoreBadge() {
  const strings = Language.active.cerahOnline;
  return (
    <Button text={strings.installForIPhone} onClick={() => {
      analytics.track("onlineJour.installBtnClicked", {platform: "apple"});
      window.open(getAppStoreDownloadLink(), '_blank')
    }}/>
  );
}

export function getAppStoreDownloadLink() {
  return "https://apps.apple.com/app/id1504514618#?platform=iphone";
}