import NovaImg from "../../../img/testimonials/nova.jpeg";
import JorgeImg from "../../../img/testimonials/jorge_mendieta.jpeg";
import ManuImg from "../../../img/testimonials/manuel_lorenzo.jpeg";
import DellaImg from "../../../img/testimonials/della_vanindya.jpeg";
import NadiaImg from "../../../img/testimonials/nadia.jpeg";
import SitiImg from "../../../img/testimonials/siti_hasanah.jpeg";
import AlvinImg from "../../../img/testimonials/alvin.jpeg";
import NurulImg from "../../../img/testimonials/nurul.jpg";
import KaelaLeekaImg from "../../../img/testimonials/kaela_leeka.jpg";
import {Language} from "../i18n/language";

export function getTestimonials() {
  const strings = Language.active.testimonials;
  return [
    {
      text: strings.reviews.alvin,
      user: {
        name: "Alvin Kurniawan",
        country: "ID",
        profilePictureUri: AlvinImg.src
      }
    },
    {
      text: strings.reviews.manolo,
      user: {
        name: "Manuel Lorenzo",
        country: "ES",
        profilePictureUri: ManuImg.src
      }
    },
    {
      text: strings.reviews.jorge,
      user: {
        name: "Jorge Mendieta",
        country: "PA",
        profilePictureUri: JorgeImg.src
      }
    },
    {
      text: strings.reviews.nurul,
      user: {
        name: "Nurul Hazirah Che Hamzah",
        country: "MY",
        profilePictureUri: NurulImg.src
      }
    },
    {
      text: strings.reviews.nova,
      user: {
        name: "Nova",
        country: "ID",
        profilePictureUri: NovaImg.src
      }
    },
    {
      text: strings.reviews.nadia,
      user: {
        name: "Nadia",
        country: "ID",
        profilePictureUri: NadiaImg.src
      }
    },
    {
      text: strings.reviews.siti,
      user: {
        name: "Siti Hasanah",
        country: "ID",
        profilePictureUri: SitiImg.src
      }
    },
    {
      text: strings.reviews.della,
      user: {
        name: "Della Vanindya",
        country: "ID",
        profilePictureUri: DellaImg.src
      }
    },
    {
      text: strings.reviews.kaelaLeeka,
      user: {
        name: "Kaela & Leeka",
        country: "ID",
        profilePictureUri: KaelaLeekaImg.src
      }
    }
  ];
}