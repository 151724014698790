import React from 'react';
import * as style from "./testimonialsSection.module.scss";
import {Language} from "../../i18n/language";
import {SectionTitle} from '../sectionTitle';
import {Link} from 'react-router-dom';
import {TestimonialsGrid} from '../../testimonialsPage/testimonialsPage';
import {getTestimonials} from "../../testimonialsPage/testimonials";
import {userSession} from '../../userSession/userSession';

export function TestimonialsSection({children}) {
  const strings = Language.active.whatTheySayAboutUs;
  const preferredLang = userSession.getPreferredLanguage();
  
  let testimonials;
  if (preferredLang === "ms") {
    testimonials = [getTestimonials()[3], ...getTestimonials().slice(1,3)];
  } else {
    testimonials = getTestimonials().slice(0,3);
  }

  return (
    <div className={style.testimonialsSection}>
      <SectionTitle title={strings.whatTheySayAboutUs} blackTitle/>
      <TestimonialsGrid testimonials={testimonials}/>
      <Link className={style.seeAllLink} to={"/testimonials"}>{strings.seeAllTestimonials}</Link>
      {children}
    </div>
  )
}