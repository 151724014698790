import React from 'react';
import * as style from "./testimonial.module.scss";
import TestimonialQuote from "../../../img/testimonials/testimonial-quote.svg";
import Background from "../../../img/testimonials/testimonial-card-background.png";
import {Language} from "../i18n/language";
import {LazyLoadImage} from 'react-lazy-load-image-component';

export function Testimonial({text, user}) {
  const strings = Language.active.testimonials;
  return (
    <div className={style.testimonialContainer}>
      <div className={style.testimonialQuote}>
        <LazyLoadImage src={TestimonialQuote.src}/>
      </div>
      <span className={style.testimonialText}>
        {text}
      </span>

      <div className={style.testimonialDisplayPictureContainer}>
        <LazyLoadImage className={style.displayPicture} src={user.profilePictureUri}/>
      </div>

      <span className={style.personName}>
        {user.name} {user.age && `- ${user.age} y/o`}
      </span>

      <span className={style.country}>
        {strings.countries[user.country]} {getFlagEmoji(user.country)}
      </span>
      <LazyLoadImage className={style.background} src={Background.src}/>
    </div>
  )
}

function getFlagEmoji(countryCode) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}
