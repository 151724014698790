import React from 'react';
import * as style from "./appShowcase.module.scss";
import StudentsAppImage from "../../../../img/appShowcase/mobile-app.png";
import PlayStoreBadge from "../../../../img/logos/google-play-badge.png";
import AppStoreBadge from "../../../../img/logos/app-store-badge.svg";
import backDrop from "../../../../img/appShowcase/mobile-banner-backdrop.png";
import {Language} from '../../i18n/language';
import {playStoreLinkClicked, appStoreLinkClicked} from "../../legal/footer";
import {LazyLoadImage} from 'react-lazy-load-image-component';

export function AppShowcaseBanner() {
  const strings = Language.active.appShowcaseBanner;
  return (
    <div className={style.appShowCaseContainer}>
      <div className={style.innerContainer}>
        <div className={style.contentWrapper}>
          <div className={style.appContent}>
            <span className={style.title}>{strings.practiceEnglishAnywhereYouAre}</span>
            <span className={style.subtitle}>{strings.donwloadOurAppAndStartLearningNow}</span>
            <div className={style.storeRow}>
              <LazyLoadImage 
                className={style.storeBadge} 
                src={PlayStoreBadge.src} 
                onClick={playStoreLinkClicked}/>
              <LazyLoadImage 
                className={style.storeBadge} 
                src={AppStoreBadge.src} 
                onClick={appStoreLinkClicked}/>
            </div>
          </div>
          <LazyLoadImage className={style.appImage} src={StudentsAppImage.src}/>
        </div>
        <LazyLoadImage className={style.backdrop} src={backDrop.src}/>
      </div>
    </div>
  )
}