import React from 'react';
import * as style from './button.module.scss';

export function Button({text, children, noArrow, onClick, disabled, small, className}) {
  return (
    <div
       className={`${style.button} ${className} ${
         disabled ? style.disabled : ""
       } ${
         small ? style.small : ""
       }`}
       onClick={!disabled && onClick}
    >
      {text}
      {children}
      {noArrow || <div className={style.arrow}/>}
    </div>
  );
}