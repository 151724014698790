import React from 'react'
import * as style from  "./blogArticlesSection.module.scss";
import {SectionTitle, SectionSubTitle} from "../sectionTitle";
import {Swiper, SwiperSlide} from "../../controls/swiper";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import articles from "./articles.json";
import { Button } from '../../controls/button';
import * as btnStyle from "../../controls/button.module.scss";
import {LazyLoadImage} from 'react-lazy-load-image-component';

export function BlogArticlesSection() {
  SwiperCore.use([Navigation, Pagination]);

  function Article({imageUrl, title, writer, date, content, link}) {
    return (
      <div className={style.articleContainer}>
        <div className={style.header}>
          <div className={style.headerLeft}>
            <LazyLoadImage className={style.articleImage} src={imageUrl}/>
          </div>
          <div className={style.headerRight}>
            <h2 className={style.title}>{title}</h2>
            <span className={style.writersName}>By {writer}</span>
            <span className={style.date}>{date}</span>
          </div>
        </div>
        <div className={style.content}>
          {content}
        </div>
        <Button 
          onClick={() => window.open(link, "_blank")}
          className={`${btnStyle.clear} ${style.button}`} 
          noArrow>
            Lanjut membaca
        </Button>
      </div>
    )
  }

  return (
    <div className={style.blogArticlesSection}>
      <SectionTitle title={"Tips Belajar!"}/>
      <span className={style.subtitle}>Tetap update dengan artikel kami</span>
      <div className={style.articlesSlides}>
        <Swiper
          modules={[Navigation, Pagination]} 
          navigation={true}
          pagination={true}
          loop={true}
          centeredSlides={true}
          slidesPerView={1}
          style={{width: '100%', height: '100%'}}>
          {articles.map((el, idx) => 
            <SwiperSlide key={idx} >
              <Article imageUrl={el.imageUrl} title={el.title} writer={el.writer} date={el.date} content={el.content} link={el.link}/>
            </SwiperSlide>
          )}
        </Swiper>
      </div>
      <span className={style.seeAllButton} onClick={() => window.open("https://www.cerah.co/blog", "_blank")}>Lihat semua artikel blog</span>
    </div>
  )
}

