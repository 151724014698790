import {isBrowser} from "../serverSideRendering/ssr";

const USER_ID_STORAGE_KEY = "cerah.userId";
const LANG_STORAGE_KEY = "cerah.lang";
const GCLID_STORAGE_KEY = "cerah.gclid";
const WBRAID_STORAGE_KEY = "cerah.wbraid";
const FBC_STORAGE_KEY = "cerah.fbc";
const FBP_STORAGE_KEY = "cerah.fbp";
const CURRENCY_STORAGE_KEY = "cerah.cur";
const CAMPAIGN_STORAGE_KEY = "cerah.campaign";
const USER_REFRESH_TOKEN_KEY = "cerah.refreshToken";
const REFERRAL_OF_KEY = "cerah.referralOf";

let country = 'ID';

let localStorage = isBrowser ? window.localStorage : {
  setItem() {},
  getItem() {
    return "";
  }
}

export const userSession = {
  update: (userId) => {
    localStorage.setItem(USER_ID_STORAGE_KEY, userId);
  },

  getUserId: () => {
    return localStorage.getItem(USER_ID_STORAGE_KEY);
  },

  setGclid: (gclid) => {
    if (!userSession.getGclid()) {
      localStorage.setItem(GCLID_STORAGE_KEY, gclid);
    }
  },

  getGclid: () => {
    return localStorage.getItem(GCLID_STORAGE_KEY);
  },

  setWbraid: (wbraid) => {
    if (!userSession.getWbraid()) {
      localStorage.setItem(WBRAID_STORAGE_KEY, wbraid);
    }
  },

  getWbraid: () => {
    return localStorage.getItem(WBRAID_STORAGE_KEY);
  },

  setFbc: (fbc) => {
    if (!userSession.getFbc()) {
      localStorage.setItem(FBC_STORAGE_KEY, fbc);
    }
  },

  getFbc: () => {
    return localStorage.getItem(FBC_STORAGE_KEY);
  },

  setFbp: (fbp) => {
    if (!userSession.getFbp()) {
      localStorage.setItem(FBP_STORAGE_KEY, fbp);
    }
  },
  
  getFbp: () => {
    return localStorage.getItem(FBP_STORAGE_KEY);
  },
  
  setPreferredLanguage: (lang) => {
    localStorage.setItem(LANG_STORAGE_KEY, lang);
  },

  getPreferredLanguage: () => {
    return localStorage.getItem(LANG_STORAGE_KEY);
  },

  setCountry: (countryIsoCode) => {
    country = countryIsoCode;
    switch (countryIsoCode) {
      case "ID":
        localStorage.setItem(CURRENCY_STORAGE_KEY, "IDR"); break;
      case "FR":
      case "IE":
      case "DE":
      case "IT":
      case "PT":
      case "ES":
        localStorage.setItem(CURRENCY_STORAGE_KEY, "EUR"); break;
      case "GB":
        localStorage.setItem(CURRENCY_STORAGE_KEY, "GBP"); break;
      case "TW":
        localStorage.setItem(CURRENCY_STORAGE_KEY, "TWD"); break;
      case "MY":
        localStorage.setItem(CURRENCY_STORAGE_KEY, "MYR"); break;
      case "VN":
        localStorage.setItem(CURRENCY_STORAGE_KEY, "VND"); break;
      case "CO":
        localStorage.setItem(CURRENCY_STORAGE_KEY, "COP"); break;
      case "CL":
        localStorage.setItem(CURRENCY_STORAGE_KEY, "CLP"); break;
      case "MX":
        localStorage.setItem(CURRENCY_STORAGE_KEY, "MXN"); break;
      case "SG":
        localStorage.setItem(CURRENCY_STORAGE_KEY, "SGD"); break;
      default:
        localStorage.setItem(CURRENCY_STORAGE_KEY, "USD"); break;
    }
  },

  getCountry: () => {
    return country;
  },

  getCurrency: () => {
    return localStorage.getItem(CURRENCY_STORAGE_KEY) || "USD";
  },

  setCampaign: (campaign) => {
    localStorage.setItem(CAMPAIGN_STORAGE_KEY, campaign);
  },

  getCampaign: () => {
    return localStorage.getItem(CAMPAIGN_STORAGE_KEY);
  },

  setRefreshToken: (refreshToken) => {
    localStorage.setItem(USER_REFRESH_TOKEN_KEY, refreshToken);
  },

  getRefreshToken: () => {
    return localStorage.getItem(USER_REFRESH_TOKEN_KEY);
  },

  setReferralOf: (referralOf) => {
    return localStorage.setItem(REFERRAL_OF_KEY, referralOf);
  },

  getReferralOf: () => {
    return localStorage.getItem(REFERRAL_OF_KEY);
  }
};