import React, {useEffect} from 'react';
import * as style from './main.module.scss';
import * as buttonStyle from '../controls/button.module.scss';
import {Button} from '../controls/button';
import {Language} from "../i18n/language";
import {OurMethod} from './ourMethod/ourMethod';
import {CerahOnlineSection} from "./cerahOnlineSection";
import {CerahKidsSection} from "./cerahKidsSection";
import {ScrollTracker} from "../analytics/scrollTracker";
import {useFixedWinHeight} from "./main";
import {isBrowser} from "../serverSideRendering/ssr";
import {Link} from 'react-router-dom';
import {Image} from '../controls/image';
// TODO: import webp for MainHero1
import MainHero1 from "../../../img/learning-english-changes-your-life.jpg";
import MainHero1webp from "../../../img/learning-english-changes-your-life.jpg.webp";
import OurClassesEllipse1 from "../../../img/main/ellipse-1.svg";
import {WhyCerahSection} from './whyCerah/whyCerah';
import {TestimonialsSection} from './testimonials/testimonialsSection';
import {LittleBitAboutUs} from './littleBitAboutUs/littleBitAboutUs';
import {AppShowcaseBanner} from './appShowcaseBanner/appShowcaseBanner';
import {SignUpNow} from './signUpNow/signUpNow';
import {SectionTitle} from './sectionTitle';
import {BlogArticlesSection} from './blogArticlesSection/blogArticlesSection';
import {LazyLoadImage} from 'react-lazy-load-image-component';

const firstHeroMsgSectionId = "home-first-hero-msg";
const ourMethodSectionId = "home-our-method";
const ourClassesSectionId = "home-our-classes";
const cerahOnlineSectionId = "home-cerah-online";
const cerahKidsSectionId = "home-cerah-kids";
const whyCerahSectionId = "home-why-cerah";
const testimonialsSectionId = "testimonials";
const signUpNowSectionId = "sign-up-now";
const littleBitAboutUsSectionId = "little-bit-about-us";
const appShowcaseSectionId  = "app-showcase";
export const whoAreWeSectionId = "home-who-are-we";

const scrollableSections = [
  [firstHeroMsgSectionId, "scrollToHeroMsg1"],
  [ourMethodSectionId, "scrollToOurMethod"],
  [ourClassesSectionId, "scrollToOurClasses"],
  [whyCerahSectionId, "scrollToWhyCerah"],
  [testimonialsSectionId, "scrollToTestimonials"],
  [signUpNowSectionId, "scrollToSignUpNow"],
  [littleBitAboutUsSectionId, "scrollToLittleBitAboutUs"],
  [appShowcaseSectionId, "scrollToAppShowcase"]
];

export function Home() {
  let scrollTracker;

  useEffect(() => {
    scrollTracker = new ScrollTracker('home', scrollableSections);
    scrollTracker.setup();
    return scrollTracker.teardown.bind(scrollTracker);
  }, []);

  const vh = useFixedWinHeight();

  const strings = Language.active.main;
  const signupNowStrings = Language.active.signUpNow;
  return (
    <div>
      <div className={style.home}>
        <div className={style.heroImg}>
          <Image
            altFormats={[MainHero1, MainHero1webp]}
            alt={strings.homeImageAltText}
            layout="fill"
            objectFit="cover"
          />
        </div>
        <div className={style.heroContent}>
          <div className={style.textPane}>
            <div className={style.slogan1}>{strings.changeYourLife}</div>
            <h1 key={Math.random()} className={style.slogan2} dangerouslySetInnerHTML={{__html: strings.speakEnglishWithinMonths}}/>
            <div className={style.slogan3}>{strings.noConfusingGrammar}</div>
          </div>
          <div className={style.buttonPane}>
            <Link to="/cerahOnline">
              <Button className={`${buttonStyle.large}`} noArrow>
                {strings.tryNow}
              </Button>
            </Link>
            <div className={style.underText}>{strings.noPaymentNeeded}</div>
          </div>
        </div>
      </div>

      <div id={firstHeroMsgSectionId} className={`${style.title} ${style.heroSectionTitle}`}>{strings.learnEnglishAtComfort}<hr className={style.horizontalRule}/></div>

      <div id={ourMethodSectionId} className={style.heroSection}>
        <OurMethod/>
      </div>

      <div id={ourClassesSectionId} className={style.ourClasses}>
        <SectionTitle title={strings.ourClasses}/>
        <hr className={style.horizontalRule} style={{width: "30%"}}/>
        <div id={cerahOnlineSectionId}>
          <CerahOnlineSection/>
        </div>
        <div id={cerahKidsSectionId}>
          <CerahKidsSection/>
        </div>
        <LazyLoadImage className={style.ellipse1} src={OurClassesEllipse1.src}/>
      </div>

      <div id={whyCerahSectionId}>
        <WhyCerahSection/>
      </div>

      <div id={testimonialsSectionId}>
        <TestimonialsSection/>
      </div>

      <div id={signUpNowSectionId}>
        <SignUpNow 
          title={signupNowStrings.signUpNow} 
          subtitle={signupNowStrings.beOneOfThem} 
          navigateTo={"/cerahOnline"} 
          buttonText={signupNowStrings.tryItFree}/>
      </div>

      {/*<div id={littleBitAboutUsSectionId}>*/}
      {/*  <LittleBitAboutUs/>*/}
      {/*</div>*/}

      <div id={appShowcaseSectionId}>
        <AppShowcaseBanner/>
      </div>

      {Language.activeLangIsoCode === "id" &&
        <BlogArticlesSection/>
      }
    </div>
  );
}
