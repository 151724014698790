import React, {useState} from 'react';
import {Language} from '../i18n/language';
import * as style from './languageSelector.module.scss';
import flagEn from '../../../img/icons/en.svg';
import flagEs from '../../../img/icons/es.svg';
import flagId from '../../../img/icons/id.svg';
import flagMy from '../../../img/icons/my.svg';
import {useLocation} from "react-router";
import {Link} from "react-router-dom";

const languageLabels = {
  en: "EN",
  es: "ES",
  id: "ID",
  ms: "MY",
};

const languageFlags = {
  en: flagEn,
  es: flagEs,
  id: flagId,
  ms: flagMy,
}

export function LanguageSelector() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  
  return (
    <div className={style.languageSelector} onClick={() => setMenuIsOpen(isOpen => !isOpen)}>
      <LanguageValue lang={Language.activeLangIsoCode}/>
      {menuIsOpen && <div className={style.languageSelectorDropdown}>
        {Object.keys(languageLabels).map(lang => <LanguageOption lang={lang}/>)}
      </div>}
    </div>
  );
  
  function LanguageOption({lang}) {
    const selectedLanguage = Language.activeLangIsoCode;
    let path = (useLocation()).pathname;
    if (path.startsWith(`/${selectedLanguage}/`)) {
      path = path.replace(new RegExp(`/${selectedLanguage}`), "");
    }
    path =`/${lang}${path}`;
    return (
      <Link to={path}>
        <LanguageValue lang={lang}/>
      </Link>
    );
  }
  
  function LanguageValue({lang}) {
    return (
    <div className={style.option}>
      <div className={style.optionIcon}>
        <img alt={lang} key={lang} style={{height: "100%"}} src={`${languageFlags[lang]?.src}?${Math.random()}`}/>
      </div>
      <div className={style.languageLabel}>
        {languageLabels[lang]}
      </div>
    </div>
    );
  }
}


