import {isBrowser} from "../serverSideRendering/ssr";

const CERAH_KIDS_TESTIMONIAL_KEY = "cerah.exp.cerahKids.testimonial";

const localStorage = isBrowser ? window.localStorage : {
  setItem() {},
  getItem() {
    return "";
  }
}

export const experimentGroup = {
  getCerahOnlineGroup: () => {
    return 1;
  },
  getCerahKidsTestimonialGroup: () => {
    let kidsTestimonialGroup = Number(localStorage.getItem(CERAH_KIDS_TESTIMONIAL_KEY));
    if (!kidsTestimonialGroup) {
      kidsTestimonialGroup = Math.floor(Math.random() * 2) + 1;
      localStorage.setItem(CERAH_KIDS_TESTIMONIAL_KEY, kidsTestimonialGroup);
    }
    return kidsTestimonialGroup;
  }
};

export const experimentalGroups = {
  cerahKidsTestimonialGroup: experimentGroup.getCerahKidsTestimonialGroup()
}