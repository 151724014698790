import {config} from '../config';
import {isBrowser} from "../serverSideRendering/ssr";

export let mixpanel = {
  callQ: [],

  track(...args) {
    mixpanel.callQ.push(["track", ...args]);
  },

  track_links(...args) {
    mixpanel.callQ.push(["track_links", ...args]);
  },

  getAnalyticsDistinctId() {
    console.error("mixpanel.getAnalyticsDistinctId() was called before mixpanel was initialized");
    return null;
  }
};

if (isBrowser) {
  (async () => {
    const oldMixpanel = mixpanel;
    mixpanel = (await import('mixpanel-browser')).default;

    mixpanel.init(config.MIXPANEL_TOKEN, {
      token: config.MIXPANEL_TOKEN,
      people: true,
      crossSubdomainCookie: true
    });

    oldMixpanel?.callQ.forEach(([method, ...args]) => {  //flush queue of events onto the newly loaded mixpanel object
      mixpanel[method](...args);
    });
  })();
}