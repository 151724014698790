import React, { useEffect, useState } from 'react';
import * as style from './navbar.module.scss';
import { LanguageSelector } from '../controls/languageSelector';
import { Language } from "../i18n/language";
import { Link } from 'react-router-dom';
import CrossIcon from "../../../img/icons/cross-icon.svg";
import CerahLogo from "../../../img/logos/cerah-logo.png";
import HamIcon from "../../../img/icons/ham.svg"
import analytics from "../analytics/analytics";

export const navbarSize = 56;

export function Navbar() {
  const [isNavigationDrawerOpened, setIsNavigationDrawerOpened] = useState(false);

  return (
    <div className={style.navbar}>
      <Link className={style.logoContainer} to="/">
        <img src={CerahLogo.src} style={{height: "100%"}}/>
      </Link>
      <NavigationLinks isDrawerOpen={isNavigationDrawerOpened} onNavClose={() => setIsNavigationDrawerOpened(false)}/>
      <div className={style.stub}/>
      <LanguageSelector/>
      <img onClick={() => {
        setIsNavigationDrawerOpened(true);
        analytics.track("hamburgerClicked");
      }} className={style.hamIcon} src={HamIcon.src}/>
    </div>
  );
}

function NavigationLinks({isDrawerOpen, onNavClose}) {
  const strings = Language.active.navbar;
  return (
    <div className={`${style.navigationLinksOuterPadding} ${!isDrawerOpen && style.navigationContainerHidden}`}>
      <div className={style.navigationLinksContainer}>
        <img onClick={onNavClose} className={style.closeNavButton} src={CrossIcon.src}/>
        <Link className={style.navigationLink} onClick={onNavClose} to="/">{strings.home}</Link>
        <Link className={style.navigationLink} onClick={onNavClose} to="/cerahOnline">{strings.coursesForAdults}</Link>
        <Link className={style.navigationLink} onClick={onNavClose} to="/cerahKids">{strings.coursesForKids}</Link>
        <Link className={style.navigationLink} onClick={onNavClose} to="/teacher">{strings.becomeATeacher}</Link>
      </div>
    </div>
  )
}