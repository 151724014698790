import React from 'react';
import * as style from './footer.module.scss';
import {Link} from 'react-router-dom';
import {Language} from "../i18n/language";
import InstaLogo from "../../../img/logos/logo-instagram.svg";
import LinkedInLogo from "../../../img/logos/logo-linkedin.svg";
import WhatsappLogo from "../../../img/logos/logo-whatsapp.svg";
import FacebookLogo from "../../../img/logos/logo-facebook.svg"
import PlayStoreBadgeIcon from "../../../img/logos/google-play-badge.png";
import {waLinkClickHdlr} from '../whatsapp/waLinkClickHdlr';
import AppleStoreBadgeIcon from "../../../img/logos/app-store-badge.svg";
import {getAndroidDownloadLink, getAppStoreDownloadLink} from '../wizardComponents/downloadTheApp/downloadTheApp';
import {LazyLoadImage} from 'react-lazy-load-image-component';

export function Footer() {
  const strings = Language.active.footer;
  const legalStrings = Language.active.copyright;

  function waLinkClicked() {
    const waClientMsg = Language.active.main.contactUs.imInterested;
    waLinkClickHdlr.navigateToWhatsapp(waClientMsg, "waLinkClicked");
  }

  return (
    <div className={style.footer}>
      <div className={style.footerColumn}>
        <div className={`${style.footerElementRow} ${style.cerahLogo}`}>
          <Link className={style.logo} to="/"/>
          <Link className={style.companyName} to="/">CERAH</Link>
        </div>
        <span className={style.columnText}>{strings.conquerTheWorld}</span>
        <div className={`${style.footerElementRow} ${style.badgeIcons}`}>
          <LazyLoadImage className={style.badgeIcon} src={PlayStoreBadgeIcon.src} onClick={playStoreLinkClicked}/>
          <LazyLoadImage className={style.badgeIcon} src={AppleStoreBadgeIcon.src} onClick={appStoreLinkClicked}/>
        </div>
      </div>
      <div className={style.footerColumn}>
        <span className={style.columnHeading}>{strings.ourServices}</span>
        <Link className={style.columnText} to={"/cerahOnline"}>CerahOnline</Link>
        <Link className={style.columnText} to={"/cerahKids"}>CerahKids</Link>
        <Link className={style.columnText} to={"/testimonials"}>{strings.testimonials}</Link>
        <Link className={style.columnText} to={"/teacher"}>{strings.becomeTeacher}</Link>
      </div>
      <div className={style.footerColumn}>
        <span className={style.columnHeading}>{strings.company}</span>
        <Link className={style.columnText} to={"/privacy"}>{legalStrings.privacyPolicy}</Link>
        <Link className={style.columnText} to={"/TnCs"}>{legalStrings.termsAndConditions}</Link>
      </div>
      <div className={style.footerColumn}>
        <span className={style.columnHeading}>{strings.findUsOn}</span>
        <div className={`${style.footerElementRow}`}>
          <a href='https://www.instagram.com/ceraheducation/' target={"_blank"}><LazyLoadImage className={style.socialMediaLogo} src={InstaLogo.src}/></a>
          <a href='https://www.linkedin.com/company/cerah-co/' target={"_blank"}><LazyLoadImage className={style.socialMediaLogo} src={LinkedInLogo.src}/></a>
          <LazyLoadImage className={style.socialMediaLogo} src={WhatsappLogo.src} onClick={waLinkClicked}/>
          <a href='https://www.facebook.com/cerah.co' target={"_blank"}><LazyLoadImage className={style.socialMediaLogo} src={FacebookLogo.src}/></a>
        </div>
        <Link className={style.columnText} to="/">{strings.copyright} &copy; 2021-{new Date().getFullYear()} Cerah Education Pte Ltd {strings.allRightsReserved}</Link>
      </div>
      
    </div>
  );
}

export function playStoreLinkClicked() {
  window.open(getAndroidDownloadLink(), "_blank");
  analytics.track("playStoreLinkClicked");
}

export function appStoreLinkClicked() {
  window.open(getAppStoreDownloadLink(), "_blank");
  analytics.track("appStoreLinkClicked");
}