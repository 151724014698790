import { createBrowserHistory } from 'history';
import {isBrowser} from "../serverSideRendering/ssr";

const history = isBrowser ? createBrowserHistory({}) : {
  listen: () => {}
};

let backListener;
let forthListener;

history.listen((location, action) => {
  if (action === "POP") {
    backListener && backListener(location);
  } else if (action === "PUSH") {
    forthListener && forthListener(location);
  }
});

history.setBackListener = (listener) => {
  backListener = listener;
};

history.setForthListener = (listener) => {
  forthListener = listener;
};

export default history;