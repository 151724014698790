import React from 'react'
import * as style from "./signUpNow.module.scss";
import {Button} from '../../controls/button';
import * as buttonStyle from "../../controls/button.module.scss";
import {Link} from 'react-router-dom';

export function SignUpNow({title, subtitle, buttonText, navigateTo}) {
  return (
    <div className={style.signUpNowContainer}>
      <span className={style.subtitle}>{subtitle}</span>
      <span className={style.title}>{title}</span>
      <Link to={navigateTo}>
        <Button className={`${buttonStyle.large} ${style.button}`} noArrow>{buttonText}</Button>
      </Link>
    </div>
  )
}