import {config} from "../config";
import {isBrowser} from "../serverSideRendering/ssr";
export {languageLessPath, langPrefixRegExp} from "../i18n/pathUtils";

export function protocolHost() {
  if (isBrowser)
    return `${window.location.protocol}//${window.location.host}`;
  return canonicalProtocolHost();
}

export function canonicalProtocolHost() {
  return config.PUBLIC_PATH;
}