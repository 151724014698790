import React from 'react';
import * as style from './main.module.scss';

export function SectionTitle({title, forDarkBgnd, blackTitle}) {
  return (
      <h1 className={`${style.sectionTitle} ${forDarkBgnd && style.forDarkBgnd} ${blackTitle && style.blackTitle}`}>
        {title}
      </h1>
  );
}

export function SectionSubTitle({title, forDarkBgnd, blackTitle, textAlignLeft}) {
  return (
      <h2 className={`${style.sectionSubTitle} ${forDarkBgnd && style.forDarkBgnd} ${blackTitle && style.blackTitle} ${textAlignLeft && style.textAlignLeft}`}>
        {title}
      </h2>
  );
}