import analytics from "../analytics/analytics";
import URLSearchParams from 'url-search-params';
import {isBrowser} from "../serverSideRendering/ssr";
import {userSession} from "../userSession/userSession";

export let waPhone = "6281381994846";

export function updateWaPhoneNumber() {
  switch (userSession.getCountry()) {
    case "MY":
      waPhone = "60133642307"; break;
    case "ID":
      waPhone = "6281381994846"; break;
    default:
      waPhone = "34627067017"; break;
  }
}

const waCode = isBrowser ? generateUniqueCode() : "somecode for server side";

function generateUniqueCode() {
  const campaign = new URLSearchParams(window.location.search).get("utm_campaign");
  return `${(Math.floor(Math.random() * 1000000)).toString(36)}-${(campaign || "")}`;
}

export const waLinkClickHdlr = {
  async navigateToWhatsapp(message, analyticsEvt, analyticsProps = {}) {
    const msgPlusCode = encodeURIComponent(`${message} (code: ${waCode})`);
    try {
      await Promise.all([
        analytics.track(analyticsEvt, {
          code: waCode,
          ...analyticsProps
        }),
      ]);
    } catch (e) {
      console.error(e);
    }
    window.open(`https://wa.me/${waPhone}?text=${msgPlusCode}`);
  }
};
