import React, {useEffect, useState} from "react";
import {Route, Prompt, withRouter, Switch} from "react-router-dom";
import {Home} from "./home";
import analytics from "../analytics/analytics";
import history from "../history/history";
import Helmet from "next/head";
import {Language} from "../i18n/language";
import {Footer} from "../legal/footer";
import {Navbar} from "../navbar/navbar";
import * as style from "./main.module.scss";
import {importSnap} from "../midtrans/snapLoader";
import {ScrollContext} from "react-router-scroll-4";
import {Payment} from "../midtrans/payment";
import {TeacherEnquiriesClosed} from "../teacher/teacherEnquiriesClosed";
import {userSession} from "../userSession/userSession";
import {supportedLanguages} from "../i18n/language";
import {canonicalProtocolHost, languageLessPath, protocolHost} from "./hrefLangTags";
import {configApi} from "../api/configApi";
import {Redirect, Router, StaticRouter} from "react-router";
import {browserCapabilities} from "./browserCapabilities";
import {isBrowser} from "../serverSideRendering/ssr";
import {config} from '../config';

/////DYNAMIC IMPORTS
import dynamic from 'next/dynamic';
import {updateWaPhoneNumber} from "../whatsapp/waLinkClickHdlr";

const CerahKids = dynamic(() => import("../cerahKids/cerahKids").then(mod => mod.CerahKids));
const CerahOnline = dynamic(() => import ("../cerahOnline/cerahOnline").then(mod => mod.CerahOnline));
const LearningMethods = dynamic(() => import ("../learningMethods/learningMethods").then(mod => mod.LearningMethods));
const TestimonialsPage = dynamic(() => import ("../testimonialsPage/testimonialsPage").then(mod => mod.TestimonialsPage));
const AboutUs = dynamic(() => import ("../aboutUs/aboutUs").then(mod => mod.AboutUs));
const CerahTeacher = dynamic(() => import ("../cerahTeacher/cerahTeacher").then(mod => mod.CerahTeacher));
const TeacherLanding = dynamic(() => import ("../teacher/teacherLanding").then(mod => mod.TeacherLanding));
//
const TeacherQuiz = dynamic(() => import ("../quiz/quizPackage").then(mod => mod.TeacherQuiz));
const Quiz = dynamic(() => import ("../quiz/quizPackage").then(mod => mod.Quiz));
const LevelQuiz = dynamic(() => import ("../quiz/quizPackage").then(mod => mod.LevelQuiz));
const LevelResults = dynamic(() => import ("../quiz/quizPackage").then(mod => mod.LevelResults));
const NpsQuiz = dynamic(() => import ("../quiz/quizPackage").then(mod => mod.NpsQuiz));
const NpsQuizEnd = dynamic(() => import ("../quiz/quizPackage").then(mod => mod.NpsQuizEnd));
const GenericQuizEnd = dynamic(() => import ("../quiz/quizPackage").then(mod => mod.GenericQuizEnd));


const TeacherEnquiry = dynamic(() => import("../enquiry/enquiryPackage").then(mod => mod.TeacherEnquiry));
const ThankYou = dynamic(() => import("../enquiry/enquiryPackage").then(mod => mod.ThankYou));

const TnCs = dynamic(() => import("../legal/legalPackage").then(mod => mod.TnCs));
const PrivacyPolicy = dynamic(() => import("../legal/legalPackage").then(mod => mod.PrivacyPolicy));
const ServiceTnCs = dynamic(() => import("../legal/legalPackage").then(mod => mod.ServiceTnCs));

const TeacherProfilePage = dynamic(() => import("../teacherProfile/teacherProfilePackage").then(mod => mod.TeacherProfilePage));
const TeacherChoices = dynamic(() => import("../teacherProfile/teacherProfilePackage").then(mod => mod.TeacherChoices));

const TestCallRescheduler = dynamic(() => import("../testCallRescheduler/testCallRescheduler").then(mod => mod.TestCallRescheduler));

const ShortUrl = dynamic(() => import("../shortUrl/shortUrl").then(mod => mod.ShortUrl));

const RenewalsWizard = dynamic(() => import("../renewals/renewalsWizard").then(mod => mod.RenewalsWizard));
const PaymentDirections = dynamic(() => import("../renewals/paymentDirections").then(mod => mod.PaymentDirections));
const CertificateValidations = dynamic(() => import("../certificateValidations/certificateValidations").then(mod => mod.CertificateValidations));

const PaymentSuccess = dynamic(() => import("../midtrans/paymentNotice").then(mod => mod.PaymentSuccess));

const trackPage = (location) => {
  analytics.page(location.pathname, {
    search: location.search,
    hash: location.hash,
  });
};

const vh = isBrowser ? window.innerHeight || 500 : 500;
const vw = isBrowser ? window.innerWidth || 500 : 500;

export function useFixedWinHeight() {
  const [winHeight, setWinHeight] = useState(400);

  useEffect(() => {
    setWinHeight(vh)
  }, []);

  return winHeight;
}

export function useFixedWinWidth() {
  const [winWidth, setWinWidth] = useState(0);

  useEffect(() => {
    setWinWidth(vw)
  }, []);

  return winWidth;
}


class WrappedApp extends React.Component {
  constructor(props) {
    super(props);
    this.localizedRoutes = this.localizedRoutes.bind(this);
    if (isBrowser) setTimeout(() => importSnap(), 4000);
    this.state = {
      teacherExcludedCountries: null
    };
    (async () => {
      if (isBrowser) {
        const webappConfig = await configApi.getWebappConfig();
        this.setState({teacherExcludedCountries: webappConfig.teacherExcludedCountries});
      }
    })();
  }

  localizedRoutes({location, match}) {
    const path = this.props.location.pathname;
    const teacherExcludedCountries = this.state.teacherExcludedCountries;
    const lang = match.params.lang;
    const prefix = lang ? `/${lang}` : "";
    if (isBrowser) {
      if (lang && lang !== userSession.getPreferredLanguage()) Language.switchActiveTranslation(lang);
    } else {
      if (lang) {
        Language.switchActiveTranslation(lang);
      } else {
        Language.switchActiveTranslation("id");
      }
    }
    return (<div> {/* DO NOT remove this outer div, it's a hack that fixes some react-router bug that causes the Navbar to be otherwise garbled with the Home component on client-side rendering on first page laod */}
      <Helmet>
        <title>{Language.active.title}</title>
        {config.ENV === "dev" && <meta name="robots" content="noindex,nofollow" />}
        <meta name="description" content={Language.active.metaDescription}/>
        <link rel="canonical" href={`${canonicalProtocolHost()}${prefix}${languageLessPath(location.pathname)}/`}/>
        <link rel="alternate" hrefLang="x-default" href={`${protocolHost()}${languageLessPath(location.pathname)}/`}/>
        {supportedLanguages.map(lang =>
          <link rel="alternate" hrefLang={`${lang}`} href={`${protocolHost()}/${lang}${languageLessPath(location.pathname)}/`} key={lang}/>
        )}
      </Helmet>
      {navbarToDisplay(path)}
      <div> {/* DO NOT remove this outer div, it's a hack that fixes some react-router bug that causes the Footer to be otherwise garbled with the Home component on client-side rendering on first page laod */}
        <Switch>
          <Route path={`${prefix}/`} exact={true} component={Home}/>
          <Route path={`${prefix}/teacher-enquiry`} component={
            teacherExcludedCountries && !teacherExcludedCountries.includes(userSession.getCountry()) ?
              TeacherEnquiry :
              TeacherEnquiriesClosed
          }/>
          <Route path={`${prefix}/thank-you/:type`} component={ThankYou}/>
          <Route path={`${prefix}/teacher`} component={TeacherLanding}/>
          <Route path={`${prefix}/test/teacher/intro`} component={TeacherQuiz}/>
          <Route path={`${prefix}/test/level/intro`} component={LevelQuiz}/>
          <Route path={`${prefix}/test/nps/intro`} component={NpsQuiz}/>
          <Route path={`${prefix}/test/level/results`} render={({location}) => <LevelResults location={location}/>}/>
          <Route path={`${prefix}/test/nps/end`} component={NpsQuizEnd}/>
          <Route path={`${prefix}/test/generic/end`} component={GenericQuizEnd}/>
          <Route path={`${prefix}/test/running/question/current`} component={Quiz}/>
          <Route path={`${prefix}/privacy`} component={PrivacyPolicy}/>
          <Route path={`${prefix}/tncs`} component={TnCs}/>
          <Route path={`${prefix}/serviceTncs`} component={ServiceTnCs}/>
          <Route path={`${prefix}/payment/:result`} component={PaymentSuccess}/>
          <Route path={`${prefix}/payments/:token`} component={Payment}/>
          <Route path={`${prefix}/cerahOnline/:level`} exact={true} component={CerahOnline}/>
          <Route path={`${prefix}/cerahOnline`} exact={true} component={CerahOnline}/>
          <Route path={`${prefix}/learningMethods`} exact={true} component={LearningMethods}/>
          <Route path={`${prefix}/testimonials`} exact={true} component={TestimonialsPage}/>
          <Route path={`${prefix}/aboutUs`} exact={true} component={AboutUs}/>
          {/*<Route path={`${prefix}/cerahTeacher`} exact={true} component={CerahTeacher}/>*/}
          <Route path={`${prefix}/cerahKids`} exact={true} component={CerahKids}/>
          <Route path={`${prefix}/teachers/:teacherId`} exact={true} component={TeacherProfilePage}/>
          <Route path={`${prefix}/courseProposals/:courseProposalId`} component={TeacherChoices}/>
          <Route path={`${prefix}/testcall/reschedule`} component={TestCallRescheduler}/>
          <Route path={`${prefix}/s/:shortPath`} component={ShortUrl}/>
          <Route path={`${prefix}/renewals`} component={RenewalsWizard}/>
          <Route path={`${prefix}/paymentDirections`} component={PaymentDirections}/>
          <Route path={`${prefix}/certificate-validations`} component={CertificateValidations}/>
          {isBrowser && <>
            <Route path={`${prefix}/teach/`} render={({location}) => {
              window.location.href =
              `${protocolHost()}/teacher/?utm_source=share&utm_medium=social_story&utm_campaign=teacher_story`;
            }}/>
            <Route path={`${prefix}/bca/`} render={({location}) => {
              window.location.href =
              `${protocolHost()}/cerahOnline/?utm_source=share&utm_medium=social_group&utm_campaign=bca_meeting`;
            }}/>
            <Route path={`${prefix}/era/`} render={({location}) => {
              window.location.href =
              `${protocolHost()}/cerahOnline/?utm_source=era&utm_medium=influencer&utm_campaign=era`;
            }}/>
            <Route path={`${prefix}/flo/`} render={({location}) => {
              window.location.href =
              `${protocolHost()}/cerahOnline/?utm_source=flo&utm_medium=influencer&utm_campaign=florane`;
            }}/>
            <Route path={`${prefix}/maple/`} render={({location}) => {
              window.location.href =
              `${protocolHost()}/cerahOnline/?utm_source=maple&utm_medium=partner&utm_campaign=maple`;
            }}/>
            <Route path={`${prefix}/kerjaholic/`} render={({location}) => {
              window.location.href =
              `${protocolHost()}/cerahOnline/?utm_source=kerjaholic&utm_medium=website&utm_campaign=kerjaholic`;
            }}/>
            <Route path={`${prefix}/2friends/`} render={({location}) => {
              window.location.href =
              `${protocolHost()}/cerahOnline/?utm_source=2friends&utm_medium=direct_message&utm_campaign=2friends`;
            }}/>
            <Route path={`${prefix}/ig/`} render={({location}) => {
              window.location.href =
              `${protocolHost()}/cerahOnline/?utm_source=instagram&utm_medium=social_profile&utm_campaign=company_page`;
            }}/>
            <Route path={`${prefix}/tt/`} render={({location}) => {
              window.location.href =
              `${protocolHost()}/cerahOnline/?utm_source=twitter&utm_medium=twit&utm_campaign=twitter`;
            }}/>
            {["robinson", "teddy", "siena", "azka", "winda", "jeniffer"].map(name =>
              <Route path={`${prefix}/${name}/`} render={({location}) => {
                window.location.href =
                `${protocolHost()}/teacher/?utm_source=share&utm_medium=social_story&utm_campaign=teacher_referral_${name}`;
              }}/>
            )}
          </>}
        </Switch>
      </div>
    </div>);
  }

  render() {
    const path = this.props.location.pathname;
    return withScrollContext(
      <div className={style.main}>
        <Prompt message={location => {
          trackPage(location);
          return true;
        }}/>
        <Route path="/blog" render={({location}) => {
          if (!location.pathname.endsWith("index.html")) {
            window.location.href =
              `${protocolHost()}${location.pathname}${location.pathname.endsWith('/') ? "" : "/"}index.html`;
          }
        }}/>
        <Switch>
          <Route path={`/:lang(${supportedLanguages.join("|")})`} render={this.localizedRoutes}/>
          <Route path="/" render={this.localizedRoutes}/>
        </Switch>
        {shouldDisplayFooter(path) && <Footer/>}
      </div>
    )
  }
}

function withScrollContext(component) {
  if (isBrowser) {
    return (
      <ScrollContext shouldUpdateScroll={(prevRouterProps, {location, history}) => 
        prevRouterProps && (location.pathname !== prevRouterProps.location.pathname || location.hash !== prevRouterProps.location.hash
      )}>
        {component}
      </ScrollContext>
    );
  }
  return (<>{component}</>);
}

function navbarToDisplay(path) {
  if ((new RegExp('^(/id|/en|/es|/ms)')).test(path)) {
    path = path.slice(3);
  }
  const transparentNav = 
    path.startsWith('/cerahOnline') || 
    path.startsWith('/cerahKids') || 
    path.startsWith('/learningMethods') || 
    path.startsWith('/cerahTeacher') || 
    path.startsWith('/aboutUs');
  if (
    path.startsWith('/teacher-search') ||
    path.startsWith('/test/') ||
    path.startsWith('/teacher-enquiry') ||
    path.startsWith('/payment')
  ) {
    return "";
  } else {
    return <Navbar transparentNav={transparentNav}/>
  }
}

function shouldDisplayFooter(path) {
  if ((new RegExp('^(/id|/en|/es|/ms|/zh-tw|/vi)')).test(path)) {
    path = path.slice(3);
  }
  return !path.startsWith('/teacher-search') &&
    !path.startsWith('/test/') &&
    !path.startsWith('/teacher-enquiry') &&
    !path.startsWith('/thank-you') &&
    !path.startsWith('/payment');
}

const WrappedAppWithRouter = withRouter(WrappedApp);

export const App = function ({pageContext}) {
  const [dummy, setDummy] = useState(0);

  function render() {
    setDummy(Math.random());
  }

  useEffect(() => {
    (async () => {
      try {
        const resp = await fetch("https://www.cloudflare.com/cdn-cgi/trace");
        const text = await resp.text();
        let country = null;
        text.split("\n").forEach(line => {
          if (line.startsWith("loc=")) {
            country = line.split("loc=")[1]
          }
        });
        if (!country) {
          country = "ID";
          console.error("Failed to parse geolocation info", text);
        }
        userSession.setCountry(country);
        updateWaPhoneNumber();
        render();
      } catch (e) {
        console.error("Failed to retrieve geolocation info", e);
        userSession.setCountry("ID");
      }
    })();

    if (isBrowser) {
      const campaign = new URLSearchParams(window.location.search).get("utm_campaign");
      if (campaign) {
        userSession.setCampaign(campaign);
      }
    }

    Language.onChange(() => {
      console.log("language changed");
      render();
    });
    browserCapabilities.onReady(render);
    render();
  }, []);


  return (
    <ForkRouter history={history} pageContext={pageContext}>
      <WrappedAppWithRouter/>
    </ForkRouter>
  );
}

function ForkRouter({children, pageContext, history}) {
  if (isBrowser) {
    return <Router history={history}>
      {children}
    </Router>
  }

  return <StaticRouter location={{pathname: pageContext.staticPath}}>
    {children}
  </StaticRouter>
}
