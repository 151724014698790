import React from 'react';
export {App} from './main/main';
import {App} from './main/main';
import analytics from './analytics/analytics.js';
import {config} from './config';
import {userSession} from "./userSession/userSession";
import {experimentGroup} from "./experiment/experimentGroup";
import URLSearchParams from 'url-search-params';

const isBrowser = typeof window !== "undefined";

const location = isBrowser ? window.location : {search: "", hash: ""};
const document = isBrowser ? window.document : {cookie: {match: () => {}}};

analytics.track("main.starting");

function getCookieValue(a) {
  var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}

const gclid = new URLSearchParams(location.search).get("gclid");
if (gclid)
  userSession.setGclid(gclid);

const wbraid = new URLSearchParams(location.search).get("wbraid");
if (wbraid)
  userSession.setWbraid(wbraid);

const fbclid = new URLSearchParams(location.search).get("fbclid");
const fbc = getCookieValue("_fbc") || (fbclid && `fb.1.${Date.now()}.${fbclid}`) || undefined;
const fbp = getCookieValue("_fbp") || undefined;
if (fbc)
  userSession.setFbc(fbc);
if (fbp)
  userSession.setFbp(fbp);

const referralOf = new URLSearchParams(location.search).get('referral_of');
if (referralOf) {
  userSession.setReferralOf(referralOf);
}

analytics.page(location.pathname, {
  search: location.search,
  hash: location.hash,
  gclid,
  fbc,
  fbp
});

if (isBrowser) {
  window.addEventListener("message", (ev) => {
    let data = ev.data;
    if (data) {
      if (data.src === "rocketchat" && data.args === "chat-started") {
        analytics.track("livechatStarted");
      }
    }
  });
}

if (config.ENV === "prod" && isBrowser) {
  (async () => {
    const rollbar = (await import (/* webpackChunkName: "rollbar" */ 'rollbar-browser')).default;
    window.Rollbar = rollbar.init({
      accessToken: config.ROLLBAR_ACCESS_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,

      payload: {
        environment: config.ENV,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: config.CODE_VERSION,
            guess_uncaught_frames: true
          }
        }
      }
    });
  })();
}
