import React from 'react';
import * as style from './whyCerah.module.scss';
import {Language} from "../../i18n/language";
import {Swiper, SwiperSlide} from "../../controls/swiper";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";
import {Button} from '../../controls/button';
import BrainImg from "../../../../img/whyCerah/method.webp";
import WorldClassTeachers from "../../../../img/whyCerah/1-teacher.webp";
import TryBefore from "../../../../img/whyCerah/try.webp";
import Affordable from "../../../../img/whyCerah/piggy.webp";
import OurApp from "../../../../img/whyCerah/with-our-app.webp";
import Bubble1 from "../../../../img/whyCerah/bubble1.svg";
import Bubble2 from "../../../../img/whyCerah/bubble2.svg";
import {SectionTitle} from '../sectionTitle';
import {Link} from "react-router-dom";
import {LazyLoadImage} from 'react-lazy-load-image-component';

export function WhyCerahSection({children}) {
  SwiperCore.use([Navigation, Pagination]);

  const strings = Language.active.whyCerah;

  const whyCerahArr = [
    {
      imageUri: BrainImg.src,
      title: strings.aMethodThatWorks,
      text: strings.realSpeakingPracticeNoTheory,
      // button: {
      //   link: "/learningMethods",
      //   text: strings.learnMoreMethod
      // }
    },
    {
      imageUri: WorldClassTeachers.src,
      title: strings.worldClassTeachers,
      text: strings.ourTeachersAreFun
    },
    {
      imageUri: TryBefore.src,
      title: strings.canTryBeforeYouBuy,
      text: strings.nothingToLose
    },
    {
      imageUri: Affordable.src,
      title: strings.veryAffordablePrices,
      text: strings.privatePricesBetterThanYourTown
    },
    {
      imageUri: OurApp.src,
      title: strings.learnWithTheApp,
      text: strings.vocabularyPracticeAndProgress,
    },
  ];

  return (
    <div className={style.whyCerah}>
      <SectionTitle title={strings.whyCerah}/>
      <div className={style.sliderWrapper} style={{minHeight: 0}}>
        <Swiper
              modules={[Navigation, Pagination, Autoplay]} 
              autoplay={{delay: 2500}}
              navigation={true}
              pagination={true}
              spaceBetween={20}
              loop={true}
              centeredSlides={true}
              slidesPerView={1}
              style={{width: '100%', height: '100%'}}>
          {whyCerahArr.map((el, idx) => 
            <SwiperSlide key={idx} >
              <WhyCerahSlide title={el.title} text={el.text} imageUri={el.imageUri} button={el.button}/>
            </SwiperSlide>
          )}
        </Swiper>
      </div>
      <div className={style.watermarks}>
        <LazyLoadImage className={style.watermark1} src={Bubble1.src}/>
        <LazyLoadImage className={style.watermark2} src={Bubble2.src}/>
      </div>
      {children}
    </div>
  )
}

function WhyCerahSlide({title, text, imageUri, button}) {
  return (
    <div className={style.whyCerahSlide}>
      <div className={style.whyCerahSlideImage}>
        <LazyLoadImage className={style.whyCerahImg} src={imageUri}/>
      </div>
      <div className={style.whyCerahSlideCaption}>
        <span className={style.whyCerahTitle}>{title}</span>
        <span className={style.whyCerahText}>{text}</span>
        <div>
        {button && 
          <Link to={button.link}>
            <Button noArrow>
              {button.text}
            </Button>
          </Link>
        }
        </div>
      </div>
    </div>
  )
}