import React from 'react';
import {Language} from "../i18n/language";
import {OurClassesSection} from './ourClassesSection/ourClassesSection';
import CerahKidsHomeImg from "../../../img/cerahKids-home.webp";

export function CerahKidsSection(props) {
  const strings = Language.active.ourClasses;

  return (
    <OurClassesSection 
      title={strings.cerahKids} 
      subtitle={strings.cerahKidsSubtitle} 
      text={strings.cerahKidsText}
      imageUri={CerahKidsHomeImg.src}
      buttonText={strings.buttonText}  
      navigateTo="/cerahKids"
      flexReverse
      noBackground/>
  );
}
