import React from 'react'
import { SectionSubTitle, SectionTitle } from '../main/sectionTitle';
import * as style from "./testimonialsPage.module.scss";
import { Language } from '../i18n/language';
import QuotesImg from "../../../img/testimonials/testimonial-quote.svg";
import {Testimonial} from "../testimonial/testimonial";
import {Swiper, SwiperSlide} from "../controls/swiper";
import SwiperCore, { Navigation, Pagination} from "swiper/core";
import {SignUpNow} from "../main/signUpNow/signUpNow";
import EllipseLeft from "../../../img/main/ellipse-left.svg";
import EllipseRight from "../../../img/main/ellipse-right.svg";
import {getTestimonials} from "./testimonials";
import VideoTestimonial from "../../../media/testimonial.mp4";
import VideoTestimonialPortrait from "../../../media/testimonial-portrait.mp4";
import testimonialThumbnail from "../../../img/testimonials/manolo_thumbnail.jpeg";
import testimonialThumbnailPortrait from "../../../img/testimonials/manolo_thumbnail_portrait.jpeg";
import analytics from "../analytics/analytics";

export function TestimonialsPage() {
  const strings = Language.active.testimonials;
  SwiperCore.use([Navigation, Pagination]);

  return (
    <div className={style.testimonialsPage}>
      <div className={style.banner}>
        <SectionTitle title={strings.bannerTitle}/>
        <div className={style.bannerRow}>
          <div className={style.testimonialVideo}>
            <video 
              className={style.landscapeVideo}
              src={VideoTestimonial}
              autoPlay={true}
              controls={true}
              muted={true}
              poster={testimonialThumbnail.src}/>
            <video 
              className={style.portraitVideo}
              src={VideoTestimonialPortrait}
              autoPlay={true}
              controls={true}
              muted={true}
              poster={testimonialThumbnailPortrait.src}/>
          </div>
          <div className={style.bannerTestimonial}>
            <img className={style.quotes} src={QuotesImg.src}/>
            <span className={style.text}>{strings.bannerTestimonialText}</span>
            <span className={style.personName}>{strings.bannerPersonName}</span>
            <span className={style.personInfo}>{strings.bannerPersonInfo}</span>
          </div>
        </div>
        <img className={style.ellipseLeft} src={EllipseLeft.src}/>
        <img className={style.ellipseRight} src={EllipseRight.src}/>
      </div>

      <div className={style.storiesSection}>
        <SectionTitle title={strings.storiesFromOurStudents}/>
        <TestimonialsGrid testimonials={getTestimonials()}/>
      </div>

      <SignUpNow title={strings.joinNow} subtitle={strings.followTheirSuccessStory} buttonText={strings.tryItFree} navigateTo="/cerahOnline"/>
    </div>
  )
}

export function TestimonialsGrid({testimonials}) {
  return (
    <div className={style.testimonialsGridAligner}>
      <Swiper
        modules={[Pagination, Navigation]} 
        pagination={true}
        navigation={true}
        slidesPerColumn={2}
        spaceBetween={0}
        slidesPerColumnFill={'row'}
        slidesPerView={3}
        style={{width: '100%', height: '100%', padding: '24px'}}
        onSlideChange={({activeIndex}) => analytics.track(`online.testimonialSwiped`, {name: testimonials[activeIndex].user.name})}
        breakpoints={{
          0 : {
            slidesPerView: 1,
            slidesPerColumn: 1
          },
          924: {
            slidesPerView: 2,
            slidesPerColumn: 1
          },
          1314: {
            slidesPerView: 3,
            pagination: (testimonials.length <= 3)
          },
        }}>
        {testimonials.map((t, idx) => 
          <SwiperSlide key={idx} className={style.testimonialSlide}>
            <Testimonial text={t.text} user={t.user}/>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  )
}