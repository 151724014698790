import {userSession} from "../userSession/userSession";
import {config} from '../config';

export async function apiFetch(path, method, body = null, jwtToken) {
  const authorization = jwtToken && `Bearer ${jwtToken}`;
  return fetch(new Request(config.API_HOST + path, {
    headers: {
      "Content-Type": "application/json",
      "X-CH-AUTH-TOKEN": userSession.getUserId(),
      "x-cerah-auth": authorization
    },
    method,
    body: body != null ? JSON.stringify(body) : null
  })).then((response) => {
    if (!response.ok) {
      return Promise.reject(response);
    }

    return response.json();
  });
}